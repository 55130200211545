import React, { createContext, useContext } from "react";
import { useGeoLocationData } from "../../hooks/useGeoLocationApi";
import * as store from "../../store";

const LocationDataContext = createContext({
  data: {},
  isLoading: true,
  isError: false,
} as any);

export const LocationDataProvider = ({ children }: any) => {
  const locationData = useGeoLocationData();

  return (
    <LocationDataContext.Provider value={locationData}>
      {children}
    </LocationDataContext.Provider>
  );
};

export const useLocationDataContext = () => {
  return useContext(LocationDataContext);
};

export const isBlockedCountry = (countryCode: string) => {
  return ["CN", "US", "BY", "CU", "IR", "KP", "RU", "SY", "UA"].includes(
    countryCode
  );
};

export const showLocationNotAllowedMessage = (countryCode: string) => {
  if (isBlockedCountry(countryCode)) {
    store.default.dispatch(
      store.toastAdd({
        id: "error-country-not-allowed",
        message: "Altitude is currently not available in your region.",
        type: "error",
      })
    );
  } else {
    store.default.dispatch(store.toastRemove("error-country-not-allowed"));
  }
};
