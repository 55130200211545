import React, {useEffect} from "react";
import * as ModalStyles from "../../../../../utils/styles/modal.styles";

type TabOption = { disabled: boolean, name: string };
type TabItem = string | TabOption;

interface ModalHeaderProps {
  tabs: Record<string, TabItem>;
  onTabChanged: (tabKey: string) => void;
  activeTab?: string;
}

function isTabOption(tabItem: TabItem): tabItem is TabOption {
  return (tabItem as TabOption).name !== undefined;
}

export const ModalHeader = ({ tabs, onTabChanged, activeTab }: ModalHeaderProps) => {
  useEffect(() => {
    if (!activeTab) {
      const firstTab = Object.values(tabs)[0];
      onTabChanged(isTabOption(firstTab) ? firstTab.name : firstTab);
    }
  }, [activeTab, onTabChanged, tabs]);
  return (
    <ModalStyles.ModalHeader data-testid="modal-header">
      {Object.keys(tabs).map((tabKey) => {
        const tab = tabs[tabKey];
        const tabName = isTabOption(tab) ? tab.name : tab;
        const disabled = isTabOption(tab) ? tab.disabled : false;
        return (
          <ModalStyles.ModalHeaderTab
            data-testid={"tab-" + tabName}
            key={tabKey}
            $active={activeTab === tabName}
            $disabled={disabled}
            onClick={() => !disabled && onTabChanged(tabName)}
          >
            {tabName}
          </ModalStyles.ModalHeaderTab>
        )
      })}
    </ModalStyles.ModalHeader>
  );
};
