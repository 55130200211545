import React from "react";
import { useChainId } from "wagmi";

import { Navbar, NotFound } from "./components";
import { Dashboard, Vaults } from "./modules";
import { PopupProvider } from "react-popup-manager";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { AppPage, ROUTES } from "./utils";
import { GlobalStyle } from "./utils";

import TopToastBar from "./components/top-toast-bar";
import * as Sentry from "@sentry/react";
import { ErrorPage } from "./components/error-page";
import AnalyticsProvider from "./hooks/useAnalytics/analytics.provider";
import { useOptIn } from "./hooks/useAnalytics";
// import CookieConsent from "./modules/dashboard/components/cookie-consent";
import { ApolloProvider } from "@apollo/client";
import { createSubgraph } from "./config";
import { useUser } from "./hooks/useAppSelector";
import { LocationDataProvider } from "./modules/dashboard/locationState";
import { TransactionProvider } from "./services/transaction-provider";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const chainId = useChainId();

  const { data: { theme } = {} } = useUser();

  const { analyticsInstance } = useOptIn();

  const apolloClient = createSubgraph(chainId);

  return (
    <ThemeProvider theme={{ theme: theme || "dark" }}>
      <AnalyticsProvider instance={analyticsInstance}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <ApolloProvider client={apolloClient}>
            <LocationDataProvider>
              <TransactionProvider>
                <PopupProvider>
                  <GlobalStyle />
                  <AppPage data-testid="app">
                    <TopToastBar />
                    <Navbar />
                    <SentryRoutes>
                      <Route path={ROUTES.dashboard} element={<Dashboard />} />
                      <Route path={ROUTES.vaults} element={<Vaults />} />
                      <Route path={ROUTES.notFound} element={<NotFound />} />
                    </SentryRoutes>
                  </AppPage>
                </PopupProvider>
              </TransactionProvider>
            </LocationDataProvider>
            <ToastContainer theme="colored" />
            {/*<CookieConsent />*/}
          </ApolloProvider>
        </Sentry.ErrorBoundary>
      </AnalyticsProvider>
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(App);
