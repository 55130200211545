import styled from "styled-components";
import { ThemePrimaryText, ThemeGray100 } from "../../../../../utils";
import { Button } from "../../../../../utils/styles/button";
import { GridVars } from "../../../../../utils";

interface PillButtonProps {
  $maxWidth?: number;
  $background?: string;
}

interface PillButtonTextProps {
  $color?: string;
}

export const PillButton = styled.div<PillButtonProps>`
  margin-left: 0;
  padding: 4px 6px;

  background: ${(props) => props.$background || ThemeGray100};
  border-radius: 4px;

  justify-content: center;
  align-content: center;
  display: flex;

  gap: 5px;

  max-width: ${(props) => `${props.$maxWidth}px` || 'auto'};

  cursor: pointer;
`;

export const PillButtonText = styled.div<PillButtonTextProps>`
  font-size: 11px;
  line-height: 14px;
  color: ${(props) => props.$color || ThemePrimaryText};
`;

export const InventivesNetApy = styled.span`
  color: ${ThemePrimaryText};
`;

export const BackButton = styled(Button).attrs({ $size: 'inline', $variant: 'basic' })`
  padding-left: 0;
`;
export const ValuationInput = styled.input`
`;

export const InlineMargin = styled.span`
  margin-right: ${GridVars.margin.m1};
`;