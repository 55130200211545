import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import moneyFormatterLogic from "../../../components/money-formatter/money-formatter.logic";
import { tokenBalanceFormatter } from "../../../components/token-balance/token-balance";
import { TokenDisplayType } from "../../../models/vault";
import { Messages } from "../../../utils";
import {BigNumber, constants} from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { RateLimitResponse } from "@altitude-fi/altitude-v1-sdk/dist/generated-types/Ingress";

export const depositInputValidator = (
  supplyToken: string,
  balance: number,
  value: number | null,
  max: number,
  maxDeposit: number,
  minDeposit: number,
  vaultSupplyBalance: number,
  vaultDepositLimit: number,
  vaultType: VaultName
) => {
  if (value && (balance + value) < minDeposit) {
    return `Altitude requires your supply balance to be ${minDeposit} ${supplyToken} or more`;
  }

  if (value && value > max) {
    return Messages.greaterThan("deposit", "you have on the account");
  }

  if (value && value > maxDeposit) {
    const formattedValue = tokenBalanceFormatter({
      displayType: TokenDisplayType.supply,
      vaultType,
      amount: maxDeposit,
    });
    return `You have reached your deposit limit and cannot deposit more than ${formattedValue.label}`;
  }

  if (value && (value + vaultSupplyBalance) > vaultDepositLimit) {
    const formattedValue = tokenBalanceFormatter({
      displayType: TokenDisplayType.supply,
      vaultType,
      amount: vaultDepositLimit - vaultSupplyBalance,
    });
    return `The vault has reached its deposit limit, you cannot deposit more than ${formattedValue.label}`;
  }

  return "";
};
export const borrowInputValidator = (value: BigNumber, max: BigNumber, borrowRateLimit?: RateLimitResponse, debtTokenAmount?: BigNumber) => {
  if (value.add(debtTokenAmount || constants.Zero).gt(max)) {
    return Messages.greaterThan("borrow", "Borrowing limit");
  }

  if (borrowRateLimit && value.gt(borrowRateLimit.available)) {
    return 'Your borrow is larger than allowed by our security limit. Please borrow in batches or contact the team.'
  }
  return "";
};
export const withdrawInputValidator = (value: BigNumber, max: BigNumber, vaultType: VaultName, tokenDecimals: number, withdrawRateLimit?: RateLimitResponse) => {
  if (value.gt(max)) {
    const formattedValue = tokenBalanceFormatter({
      displayType: TokenDisplayType.supply,
      vaultType,
      amount: +formatUnits(max, tokenDecimals),
    });
    return `You need supply to cover your loan. ${Messages.greaterThan("withdraw", formattedValue.label)}`;
  }

  if (withdrawRateLimit && value.gt(withdrawRateLimit.available)) {
    return "Your withdraw is larger than allowed by our security limit. Please withdraw in batches or contact the team.";
  }

  return "";
};
export const repayInputValidator = (
  value: BigNumber,
  max: BigNumber,
  balance: BigNumber,
  tokenLabel: string,
  tokenDecimals: number
) => {
  if (value.gt(max)) {
    return Messages.greaterThan("repay", "you borrowed");
  }

  if (value.gt(balance)) {
    const formattedBalance = moneyFormatterLogic({
      value: formatUnits(balance, tokenDecimals),
      postfix: ` ${tokenLabel}`,
    });
    return Messages.greaterThan("repay", `your balance (${formattedBalance})`);
  }

  return "";
};
export const claimInputValidator = (
  value: BigNumber,
  max: BigNumber,
  claimRateLimit?: RateLimitResponse
) => {
  if (value.gt(max)) {
    return Messages.greaterThan("claim", "your balance");
  }
  if (claimRateLimit && value.gt(claimRateLimit.available)) {
    return "Your claim is larger than allowed by our security limit. Please claim in batches or contact the team."
  }
  return "";
}
