import {
  BoxHeaderSquare,
  StatsColumn,
  StatsItem,
  StatsItemTitle, StatsItemValue,
  VaultBoxContainer,
  VaultBoxStats,
  VaultBoxTitle, VaultStatsContainer,
  StatsProgressBar
} from "./styles";
import React, { useCallback, useMemo } from "react";
import {VaultHarvestModel, VaultModel, VaultStats as VaultStatsType} from "../../../../models";
import { InfoPopover, LoaderInline, MoneyFormatter } from "../../../../components";
import { percentageFormatter } from "../../../../utils";
import * as PopoverStyles from "../../../../components/custom-popover/styles";
import { useVaultHarvests, useVaultStats } from "../../../../hooks/useAppSelector";
import { useApyData } from "../../../../hooks/useApi";
import { ApyData } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { useBackgroundLoading } from "../../../../hooks/useBackgroundLoading";

type VaultStatsProps = {
  vault: VaultModel
}

type NumericKeys<T> = {
  [K in keyof T]: T[K] extends number ? K : never
}[keyof T];

const VaultStats = ({ vault }: VaultStatsProps) => {
  const { data: stats, isLoading: statsLoading } = useVaultStats(vault);
  const { data: harvests, isLoading: vaultHarvestLoading } = useVaultHarvests();
  const { data: apyData, isLoading: apyLoading } = useApyData();

  const renderLoadingBar = () => (
    <LoaderInline height={18} loaderSettings={[
      {
        y: 6,
        x: 0,
        rx: 3,
        ry: 3,
        width: 100,
        height: 13,
      }
    ]} />
  );

  const isDataLoading = useMemo(() => apyLoading || statsLoading || vaultHarvestLoading, [apyLoading, statsLoading, vaultHarvestLoading]);
  const isLoading = useBackgroundLoading(isDataLoading);

  const renderUsdValue = useCallback((key: NumericKeys<VaultStatsType>) => {
    if (isLoading) {
      return renderLoadingBar();
    }
    const value = stats && stats[key];
    const numberValue = value === undefined ? NaN : value
    return <MoneyFormatter value={numberValue} prefix={"$"} />
  }, [stats, isLoading]);

  const totalSuppliedInPercentage = useMemo(() => {
    if (!stats || !vault) {
      return 0;
    }
    return (stats?.totalSupply / vault.vaultDepositLimit) * 100;
  }, [stats, vault]);

  const collateralInfo = [
    { label: 'Total Supplied', value: `${stats?.totalSupply.toFixed(vault.supplyPrecision)} ${vault.supplySymbol}` },
    { label: 'Vault Deposit Limit', value: `${vault.vaultDepositLimit} ${vault.supplySymbol}` },
    { label: 'User Deposit Limit', value: `${vault.userMaxDepositLimit} ${vault.supplySymbol}` },
    { label: 'User Minimum Supply', value: `${vault.userMinDepositLimit} ${vault.supplySymbol}` },
  ];

  const farmingInfo = [
    { label: 'Min Target Threshold', value: vault.minTargetThreshold },
    { label: 'Max Target Threshold', value: vault.maxTargetThreshold },
  ];

  const renderHarvestValue = useCallback((valueCb: (harvest: VaultHarvestModel, apys?: ApyData) => React.ReactNode) => {
    if (isLoading) {
      return renderLoadingBar();
    }
    return valueCb(harvests, apyData?.data);
  }, [isLoading, harvests, apyData])

  return (
    <VaultStatsContainer>
      <VaultBoxContainer>
        <VaultBoxTitle color="blueAlpha">
          <BoxHeaderSquare color="blueAlpha" />
          <span>Assets</span>
        </VaultBoxTitle>
        <VaultBoxStats>
          <StatsColumn>
            <StatsItem>
              <StatsItemTitle>
                <span>Supply</span>
                <InfoPopover>
                  {collateralInfo.map((item) => (
                    <PopoverStyles.Row key={item.label}>
                      <PopoverStyles.RowLabel>{item.label}:</PopoverStyles.RowLabel>
                      <PopoverStyles.RowValue>
                        {item.value}
                      </PopoverStyles.RowValue>
                    </PopoverStyles.Row>
                  ))}
                </InfoPopover>
              </StatsItemTitle>
              <StatsItemValue>{renderUsdValue('totalSupplyInUsd')}</StatsItemValue>
              <StatsProgressBar $percentage={totalSuppliedInPercentage} />
            </StatsItem>
            <StatsItem>
              <StatsItemTitle>Farming</StatsItemTitle>
              <StatsItemValue>{renderUsdValue('totalFarmInUsd')}</StatsItemValue>
            </StatsItem>
          </StatsColumn>
          <StatsColumn>
            <StatsItem>
              <StatsItemTitle>User Borrows</StatsItemTitle>
              <StatsItemValue>{renderUsdValue('totalUserBorrowInUsd')}</StatsItemValue>
            </StatsItem>
          </StatsColumn>
        </VaultBoxStats>
      </VaultBoxContainer>
      <VaultBoxContainer>
        <VaultBoxTitle color="greenAlpha">
          <BoxHeaderSquare color="greenAlpha" />
          <span>Allocations</span>
        </VaultBoxTitle>
        <VaultBoxStats>
          <StatsColumn>
            <StatsItem>
              <StatsItemTitle>Current Lender</StatsItemTitle>
              <StatsItemValue>{vault.lenderStrategyInfo.name}</StatsItemValue>
            </StatsItem>
            <StatsItem>
              <StatsItemTitle>Farming With</StatsItemTitle>
              <StatsItemValue>{vault.farmStrategyInfo.name}</StatsItemValue>
            </StatsItem>
          </StatsColumn>
          <StatsColumn>
            <StatsItem>
              <StatsItemTitle>Borrow APY</StatsItemTitle>
              <StatsItemValue>{renderHarvestValue((harvest, apyData) => percentageFormatter(apyData?.apys?.borrowApy || 0))}</StatsItemValue>
            </StatsItem>
            <StatsItem>
              <StatsItemTitle>Yield APY</StatsItemTitle>
              <StatsItemValue>{renderHarvestValue((harvest, apyData) => percentageFormatter(apyData?.apys?.farmApy || 0))}</StatsItemValue>
            </StatsItem>
          </StatsColumn>
        </VaultBoxStats>
      </VaultBoxContainer>
      <VaultBoxContainer>
        <VaultBoxTitle color="purpleAlpha">
          <BoxHeaderSquare color="purpleAlpha" />
          <span>Config</span>
        </VaultBoxTitle>
        <VaultBoxStats>
          <StatsColumn>
            <StatsItem>
              <StatsItemTitle>
                <span>Borrow Limit</span>
                <InfoPopover>
                  <PopoverStyles.Paragraph>
                    The maximum loan amount as a percentage of a user's supply value
                  </PopoverStyles.Paragraph>
                </InfoPopover>
              </StatsItemTitle>
              <StatsItemValue>{percentageFormatter(vault.supplyThreshold)}</StatsItemValue>
            </StatsItem>
            <StatsItem>
              <StatsItemTitle>Liquidation Threshold</StatsItemTitle>
              <StatsItemValue>{percentageFormatter(vault.liquidationThreshold)}</StatsItemValue>
            </StatsItem>
            <StatsItem>
              <StatsItemTitle>
                <span>Target Threshold</span>
                <InfoPopover>
                  {farmingInfo.map((item) => (
                    <PopoverStyles.Row key={item.label}>
                      <PopoverStyles.RowLabel>{item.label}:</PopoverStyles.RowLabel>
                      <PopoverStyles.RowValue>
                        {percentageFormatter(item.value)}
                      </PopoverStyles.RowValue>
                    </PopoverStyles.Row>
                  ))}
                </InfoPopover>
              </StatsItemTitle>
              <StatsItemValue>{percentageFormatter(vault.targetThreshold)}</StatsItemValue>
            </StatsItem>
          </StatsColumn>
          <StatsColumn>
            <StatsItem>
              <StatsItemTitle>
                <span>Performance Fee</span>
                <InfoPopover>
                  <PopoverStyles.Paragraph>
                    The performance fee is charged over the additional smart earnings Altitude makes on your position
                  </PopoverStyles.Paragraph>
                </InfoPopover>
              </StatsItemTitle>
              <StatsItemValue>{percentageFormatter(vault.reserveFactor)}</StatsItemValue>
            </StatsItem>
            <StatsItem>
              <StatsItemTitle>Liquidation Incentive</StatsItemTitle>
              <StatsItemValue>{percentageFormatter(vault.liquidationBonus)}</StatsItemValue>
            </StatsItem>
          </StatsColumn>
        </VaultBoxStats>
      </VaultBoxContainer>
    </VaultStatsContainer>
  )
}

export default VaultStats;
