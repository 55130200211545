import * as Styles from "./styles";
import { MoneyFormatter } from "../../../../../components";
import React from "react";

type HorizontalChartProps = {
  liquidationAmount: number;
  borrowColor: string;
  borrowingLimit: number;
  borrowLimitPercentage: number;
  isUnderChart: boolean;
  liquidationColor: string;
  liquidationLimit: number;
  liquidationPercentage: number;
  borrowLimit: number;
  supplyThreshold: number;
  borrowName: string;
  supplySymbol: string;
  showValues?: boolean;
}

const HorizontalChart = ({
  supplyThreshold,
  borrowLimit,
  borrowName,
  liquidationAmount,
  borrowColor,
  borrowingLimit,
  borrowLimitPercentage,
  isUnderChart,
  liquidationColor,
  liquidationLimit,
  liquidationPercentage,
  supplySymbol,
  showValues
}: HorizontalChartProps) => (
  <Styles.ChartWrapper>
    {!isUnderChart && (
      <Styles.ChartAbove>
        <Styles.ChartLabelItem $left={supplyThreshold * 100}>
          <Styles.ChartLabelItemName>
            <span>Borrow Limit</span>
            {showValues && (
              <Styles.ChartLabelItemValue>
                <MoneyFormatter value={borrowLimit} prefix={`${borrowName} `} />
              </Styles.ChartLabelItemValue>
            )}
          </Styles.ChartLabelItemName>
        </Styles.ChartLabelItem>
        <Styles.ChartLabelItem $left={liquidationLimit}>
          <Styles.ChartLabelItemName>
            <span>Liquidation</span>
            {showValues && (
              <Styles.ChartLabelItemValue>
                <MoneyFormatter value={liquidationAmount} prefix={"$"} />
              </Styles.ChartLabelItemValue>
            )}
          </Styles.ChartLabelItemName>
        </Styles.ChartLabelItem>
      </Styles.ChartAbove>
    )}
    <Styles.Chart>
      <Styles.ChartBorrowLimit
        $color={borrowColor}
        $proportion={borrowingLimit}
        $completeness={borrowLimitPercentage}
      />
      <Styles.ChartLiquidation
        $color={liquidationColor}
        $proportion={liquidationLimit - borrowingLimit}
        $completeness={Math.min(Math.max(liquidationPercentage, 0), 100)}
        $isUnderChart={isUnderChart}
      >
        <Styles.StartStick $isUnderChart={isUnderChart} />
      </Styles.ChartLiquidation>
      <Styles.ChartAfterLiquidation
        $color={liquidationColor}
        $proportion={100 - liquidationLimit}
        $completeness={Math.min(Math.max(liquidationPercentage - 100, 0), 100)}
      />
    </Styles.Chart>
    {isUnderChart && (
      <Styles.ChartBelow>
        <Styles.ChartLabelItem $left={supplyThreshold * 100}>
          <Styles.ChartLabelItemName>
            <span>Borrow Limit</span>
            {showValues && (
              <Styles.ChartLabelItemValue>
                <MoneyFormatter value={borrowLimit} prefix={`${borrowName} `} />
              </Styles.ChartLabelItemValue>
            )}
          </Styles.ChartLabelItemName>
        </Styles.ChartLabelItem>
        <Styles.ChartLabelItem $left={liquidationLimit}>
          <Styles.ChartLabelItemName>
            <span>Liquidation</span>
            {showValues && (
              <Styles.ChartLabelItemValue>
                <Styles.WrapMoneyFormatter
                  value={liquidationAmount}
                  prefix={`${supplySymbol}: $`}
                />
              </Styles.ChartLabelItemValue>
            )}
          </Styles.ChartLabelItemName>
        </Styles.ChartLabelItem>
      </Styles.ChartBelow>
    )}
  </Styles.ChartWrapper>
);

export default HorizontalChart;
