import { LoaderInline } from "../../components/index";
import * as store from "../../store";
import { selectVault } from "../../store/vault/vault.slice";

import * as Styles from "./style";
import numbro from "numbro";
import { TokenDisplayType } from "../../models/vault";
import {BigNumber} from "ethers";
import {formatUnits} from "ethers/lib/utils";

const DEFAULT_BIG_LIMIT = 999999

const TokenBalance = ({
  amount,
  vaultType,
  displayType,
  isLoading = false,
  backLabel = false,
  showSign = false,
}: {
  amount: number | BigNumber;
  vaultType: string;
  displayType: TokenDisplayType;
  isLoading?: boolean;
  backLabel?: boolean;
  showSign?: boolean;
}) => {
  if (isLoading) {
    return <LoaderInline />;
  }

  amount = amount || 0;
  const { integerPart, decimalPart, tokenType, isBig, sign } = tokenBalanceFormatter({
    amount,
    vaultType,
    displayType,
  });

  return (
    <Styles.TokenBalance type={tokenType} value={amount.toString()}>
      {!backLabel && (
        <Styles.TokenPrefix>
          {tokenType}
          {<span>&nbsp;</span>}
        </Styles.TokenPrefix>
      )}
      <Styles.TokenInteger>
        {showSign && sign === -1 ? '-' : ''}
        {integerPart}
      </Styles.TokenInteger>
      {!!decimalPart && (
        <Styles.TokenDecimal>
          .{decimalPart}
          {isBig ? numbro.languageData().abbreviations.thousand : ''}
        </Styles.TokenDecimal>
      )}
      {backLabel && <Styles.TokenDecimal>&nbsp;{tokenType}</Styles.TokenDecimal>}
    </Styles.TokenBalance>
  );
};

export const tokenBalanceFormatter = ({
  amount: rawAmount,
  vaultType,
  displayType,
}: {
  amount: number | BigNumber;
  vaultType: string;
  displayType: string;
}) => {
  const { vault }: any = store.default.getState();
  const selectedVault: any = selectVault(vault, vaultType) || {};

  const decimals = selectedVault[displayType + "Decimals"] || 18;
  const precision = selectedVault[displayType + "Precision"] || 2;
  const tokenType = (selectedVault[displayType + "Symbol"] || "$");

  const amount = BigNumber.isBigNumber(rawAmount) ? +formatUnits(rawAmount, decimals) : rawAmount;
  const isBig = amount > DEFAULT_BIG_LIMIT

  const sign = Math.sign(amount);
  const correctedAmount = Math.abs(amount).toFixed(precision)
  const integerFormatParams = {
    average: isBig,
    mantissa: 0,
    thousandSeparated: true,
    roundingFunction: Math.floor,
  }
  const integerPart = numbro(correctedAmount)
    .format({
      ...integerFormatParams,
      ...isBig ? { forceAverage: 'thousand' } : {}
    })
    .replace(numbro.languageData().abbreviations.thousand, '');

  const decimalPart = numbro(correctedAmount)
    .format({
      mantissa: precision,
      roundingFunction: Math.floor
    })
    .split('.')[1]

  return {
    label: `${integerPart}.${decimalPart} ${tokenType}`,
    integerPart,
    decimals,
    decimalPart,
    tokenType,
    isBig,
    sign
  };
};

export default TokenBalance;
