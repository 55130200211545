import styled from "styled-components";
import { Text, TextHeader } from "../../../../utils/styles/text";
import {
  Box,
  BoxHeader,
  BoxHeaderSquare,
  GridVars,
  ThemePrimary,
  ThemePrimaryText,
  ThemeSecondaryText
} from "../../../../utils";

export const VaultContainer = styled.div`
  width: 100%;
`;
export const VaultSummary = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${GridVars.margin.m2};
`;
export const VaultTitle = styled(TextHeader).attrs({ $size: 'larger' })`
  display: flex;
  align-items: center;
  gap: ${GridVars.gap};
  margin-bottom: 0;
`;

export const TokenIcons = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 2rem;
    height: 2rem;
    &:nth-child(2) {
      position: relative;
      left: -5px;
    }
  }
`;

type VaultTitleProps = {
  $open: boolean
};
export const VaultTitleStats = styled.div<VaultTitleProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${props => props.$open ? `transform: rotate(180deg);` : ''}
`;

type VaultDetailsProps = {
  $height: number
  $expanded: boolean
}
export const VaultDetails = styled.div<VaultDetailsProps>`
  max-height: ${props => props.$expanded ? `${props.$height}px` : '0'};
  overflow: hidden;
  transition: max-height 300ms;
`;

export const VaultStatsContainer = styled.div`
  display: flex;
  gap: ${GridVars.gap};
  @media (max-width: ${GridVars.breakpoint.lg}) {
    flex-direction: column;
  }
`

export const VaultBoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1 0 30%;
`;

export const VaultBoxTitle = styled(BoxHeader)``;

export const VaultBoxStats = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${GridVars.gap};
  @media (max-width: ${GridVars.breakpoint.sm}) {
    flex-direction: column;
  }
  @media (max-width: ${GridVars.breakpoint.lg}) {
    justify-content: start;
    gap: ${GridVars.gap};
  }
`
export const StatsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: ${GridVars.gap};
  @media (max-width: ${GridVars.breakpoint.lg}) {
    flex-direction: row;
    margin-bottom: ${GridVars.gap};
  }
  @media (max-width: ${GridVars.breakpoint.sm}) {
    flex-direction: column;
    margin-bottom: 0;
  }
`
export const StatsItem = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${GridVars.breakpoint.lg}) {
    flex: 1 1 0;
  }
`
export const StatsItemTitle = styled(Text)`
  display: flex;
  align-items: center;
  color: ${ThemeSecondaryText};
`
export const StatsItemValue = styled(Text).attrs({ $size: 'large', $weight: 'strong' })`
  color: ${ThemePrimaryText};
`

type StatsProgressBarProps = {
  $percentage: number
};
export const StatsProgressBar = styled.div<StatsProgressBarProps>`
  width: 80%;
  height: 3px;
  border-radius: 5px;
  background-color: ${ThemePrimaryText};
  &:after {
    content: " ";
    height: 3px;
    display: block;
    width: ${props => `${props.$percentage}%`};
    background-color: ${ThemePrimary};
  }
  @media (max-width: ${GridVars.breakpoint.sm}) {
    width: 50%;
  }
`

export { BoxHeaderSquare }
