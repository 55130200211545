import ContentLoader from "react-content-loader";
import { useTheme } from "styled-components";
import { ThemeGray300, ThemeGray500 } from "../../utils";

const SPEED = 1;
const HEIGHT = 32;
const WIDTH = "100%";

const paddingTop = 2;
const paddingRX = 4;
const paddingRY = 4;

interface Rect {
  y: number;
  x: number | string;
  rx: number;
  ry: number;
  width: number | string;
  height: number;
}

type LoaderInlineProps = {
  height?: number;
  width?: number | string;
  loaderSettings?: Rect[];
}

const defaultRect: Rect = {
  x: 0,
  y: paddingTop,
  rx: paddingRX,
  ry: paddingRY,
  width: WIDTH,
  height: HEIGHT,
};

const LoaderInline = ({
  height = HEIGHT,
  width = WIDTH,
  loaderSettings = [defaultRect],
}: LoaderInlineProps) => {
  const theme = useTheme();
  const bgColor = ThemeGray300({ theme })
  const fgColor = ThemeGray500({ theme })
  return (
    <div>
      <ContentLoader
        speed={SPEED}
        height={height}
        width={width}
        backgroundColor={bgColor}
        foregroundColor={fgColor}
      >
        {loaderSettings.map((rect: Rect, index) => (
          <rect key={`loaderIndex${index}${rect.x}`} {...rect} />
        ))}
      </ContentLoader>
    </div>
  )
};

export default LoaderInline;
