import * as Styles from "./style";
import React, { useMemo } from "react";
import LoanToValueChart from "./loan-to-value-chart";
import { useUserPosition } from "../../../../hooks/useUserPosition";
import { TokenDisplayType } from "../../../../models/vault";
import TokenBalance from "../../../../components/token-balance";
import { useUserVaultInfo, useVault } from "../../../../hooks/useAppSelector";
import MoneyFormatter from "../../../../components/money-formatter/money-formatter";
import { LoaderInline } from "../../../../components";
import { getLiquidationAmount } from "../../modals/components/bar-chart/bar-chart.logic";
import { useBackgroundLoading } from "../../../../hooks/useBackgroundLoading";

const ValueLoader = () => (
  <LoaderInline height={12} loaderSettings={[
    {
      y: 0,
      x: "60%",
      rx: 3,
      ry: 3,
      width: "40%",
      height: 12,
    }
  ]} />
);

export const LoanHealth = () => {
  const { data: userInfo, isLoading: userInfoLoading } = useUserVaultInfo();
  const { data: position, isLoading: userPositionLoading } = useUserPosition();
  const { data: vault, isLoading: vaultLoading } = useVault();
  const isDataLoading = useMemo(() => (userPositionLoading || vaultLoading || userInfoLoading), [userPositionLoading, vaultLoading, userInfoLoading]);
  const isLoading = useBackgroundLoading(isDataLoading)

  const liquidationPrice = useMemo(() => {
    if (userInfo?.debtTokenAmount && vault?.liquidationThreshold) {
      return getLiquidationAmount(userInfo?.debtTokenAmount, vault?.liquidationThreshold, userInfo?.collateralTokenAmount) * vault?.borrowToUsdRatio;
    }
    return 0;
  }, [userInfo, vault]);

  return (
    <Styles.BorrowingCapacityContainer>
      <Styles.BorrowingCapacityTitle>
        <Styles.BoxHeader color="pinkAlpha">
          <Styles.BoxHeaderSquare color="pinkAlpha" />
          <span>Loan Health</span>
        </Styles.BoxHeader>
      </Styles.BorrowingCapacityTitle>
      <Styles.BorrowingCapacityDescription>
        <Styles.BorrowingCapacitySubHeader>
          The figures are calculated based on the current value of your
          supply and will change as the value fluctuates.
        </Styles.BorrowingCapacitySubHeader>
      </Styles.BorrowingCapacityDescription>
      <Styles.ChartWrapper>
        <LoanToValueChart />
      </Styles.ChartWrapper>
      <Styles.LoanHealthInfo>
        <Styles.LoanHealthInfoRow>
          <Styles.LoanHealthInfoLabel>Borrow limit</Styles.LoanHealthInfoLabel>
          <Styles.LoanHealthInfoValue>
            {isLoading ? <ValueLoader /> : (
              <TokenBalance
                displayType={TokenDisplayType.borrow}
                vaultType={vault?.id || ''}
                amount={position?.current.borrowLimit || 0}
              />
            )}
          </Styles.LoanHealthInfoValue>
        </Styles.LoanHealthInfoRow>
        <Styles.LoanHealthInfoRow>
          <Styles.LoanHealthInfoLabel>Liquidation price</Styles.LoanHealthInfoLabel>
          <Styles.LoanHealthInfoValue>
            {isLoading ? <ValueLoader /> : (
              <MoneyFormatter
                prefix={`${vault?.supplySymbol}: $`}
                value={liquidationPrice}
              />
            )}
          </Styles.LoanHealthInfoValue>
        </Styles.LoanHealthInfoRow>
      </Styles.LoanHealthInfo>
    </Styles.BorrowingCapacityContainer>
  );
};
