import { createAsyncThunk } from "@reduxjs/toolkit";
import * as store from "../../../store";

import { fetchUserVaultData } from "./fetchUserVaults";
import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { selectVault } from "../../vault/vault.slice";
import { TransactionFlowPayload, TransactionType } from "../../transaction/transaction.slice";
import { addTransactionFlow, startTransactionFlow } from "../../../store";
import { BigNumber } from "ethers";

export const claimRewards = createAsyncThunk<void, any>(
  "userVault/claimRewards",
  async ({ selectedVault, claimAmount }: { selectedVault: VaultName, claimAmount: BigNumber }, thunkApi) => {

    const { vault } = store.default.getState();
    const { borrowName }: any = selectVault(vault, selectedVault) || {};

    const transactionFlow: TransactionFlowPayload = [];

    try {
      transactionFlow.push({
        id: `claim-${selectedVault}-${borrowName}`,
        index: 0,
        type: TransactionType.CLAIM,
        info: {
          vault: selectedVault,
          claimAmount,
          claimToken: borrowName,
        },
      });

      thunkApi.dispatch(addTransactionFlow(transactionFlow));
      thunkApi.dispatch(startTransactionFlow());
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }

    thunkApi.dispatch(fetchUserVaultData({ selectedVault }));
  }
);
