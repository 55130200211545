import { useCallback, useEffect } from "react";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";
import { readLocalStorage, saveLocalStorage } from "../store";

export default function useLocalStorage<V>(key: string, initialValue: any = null): [V, (value: V) => void] {
  const dispatch= useAppDispatch();
  const { [key]: value = initialValue } = useAppSelector((state) => state.global.localStorage);

  useEffect(() => {
    dispatch(readLocalStorage(key));
  }, [dispatch, key]);

  const handleValueChange = useCallback((value: V) => {
    dispatch(saveLocalStorage({ key, value }))
  }, [dispatch, key]);

  return [value, handleValueChange]
}
