import styled from "styled-components";
import {
  GridVars,
  Box,
  ElementHR,
  LayoutSpaceBetweenRow,
  ThemePrimaryText,
  ThemeSecondaryText,
  LayoutInlineFlexRow
} from "../../../../utils";
import {
  TextParagraph,
  TextHeader,
  Text, textSizes
} from '../../../../utils/styles/text'
import { Button } from "../../../../utils/styles/button";

export const SmartRepaymentContainer = styled.div`
  grid-area: smartRepayments;
`;

export const SmartHeader = styled(TextHeader).attrs({ $size: 'larger' })``;

export const SmartDesc = styled(TextParagraph)``;

export const SmartValue = styled(TextHeader).attrs({ $size: 'larger' })`
  margin-top: 32px;
  color: ${ThemePrimaryText};
`;

export const SmartLine = styled(ElementHR)``;

export const SmartValueBoxContainer = styled.div`
  margin-top: ${GridVars.margin.m2};
  display: flex;
  flex-direction: column;
  gap: ${GridVars.gap};
`;

export const SmartRepaymentWrapper = styled(Box)`
  height: 100%;
  @media (min-width: ${GridVars.breakpoint.xl}) {
    height: auto;
  }
`;

export const SmartValueBox = styled(LayoutSpaceBetweenRow)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
`;

export const SmartValueContainer = styled(LayoutInlineFlexRow)`
  display: flex;
  justify-content: end;
  align-items: center;
  color: ${ThemeSecondaryText};
  width: 50%;
`
export const InlineButton = styled(Button).attrs({
  $variant: 'tertiary',
  $size: 'inline'
})``

export const IncentivesValueButton = styled(Button).attrs({
  $variant: 'label',
  $size: 'tiny'
})`
  display: inline-flex;
  font-size: ${textSizes.small};
`

export const SmartValueLabel = styled(Text)`
  display: flex;
  color: ${ThemePrimaryText};
  width: 50%;
  white-space: nowrap;
`;

export const SmartValueValue = styled(Text)`
  text-align: right;
  span {
    color: ${ThemeSecondaryText};
  }
`;
export const SmartValuePopover = styled.div`
  display: inline-flex;
  justify-content: right;
  align-items: center;
  text-align: right;
  span {
    color: ${ThemeSecondaryText};
  }
`;

export const SmartValueAmount = styled(Text)`
  span {
    color: ${ThemePrimaryText};
  }
`;
