import * as Styles from "../style";
import { useMemo } from "react";
import { TransactionOk } from "../icons";
import { useTransactionMode } from "../../../../hooks/useTrasactionMode";
import useBlockExplorer from "../../../../hooks/useBlockExplorer";

const Confirmed = () => {
  const { transactionUrl } = useBlockExplorer();
  const { transactions} = useTransactionMode();
  const explorerUrl = useMemo(() => {
    const lastTx = transactions[transactions.length - 1];
    if (lastTx) {
      return transactionUrl(lastTx.hash)
    }
    return null;
  }, [transactions, transactionUrl]);

  return (
    <>
      <Styles.TransactionHeader>
        Confirmed
      </Styles.TransactionHeader>
      <Styles.ActionsContainer>
        <Styles.ConfirmedContainer>
          <TransactionOk width="75px" height="75px" />
          {explorerUrl && <a href={explorerUrl} target="_blank" rel="noreferrer noopener">view on explorer</a>}
        </Styles.ConfirmedContainer>
      </Styles.ActionsContainer>
    </>
  );
};

export default Confirmed;
