import { IconProps } from "../../../../assets/images/iconComponents";
const TransactionOut = ({ inactive, ...rest }: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="12" cy="12" r="12" fill={inactive ? '#606060' : 'white'}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M12.0794 2.80925C12.8607 2.7829 13.4801 3.4011 13.5099 4.17757L13.5097 12.8232C13.5097 13.2736 13.2987 13.6984 12.9389 13.9724C12.4279 14.3616 11.7206 14.3711 11.1995 13.9959L11.1793 13.9814C10.7929 13.7031 10.5641 13.2575 10.5641 12.7831L10.5638 4.17756C10.534 3.40109 11.2982 2.83561 12.0794 2.80925Z"
          fill={inactive ? '#A5A5A5' : 'black'}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M6.54125 9.74047C5.92304 9.12226 5.94298 8.09999 6.5858 7.45717L10.9505 3.09245C11.5933 2.44963 12.6156 2.42969 13.2338 3.0479L17.1516 6.96573C17.7699 7.58394 17.7499 8.60621 17.1071 9.24903C16.4643 9.89185 15.442 9.91179 14.8238 9.29358L12.0254 6.49513L8.82456 9.69592C8.18174 10.3387 7.15947 10.3587 6.54125 9.74047Z"
          fill={inactive ? '#A5A5A5' : 'black'}/>
    <path d="M8 18H16" stroke={inactive ? '#A5A5A5' : '#4F8BFF'} strokeWidth="3.5" strokeLinecap="round"/>
  </svg>
);

export default TransactionOut;
