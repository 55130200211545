import {createContext, useEffect, PropsWithChildren, useContext, useMemo, useCallback, useState} from 'react';
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import {
  ApprovalInfo, BorrowInfo, ClaimInfo,
  DepositAndBorrowInfo, DepositInfo, RepayInfo,
  TransactionDetails,
  TransactionType, WithdrawInfo
} from "../store/transaction/transaction.slice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { utils } from "ethers";
import { Address } from "viem";
import { TRANSACTION_STATUS } from "../utils";
import {
  acceptTransaction,
  clearAllTransactions,
  finalizeTransaction,
  nextTransaction,
  failTransaction,
  retryTransaction as retryTransactionAction
} from "../store";
import { useSdk } from "./sdk-provider";
import { captureException } from "@sentry/react";

interface TransactionProviderInterface {
  currentTransaction: TransactionDetails | null;
  isConfirmed: boolean;
  isProcessing: boolean;
  isTransactionDelayed: boolean;
  clearTransactions: () => void;
  retryTransaction: () => void;
}

export const TransactionContext = createContext<TransactionProviderInterface>({
  currentTransaction: null,
  isConfirmed: false,
  clearTransactions: () => {},
  isProcessing: false,
  isTransactionDelayed: false,
  retryTransaction: () => {}
});

export const TransactionProvider = (props: PropsWithChildren) => {
  const { data: hash, error: txError, reset: resetWriteState, writeContract } = useWriteContract();

  const { sdk } = useSdk();
  const dispatch = useAppDispatch();
  const transactionFlow = useAppSelector((state) => state.transaction.transactions);
  const currentTransactionId = useAppSelector((state) => state.transaction.currentTransaction);
  const currentTransaction = useMemo(() => currentTransactionId !== null ? transactionFlow[currentTransactionId] : null, [currentTransactionId, transactionFlow]);
  const [isTransactionDelayed, seIsTransactionDelayed] = useState(false);

  const pollingInterval = useMemo(() => isTransactionDelayed ? 15_000 : 1_000, [isTransactionDelayed]);
  const { data: txReceipt, error: execError, isSuccess: isConfirmed } = useWaitForTransactionReceipt({ hash: currentTransaction?.hash, pollingInterval });

  useEffect(() => {
    let interval = setInterval(() => {
      if (currentTransaction?.addedTime && currentTransaction?.status === TRANSACTION_STATUS.processing) {
        const timeDiff = Date.now() - currentTransaction.addedTime;
        if (timeDiff > 60_000) {
          seIsTransactionDelayed(true);
          return;
        }
      }
      seIsTransactionDelayed(false);
    }, 1000)
    return () => clearInterval(interval);
  }, [currentTransaction]);

  const isProcessing = useMemo(() => Object.values(transactionFlow).some(t => t.type !== TRANSACTION_STATUS.confirmed), [transactionFlow])

  const txDetails = useMemo(() => {
    if (!currentTransaction) {
      return null;
    }
    switch (currentTransaction.type) {
      case TransactionType.APPROVAL:
        const tokenInfo = currentTransaction.info as ApprovalInfo;
        return {
          contract: sdk.contracts.tokens[tokenInfo.token],
          functionName: "approve",
          args: [tokenInfo.spender, tokenInfo.amount.toBigInt()],
        };
      case TransactionType.DEPOSIT_AND_BORROW:
        const depositAndBorrowInfo = currentTransaction.info as DepositAndBorrowInfo;
        return {
          contract: sdk.contracts.vaults[depositAndBorrowInfo.vault].vault,
          functionName: "depositAndBorrow",
          args: [depositAndBorrowInfo.depositAmount.toBigInt(), depositAndBorrowInfo.borrowAmount.toBigInt()],
        };
      case TransactionType.DEPOSIT:
        const depositInfo = currentTransaction.info as DepositInfo;
        return {
          contract: sdk.contracts.vaults[depositInfo.vault].vault,
          functionName: "deposit",
          args: [depositInfo.depositAmount.toBigInt(), depositInfo.userAddress],
        };
      case TransactionType.BORROW:
        const borrowInfo = currentTransaction.info as BorrowInfo;
        return {
          contract: sdk.contracts.vaults[borrowInfo.vault].vault,
          functionName: "borrow",
          args: [borrowInfo.borrowAmount.toBigInt()],
        };
      case TransactionType.WITHDRAW:
        const withdrawInfo = currentTransaction.info as WithdrawInfo;
        return {
          contract: sdk.contracts.vaults[withdrawInfo.vault].vault,
          functionName: "withdraw",
          args: [withdrawInfo.withdrawAmount.toBigInt(), withdrawInfo.userAddress],
        };
      case TransactionType.REPAY:
        const repayInfo = currentTransaction.info as RepayInfo;
        return {
          contract: sdk.contracts.vaults[repayInfo.vault].vault,
          functionName: "repay",
          args: [repayInfo.repayAmount.toBigInt(), repayInfo.userAddress],
        };
      case TransactionType.CLAIM:
        const claimInfo = currentTransaction.info as ClaimInfo;
        return {
          contract: sdk.contracts.vaults[claimInfo.vault].vault,
          functionName: "claimRewards",
          args: [claimInfo.claimAmount.toBigInt()],
        };
      default:
        return null;
    }
  }, [currentTransaction, sdk]);

  const writeVariables = useMemo(() => {
    if (!txDetails) {
      return null;
    }
    const abi = txDetails.contract.interface.format(utils.FormatTypes.json) as string
    return {
      abi: JSON.parse(abi),
      address: txDetails.contract.address as Address,
      functionName: txDetails.functionName,
      args: txDetails.args,
    }
  }, [txDetails]);

  const executeTransaction = useCallback(() => {
    if (!writeVariables) {
      return;
    }
    resetWriteState();
    writeContract(writeVariables);
  }, [writeVariables, resetWriteState, writeContract])

  useEffect(() => {
    if (currentTransaction !== null) {
      if (currentTransaction.status !== TRANSACTION_STATUS.initiated) {
        return;
      }
      executeTransaction()
    }
  }, [currentTransaction, executeTransaction]);

  useEffect(() => {
    if (
      isConfirmed
      && currentTransaction
      && currentTransaction.status !== TRANSACTION_STATUS.confirmed
      && txReceipt
      && txReceipt.transactionHash
      && currentTransaction.hash?.toLowerCase() === txReceipt.transactionHash.toLowerCase()
    ) {
      dispatch(finalizeTransaction({ txReceipt, id: currentTransaction.id}));
      resetWriteState();
      dispatch(nextTransaction());
    }
  }, [currentTransaction, dispatch, isConfirmed, txReceipt, resetWriteState]);

  useEffect(() => {
    if (hash && currentTransactionId) {
      dispatch(acceptTransaction({ txHash: hash, id: currentTransactionId }));
      resetWriteState();
    }
  }, [dispatch, currentTransactionId, hash, resetWriteState]);

  useEffect(() => {
    const error = txError || execError;
    if (error && currentTransactionId) {
      dispatch(failTransaction({ id: currentTransactionId, txError: error }));
      captureException(error);
    }
  }, [execError, currentTransactionId, dispatch, txError]);

  const clearTransactions = useCallback(() => {
    dispatch(clearAllTransactions());
    resetWriteState();
  }, [dispatch, resetWriteState]);

  const retryTransaction = useCallback(() => {
    if (currentTransactionId) {
      dispatch(retryTransactionAction({ id: currentTransactionId }))
    }
  }, [dispatch, currentTransactionId])

  return (
    <TransactionContext.Provider value={{ currentTransaction, isConfirmed, clearTransactions, isProcessing, retryTransaction, isTransactionDelayed }}>
      {props.children}
    </TransactionContext.Provider>
  );
}

export const useCurrentTransaction = () => {
  return useContext(TransactionContext);
}
