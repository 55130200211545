import React from "react";
import { useCallback, useMemo, useState } from "react";

import { TokenInput } from "../../../../../components";
import { useAppDispatch } from "../../../../../hooks";
import { useAppSelector } from "../../../../../hooks";
import { UserVaultModel, VaultStats } from "../../../../../models";
import { VaultModel } from "../../../../../models/vault";
import {
  BarChart,
  StatisticsRow,
  depositInputValidator,
  ModalStyles,
} from "../../../../../modules/dashboard/modals";
import { depositAndBorrow } from "../../../../../store/userVault/actions";
import { useUserPosition, UserPosition } from "../../../../../hooks/useUserPosition";
import { getLiquidationAmount } from "../../components/bar-chart/bar-chart.logic";
import ReadOnlyWarning from "../../components/read-only-warning";
import { useUser, useUserVaultInfo, useVault, useVaultStats } from "../../../../../hooks/useAppSelector";
import { formatUnits } from "ethers/lib/utils";
import { ValidatorFunction } from "../../../../../components/token-input/token-input";
import { BigNumber, constants } from "ethers";
import * as Styles from "../../styles";

export const DepositTab = () => {
  const dispatch = useAppDispatch();

  const { data: vault} = useVault();
  const { selectedVault } = useAppSelector((state) => state.global);
  const { data: vaultStats } = useVaultStats(vault as VaultModel);
  const { data: { isReadOnly } = {} } = useUser();


  const {
    supplyBasePrice,
    borrowToUsdRatio,
    supplyThreshold,
    userMinDepositLimit,
    userMaxDepositLimit,
    vaultDepositLimit,
    liquidationThreshold,
    supplySymbol,
    borrowSymbol,
    supplyDecimals,
    supplyPrecision
  } = vault as VaultModel || {}

  const { totalSupply: vaultTotalSupply } = vaultStats as VaultStats || {}

  const { data: vaultInfo} = useUserVaultInfo()

  const {
    supplyTokenAmount,
    supplyTokenAmountBn,
    collateralTokenAmount,
    collateralTokenAmountBn,
    debtTokenAmount,
    debtTokenAmountBn,
  } = vaultInfo as UserVaultModel || {}
  const [isTouched, setIsTouched] = useState(false);
  const [collateralDepositAmount, setCollateralDepositAmount] = useState(constants.Zero);

  const depositLimit = useMemo(
    () => userMaxDepositLimit - collateralTokenAmount,
    [userMaxDepositLimit, collateralTokenAmount]
  );

  const { data: userPosition } = useUserPosition();
  const {
    loan: { loanApy },
    current: { borrowLimit, loanToValue },
  } = userPosition as UserPosition;
  const newCollateralAmount = collateralDepositAmount.add(collateralTokenAmountBn);
  const {
    data: futureUserPosition
  } = useUserPosition(debtTokenAmountBn, newCollateralAmount);

  const {
    loan: { loanApy: futureLoanAPY },
    current: {
      borrowLimit: newBorrowLimit,
      loanToValue: futureLoanToValue,
      liquidationLimit: newLiquidationLimit
    },
  } = futureUserPosition as UserPosition;

  const handleDeposit = useCallback(() => dispatch(
    depositAndBorrow({ deposit: collateralDepositAmount, selectedVault })
  ), [collateralDepositAmount, dispatch, selectedVault]);

  const handleDepositChange = useCallback((value?: BigNumber) => {
    setIsTouched(!!value);
    setCollateralDepositAmount(value || constants.Zero);
  }, []);

  const [isValid, setIsValid] = useState(false);
  const depositValidator = useCallback<ValidatorFunction>(
    (value) => {
      const validatorResult = depositInputValidator(
        supplySymbol,
        collateralTokenAmount,
        Number(value),
        supplyTokenAmount,
        depositLimit,
        userMinDepositLimit,
        vaultTotalSupply,
        vaultDepositLimit,
        selectedVault
      );
      setIsValid(!validatorResult);
      return validatorResult;
    },
    [supplyTokenAmount, depositLimit, vaultDepositLimit, collateralTokenAmount, selectedVault, supplySymbol, userMinDepositLimit, vaultTotalSupply]
  );

  const liquidationPrice = useCallback((collateral: number) => {
    return getLiquidationAmount(debtTokenAmount, liquidationThreshold, collateral) * borrowToUsdRatio;
  }, [debtTokenAmount, borrowToUsdRatio, liquidationThreshold]);

  const isDepositButtonDisabled = useMemo(() => isReadOnly || !isValid || collateralDepositAmount.lte(0), [collateralDepositAmount, isReadOnly, isValid])

  return (
    <>
      <ModalStyles.AmountContainer data-testid="deposit-amount-input">
        <TokenInput
          label="Deposit Amount"
          tokenName={supplySymbol}
          name={supplySymbol + "Amount"}
          validator={depositValidator}
          onValueChange={handleDepositChange}
          maxValue={supplyTokenAmountBn}
          currencyExchangeRate={supplyBasePrice * borrowToUsdRatio}
          precision={supplyPrecision}
        />
      </ModalStyles.AmountContainer>
      <ModalStyles.StatisticsContainer>
        <BarChart
          to={futureLoanToValue}
          from={loanToValue}
          balance={debtTokenAmount}
          collateral={+formatUnits(newCollateralAmount, supplyDecimals)}
          borrowLimit={newBorrowLimit}
          liquidation={newLiquidationLimit}
          liquidationThreshold={liquidationThreshold}
          supplyThreshold={supplyThreshold}
          borrowToUsdRatio={borrowToUsdRatio}
          borrowName={borrowSymbol}
          supplySymbol={supplySymbol}
        />
        <Styles.StatisticsRowDivider />
        <StatisticsRow
          touched={isTouched}
          label={"Deposit amount"}
          from={collateralTokenAmount}
          to={+formatUnits(collateralTokenAmountBn.add(collateralDepositAmount), supplyDecimals)}
          prefix={supplySymbol + " "}
        />
        <StatisticsRow
          touched={isTouched}
          label={"Borrow limit"}
          from={borrowLimit}
          to={newBorrowLimit}
          prefix={borrowSymbol + " "}
        />
        <StatisticsRow
          touched={isTouched}
          label={"Liquidation price"}
          from={liquidationPrice(collateralTokenAmount)}
          to={liquidationPrice(+formatUnits(newCollateralAmount, supplyDecimals))}
          prefix={`${supplySymbol}: $`}
        />
        <StatisticsRow
          touched={isTouched}
          label={"Loan APY"}
          from={loanApy || 0}
          to={futureLoanAPY}
          postfix={"%"}
          prefix={""}
        />
        <ModalStyles.TakeLoanBtn
          onClick={handleDeposit}
          disabled={isDepositButtonDisabled}
          data-testid="deposit-action-btn"
        >
          Deposit
        </ModalStyles.TakeLoanBtn>
        <ReadOnlyWarning />
      </ModalStyles.StatisticsContainer>
    </>
  );
};
