import React from "react";
import { useEffect, useMemo } from "react";

import { riskWarningToken } from "../../config";
import { useAppSelector } from "../../hooks";
import { Toast } from "../../models";
import { StartLoanBanner } from "../../modules/dashboard/components";
import { RISK_APPROVAL_TIMEOUT, RiskWarningModal } from "../../modules/dashboard/modals/risk-warning/risk-warning.modal";
import { usePopupManager } from "react-popup-manager";
import { popupInstance } from "react-popup-manager/dist/src/popupsDef";
import { selectAllToasts } from "../../store/toast/toast.slice";

import LoanHealth from "./components/loan-health";
import SmartRepayments from "./components/smart-repayments";
import UserLoanStats from "./components/user-loan-stats";
import UserStats from "./components/user-stats";
import VaultComposition from "./components/vault-composition/vault-composition";
import * as Styles from "./styles";
import { useUserVaultInfo } from "../../hooks/useAppSelector";
import {
  showLocationNotAllowedMessage,
  useLocationDataContext,
} from "./locationState";
import { TransactionModal } from "../../components/transaction-modal";

let modal: popupInstance | null = null;

const Dashboard = () => {
  const popupManager = usePopupManager();

  const { isNewUser } = useUserVaultInfo();

  useEffect(() => {
    const timeStamp = Number(localStorage.getItem(riskWarningToken));
    if ((!timeStamp || timeStamp < Date.now() - RISK_APPROVAL_TIMEOUT) && !modal) {
      modal = popupManager.open(RiskWarningModal);
    }
  }, [popupManager]);

  // TODO: Is there a better place to manage this?
  // Tracking if we should add navbar margin when menubar is in 'fixed' position.
  const toastStore = useAppSelector((state) => state.toast);
  const toasts: Array<Toast> = selectAllToasts(toastStore);
  const toastBarEnabled = useMemo(() => !!toasts.length, [toasts]);

  const { data } = useLocationDataContext();
  useEffect(() => {
    const countryCode = data?.country;
    showLocationNotAllowedMessage(countryCode);
  }, [data]);

  return (
    <Styles.DashboardPage
      data-testid="dashboard"
      $toastBarEnabled={toastBarEnabled}
    >
      <Styles.DashboardGrid $banner={isNewUser}>
        {isNewUser ? <StartLoanBanner /> : <></>}
        <LoanHealth />
        <UserStats />
        <UserLoanStats />
        <SmartRepayments />
        <VaultComposition />
        <TransactionModal />
      </Styles.DashboardGrid>
    </Styles.DashboardPage>
  );
};

export default Dashboard;
