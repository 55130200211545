import * as React from "react";
import { IconProps } from "./index";

const SvgLogoWstEth = ({ inactive, ...rest }: IconProps) => (
  <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect width="512" height="512" rx="256" fill={inactive ? '#606060' : '#00A3FF'}/>
    <path opacity=".6"
          d="m361.012 237.812 2.866 4.397c32.326 49.589 25.106 114.533-17.358 156.139-24.981 24.478-57.722 36.718-90.464 36.721l104.956-197.257Z"
          fill={inactive ? '#A5A5A5' : '#fff'}/>
    <path opacity=".2" d="M256.044 297.764 361 237.812 256.044 435.069V297.764Z" fill={inactive ? '#A5A5A5' : '#fff'}/>
    <path
      d="m150.988 237.812-2.866 4.397c-32.326 49.589-25.106 114.533 17.358 156.139 24.981 24.478 57.722 36.718 90.464 36.721L150.988 237.812Z"
      fill={inactive ? '#A5A5A5' : '#fff'}/>
    <path opacity=".6" d="m255.914 297.764-104.956-59.952 104.956 197.257V297.764Z" fill={inactive ? '#A5A5A5' : '#fff'}/>
    <path opacity=".2" d="M256.083 163.833v103.4l90.408-51.667-90.408-51.733Z" fill={inactive ? '#A5A5A5' : '#fff'}/>
    <path opacity=".6" d="m256.056 163.833-90.473 51.732 90.473 51.668v-103.4Z" fill={inactive ? '#A5A5A5' : '#fff'}/>
    <path d="m256.056 76.875-90.473 138.724 90.473-51.877V76.875Z" fill={inactive ? '#A5A5A5' : '#fff'}/>
    <path opacity=".6" d="m256.083 163.706 90.477 51.879-90.477-138.793v86.914Z" fill={inactive ? '#A5A5A5' : '#fff'}/>
  </svg>

);
export default SvgLogoWstEth;
