import { useAccount } from "wagmi";
import { useCallback, useMemo } from "react";

const useBlockExplorer = () => {
  const { chain } = useAccount();
  const baseUrl = useMemo(() => chain?.blockExplorers?.default.url, [chain]);

  const transactionUrl = useCallback((hash?: `0x${string}`) => {
    if (hash) {
      return `${baseUrl}/tx/${hash}`
    }
    return '';
  }, [baseUrl]);

  const addressUrl = useCallback((address?: `0x${string}`) => {
    if (address) {
      return `${baseUrl}/address/${address}`
    }
    return '';
  }, [baseUrl]);

  return {
    transactionUrl,
    addressUrl
  }
}

export default useBlockExplorer;
