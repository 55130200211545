import React, {useCallback, useMemo, useState} from "react";
import { usePopupManager } from "react-popup-manager";

import { ClaimModal, InfoPopover, LoaderInline, MoneyFormatter } from "../../../../components";
import DropDownMenu from "../../../../components/drop-down-menu";
import TokenBalance from "../../../../components/token-balance";
import { TokenDisplayType } from "../../../../models/vault";
import BorrowRepaymentModal from "../../../../modules/dashboard/modals/borrow-repayment";
import DepositWithdrawModal from "../../../../modules/dashboard/modals/deposit-withdraw";
import { TakeLoanModal } from "../../modals/take-loan/take-loan.modal";

import * as Styles from "./style";
import Toggle from "../../../../components/toggle/toggle";
import * as PopoverStyles from "../../../../components/custom-popover/styles";
import { useUser, useUserVaultInfo, useVault } from "../../../../hooks/useAppSelector";
import { UserPosition, useUserPosition }  from "../../../../hooks/useUserPosition";
import { ArrowDown, ArrowUp } from "../../../../assets/images/iconComponents";
import { Tabs as BorrowModalTabs } from "../../modals/borrow-repayment/borrow-repayment.modal";
import { Tabs as DepositModalTabs } from "../../modals/deposit-withdraw/deposit-withdraw.modal";
import { isBlockedCountry, useLocationDataContext } from "../../locationState";
import { useBackgroundLoading } from "../../../../hooks/useBackgroundLoading";

const UserLoanStats = () => {
  const popupManager = usePopupManager();
  const { data: { walletConnected } = {}, isLoading: userLoading } = useUser();
  const { data: { id: selectedVault, supplyBasePrice = 0, borrowSymbol } = {}, isLoading: vaultLoading } = useVault();
  const { data: currentPosition, isLoading: userPositionLoading } = useUserPosition();
  const { currentHarvest: { currentHarvestEarnings: projectedEarnings } } = currentPosition as UserPosition;
  const {
    data: {
      id: vaultId,
      debtTokenAmount = 0,
      collateralTokenAmount = 0,
      isActionsByAllowlistBlocked,
      claimableRewards
    } = {},
    isLoading: userVaultLoading,
    isNewUser
  } = useUserVaultInfo();

  const [includeProjected, setIncludeProjected] = useState<boolean>(true);

  const isDataLoading = useMemo(() => (userVaultLoading || vaultLoading || userLoading || userPositionLoading), [userVaultLoading, vaultLoading, userLoading, userPositionLoading]);
  const isLoading = useBackgroundLoading(isDataLoading);

  const { data } = useLocationDataContext();
  const countryCode = data?.country;
  const isDataReady = useMemo(() => walletConnected && vaultId && !isLoading, [walletConnected, vaultId, isLoading]);

  const loanAmount = useMemo(() => {
    // Check to make sure projectedEarnings is not NaN
    const calculatedProjectedEarnings = isNaN(projectedEarnings) ? 0 : projectedEarnings;
    if (includeProjected && debtTokenAmount > 0) {
        // Including projected for users who have debt
        if (debtTokenAmount > calculatedProjectedEarnings) {
          // If the debt is larger than projected earnings, return remaining debt
          return debtTokenAmount - calculatedProjectedEarnings;
        } else {
          // Return 0 as debt repaid by projected earnings
          return 0
        }
    }
    return debtTokenAmount;
  }, [debtTokenAmount, projectedEarnings, includeProjected]);

  const openBorrowRepayPopup = useCallback((tab?: string) => {
    popupManager.open<any>(BorrowRepaymentModal, {
      title: "BorrowRepaymentModal",
      onClose: () => {},
      defaultTab: tab
    });
  }, [popupManager]);

  const openDepositWithdrawPopup = useCallback((tab?: string) => {
    popupManager.open<any>(DepositWithdrawModal, {
      title: "DepositWithdrawModal",
      onClose: () => {},
      defaultTab: tab
    });
  }, [popupManager]);

  const openTakeLoanPopup = useCallback(() => {
    popupManager.open<any>(TakeLoanModal, {
      title: "TakeLoanModal",
      onClose: () => {},
    });
  }, [popupManager]);

  const openClaimRewards = useCallback(() => {
    popupManager.open<any>(ClaimModal, {
      title: "ClaimRewardsModal",
      onClose: () => {},
    });
  }, [popupManager]);

  const menuItems = useMemo(
    () => [
      {
        disabled: !isDataReady || !!isActionsByAllowlistBlocked || isBlockedCountry(countryCode),
        label: "Deposit",
        callback: openDepositWithdrawPopup,
      },
      {
        disabled: !isDataReady || !!isActionsByAllowlistBlocked || isBlockedCountry(countryCode),
        label: "Withdraw",
        callback: () => openDepositWithdrawPopup(DepositModalTabs.withdraw),
      },
      {
        disabled: !isDataReady || !!isActionsByAllowlistBlocked || isBlockedCountry(countryCode),
        label: "Borrow",
        callback: openBorrowRepayPopup,
      },
      {
        disabled: !isDataReady || !!isActionsByAllowlistBlocked || isBlockedCountry(countryCode),
        label: "Repay",
        callback: () => openBorrowRepayPopup(BorrowModalTabs.repay),
      },
      {
        disabled: !isDataReady || !!isActionsByAllowlistBlocked || isBlockedCountry(countryCode),
        label: "Deposit & Borrow",
        callback: openTakeLoanPopup,
      },
      {
        disabled: !isDataReady || !claimableRewards || isBlockedCountry(countryCode),
        label: "Claim Earnings",
        callback: openClaimRewards,
      },
    ],
    [isDataReady, isActionsByAllowlistBlocked, openDepositWithdrawPopup, openBorrowRepayPopup, openTakeLoanPopup, claimableRewards, openClaimRewards, countryCode]
  );

  const collateralAmountInBorrow = useMemo(
    () => supplyBasePrice * collateralTokenAmount,
    [supplyBasePrice, collateralTokenAmount]
  );

  const updateLoanButtonDisabled = useMemo(
    () =>
      !isDataReady ||
      (!collateralTokenAmount && debtTokenAmount <= 0) ||
      isActionsByAllowlistBlocked ||
      isBlockedCountry(countryCode),
    [
      collateralTokenAmount,
      debtTokenAmount,
      isActionsByAllowlistBlocked,
      isDataReady,
      countryCode
    ]
  );

  const updateCollateralButtonDisabled = useMemo(
    () =>
      !isDataReady ||
      isActionsByAllowlistBlocked ||
      isBlockedCountry(countryCode),
    [isActionsByAllowlistBlocked, isDataReady, countryCode]
  );

  return (
    <Styles.UserLoanStatContainer>
      <Styles.UserStatBox>
        <Styles.UserStatBoxItem>
          <Styles.UserStatBoxValueWrapper>
            <Styles.BoxHeader color="purpleAlpha">
              <Styles.BoxHeaderSquare color="purpleAlpha"/>
              <span>Supply</span>
            </Styles.BoxHeader>
            {selectedVault && !isLoading ? (
              <>
                <Styles.UserStatBoxValue>
                  <Styles.UserStatBoxTokenAmount data-testid="supply-token-balance">
                    <TokenBalance
                      displayType={TokenDisplayType.supply}
                      vaultType={selectedVault}
                      amount={collateralTokenAmount}
                    />
                  </Styles.UserStatBoxTokenAmount>
                </Styles.UserStatBoxValue>
                <Styles.UserStatBoxToken>
                  <TokenBalance
                    displayType={TokenDisplayType.borrow}
                    vaultType={selectedVault}
                    amount={collateralAmountInBorrow}
                  />
                </Styles.UserStatBoxToken>
              </>
            ) : (
              <StatLoader/>
            )}
          </Styles.UserStatBoxValueWrapper>
        </Styles.UserStatBoxItem>
        <Styles.UserStatBoxItem>
          <Styles.UserStatBoxValueWrapper>
            <Styles.BoxHeader color="yellowAlpha">
              <Styles.BoxHeaderSquare color="yellowAlpha"/>
              <span>Loan</span>
              <InfoPopover>
                <PopoverStyles.Paragraph>
                  <PopoverStyles.PopoverStrong>Loan overview</PopoverStyles.PopoverStrong>
                </PopoverStyles.Paragraph>
                <PopoverStyles.Row>
                  <PopoverStyles.RowLabel>Current Loan</PopoverStyles.RowLabel>
                  <PopoverStyles.RowValue><MoneyFormatter value={debtTokenAmount}
                                                          prefix={borrowSymbol + " "}/></PopoverStyles.RowValue>
                </PopoverStyles.Row>
                <PopoverStyles.Row>
                  <PopoverStyles.RowLabel>Pending Harvest</PopoverStyles.RowLabel>
                  <PopoverStyles.RowValue><MoneyFormatter value={projectedEarnings} prefix={`around ${borrowSymbol} `}/></PopoverStyles.RowValue>
                </PopoverStyles.Row>
                <PopoverStyles.Paragraph/>

                <PopoverStyles.Paragraph>
                  <b>Pending harvest earnings</b> are subject to change
                  and will reset if you update your position
                </PopoverStyles.Paragraph>
              </InfoPopover>
            </Styles.BoxHeader>
            {selectedVault && !isLoading ? (
              <>
                <Styles.UserStatBoxValue>
                  <Styles.UserStatBoxTokenAmount data-testid="borrow-token-balance">
                    <TokenBalance
                      displayType={TokenDisplayType.borrow}
                      vaultType={selectedVault}
                      amount={loanAmount}
                    />
                  </Styles.UserStatBoxTokenAmount>
                </Styles.UserStatBoxValue>
                <Styles.UserStatBoxToken>
                  <Styles.Projection>Pending harvest</Styles.Projection>
                  <Toggle value={includeProjected} onChange={setIncludeProjected}/>
                </Styles.UserStatBoxToken>
              </>
            ) : (
              <StatLoader/>
            )}
          </Styles.UserStatBoxValueWrapper>
        </Styles.UserStatBoxItem>
      </Styles.UserStatBox>
        <Styles.UpdateButtonsWrapper>
          <Styles.UpdateButton
            $variant={isNewUser ? 'secondary': 'primary'}
            $size="compact"
            disabled={updateCollateralButtonDisabled}
            onClick={() => openTakeLoanPopup()}
            data-testid="deposit-and-borrow-button"
          >
            Deposit & Borrow
          </Styles.UpdateButton>
            <DropDownMenu items={menuItems} button={(toggle, isOpen) => (
              <Styles.UpdateButton
                $variant="secondary"
                $size="compact"
                disabled={updateLoanButtonDisabled}
                onClick={toggle}
                data-testid="update-debt-dropdown"
              >
                Update {isOpen ? <ArrowUp /> : <ArrowDown />}
              </Styles.UpdateButton>
            )}/>
        </Styles.UpdateButtonsWrapper>
    </Styles.UserLoanStatContainer>
  );
};

const StatLoader = () => {
  const rects = [
    {
      y: 0,
      x: 0,
      rx: 3,
      ry: 3,
      width: 50,
      height: 25,
    },
    {
      y: 0,
      x: 55,
      rx: 3,
      ry: 3,
      width: 100,
      height: 25,
    },
    {
      y: 32,
      x: 0,
      rx: 3,
      ry: 3,
      width: 80,
      height: 16,
    },
  ];
  return <LoaderInline height={50} loaderSettings={rects}/>;
};

export default UserLoanStats;
