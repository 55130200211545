import {useEffect, useState} from "react";
import {useUser} from "./useAppSelector";

export const useBackgroundLoading = (isLoading: boolean) => {
  const [backgroundLoading, setBackgroundLoading] = useState(false);
  const { data: user } = useUser();
  useEffect(() => {
    if (!isLoading) {
      setBackgroundLoading(true);
    }
  }, [isLoading]);
  useEffect(() => {
    if (user?.walletConnected) {
      setBackgroundLoading(false);
    }
  }, [user?.walletConnected]);

  return !backgroundLoading && isLoading;
}
