import styled from "styled-components";
import { Text, textSizes } from "../../../../utils/styles/text";
import { GridVars, ThemeGray100, ThemePrimary } from "../../../../utils";
import { LinkButton } from "../../../../utils/styles/button";

export const TransactionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &:nth-child(2):before {
    content: " ";
    height: 8px;
    border: 1px solid ${ThemeGray100};
    border-radius: 5px;
    position: absolute;
    top: -12px;
    left: 11px;
  }
`

export const TransactionIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
export const TransactionIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 24px;
    width: 24px;
  }
`
export const TransactionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${GridVars.margin.m1};
`
export const TransactionLabel = styled(Text).attrs({ $size: 'medium' })``

export const StillProcessing = styled.a`
  color: ${ThemePrimary};
  font-size: ${textSizes.normal};
`
export const Retry = styled(LinkButton)`
  font-size: ${textSizes.normal};
`

export const TransactionIconPulsingCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(79, 139, 255, 0.5);
  border: 1px solid rgba(79, 139, 255, 1);
  animation: pulse 2s infinite;
  position: absolute;
  left: 0;

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.6);
      opacity: 0;
    }
  }
`

const TransactionSpinningCircle = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  background-color: #4F8BFF;
  z-index: 1;
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotate-right {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`
export const TransactionSpinningCircleLeft = styled(TransactionSpinningCircle)`
   position: absolute;
   left: 0;
   border-top-left-radius: 100%;
   transform-origin: bottom right;
   bottom: 12px;
   animation: rotate 2s linear infinite;
`
export const TransactionSpinningCircleRight = styled(TransactionSpinningCircle)`
  position: absolute;
  border-bottom-right-radius: 100%;
  transform-origin: top left;
  bottom: 0;
  left: 12px;
  animation: rotate-right 2s linear infinite;
`
