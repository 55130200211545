import styled from "styled-components";
import theme from "styled-theming";

import { colors, grays, ThemePrimaryText } from "./theme.styles"
import { GridVars } from "./grid.styles";
import { weights } from "./text";

const bgColor = theme.variants("theme", "$variant", {
  primary: { light: colors.blueAlpha, dark: colors.blueAlpha },
  secondary: { light: grays.gray300, dark: grays.gray300 },
  tertiary: { light: 'transparent', dark: 'transparent' },
  menu: { light: grays.gray100, dark: grays.gray500 },
  label: { light: grays.gray100, dark: grays.gray500 },
  basic: { light: 'transparent', dark: 'transparent' }
})
const hoverColor = theme.variants("theme", "$variant", {
  primary: { light: colors.blueBeta, dark: colors.blueSecondary },
  secondary: { light: grays.gray400, dark: grays.gray500 },
  tertiary: { light: grays.gray400, dark: grays.gray400 },
  menu: { light: grays.gray300, dark: grays.gray300 },
  label: { light: grays.gray300, dark: grays.gray300 },
  basic: { light: 'transparent', dark: 'transparent' }
})

const textColor = theme.variants('theme', '$variant', {
  primary: { light: 'white', dark: 'white' },
  secondary: { light: colors.blueAlpha, dark: colors.blueAlpha },
  tertiary: { light: grays.gray900, dark: grays.gray10 },
  menu: { light: colors.blueAlpha, dark: colors.blueAlpha },
  label: { light: grays.gray900, dark: grays.gray10 },
  basic: { light: ThemePrimaryText, dark: ThemePrimaryText }
})
const borderColor = theme.variants('theme', '$variant', {
  primary: { light: 'transparent', dark: 'transparent' },
  secondary: { light: 'transparent', dark: 'transparent' },
  tertiary: { light: grays.gray900, dark: grays.gray10 },
  menu: { light: 'transparent', dark: 'transparent' },
  label: { light: 'transparent', dark: 'transparent' },
  basic: { light: 'transparent', dark: 'transparent' }
})
const heights = {
  regular: '56px',
  compact: '36px',
  tiny: '16px',
  inline: 'auto'
}
const paddings = {
  regular: GridVars.padding.p3,
  compact: GridVars.padding.p2,
  tiny: GridVars.padding.p1,
  inline: `${GridVars.padding.p0} ${GridVars.padding.p1}`
}
const fontWeights = {
  regular: weights.strong,
  compact: weights.strong,
  tiny: weights.normal,
  inline: weights.light
}

export type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'menu' | 'label' | 'basic'
export type ButtonProps = {
  $variant?: ButtonVariants
  $size?: keyof typeof heights
  $fluid?: boolean
}

export const Button = styled.button<ButtonProps>`
  width: ${props => props.$fluid ?  '100%' : 'auto'};
  height: ${props => props.$size ? heights[props.$size] : heights.regular};
  font-weight: ${props => props.$size ? fontWeights[props.$size] : fontWeights.regular};
  background: ${bgColor};
  border-radius: 4px;
  border: 1px solid ${borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.25px;
  color: ${textColor};
  transition: 300ms all;
  padding: ${props => props.$size ? paddings[props.$size] : paddings.regular};

  &:hover {
    background: ${hoverColor};
  }

  &:disabled {
    background: ${props => props.$variant === 'menu' ? bgColor : grays.gray50};
    border: 1px solid transparent;
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;
  }
`

Button.defaultProps = {
  $variant: 'primary'
}


export type LinkButtonProps = {
  $variant?: ButtonVariants
}
export const LinkButton = styled.button<LinkButtonProps>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  color: ${textColor};
  margin: 0;
  padding: 0;
`

LinkButton.defaultProps = {
  $variant: 'primary'
}
