import React from "react";
import { useMemo } from "react";

import { CheckCircle } from "../../../../../assets/images/iconComponents";
import { CustomPopover } from "../../../../../components/custom-popover/custom-popover";
import * as PopoverStyles from "../../../../../components/custom-popover/styles";
import { PROJECT_NAME } from "../../../../../config";

import * as Styled from "./style";
import { UserPosition } from "../../../../../hooks/useUserPosition";

interface ButtonStateInterface {
  label: string;
  color: string;
  maxWidth: number;
  background: string;
  popoverTip: string;
  popoverContent: string;
  iconType: "green" | "purple" | "warn" | "danger";
}

interface RepayingButtonInterface {
  userLTV: number;
  targetLTV: number;
  supplyThreshold: number;
  liquidationThreshold: number;
  userPosition: UserPosition;
}

enum RepayingStates {
  earning = 'earning',
  smartRepayment = 'smartRepayment',
  reducingInterest = 'reducingInterest',
  notEarning = 'notEarning',
  liquidation = 'liquidation',
}

const buttonStates: Record<string, ButtonStateInterface> = {
  [RepayingStates.earning]: {
    label: "Smart Earning",
    iconType: "green",
    color: "#00D83C",
    background: "rgba(0, 216, 60, 0.1)",
    popoverContent: `${PROJECT_NAME} is generating yield for you`,
    popoverTip: "",
    maxWidth: 160,
  },
  [RepayingStates.smartRepayment]: {
    label: "Smart Repaying",
    iconType: "green",
    color: "#00D83C",
    background: "rgba(0, 216, 60, 0.1)",
    popoverContent: `Your loan is automatically being repaid by ${PROJECT_NAME}`,
    popoverTip: "",
    maxWidth: 160,
  },
  [RepayingStates.reducingInterest]: {
    label: "Reducing Interest",
    iconType: "purple",
    color: "#E8C2F8",
    background: "rgba(232, 194, 248, 0.1)",
    popoverContent: `${PROJECT_NAME} is reducing your borrow interest - lower your LTV to get even more out of ${PROJECT_NAME}`,
    popoverTip: "",
    maxWidth: 160,
  },
  [RepayingStates.notEarning]: {
    label: "Neutral",
    iconType: "warn",
    color: "#D8A800",
    background: "rgba(216, 168, 0, 0.1);",
    popoverContent: `Your LTV is high and ${PROJECT_NAME} is unable to generate yield - reduce your LTV to allow ${PROJECT_NAME} to work for you`,
    popoverTip: "",
    maxWidth: 160,
  },
  [RepayingStates.liquidation]: {
    label: "Liquidatable",
    iconType: "danger",
    color: "#D85830",
    background: "rgba(216, 88, 48, 0.1);",
    popoverContent: `Your LTV is too high and your position may be liquidated - reduce your LTV to allow ${PROJECT_NAME} to work for you`,
    popoverTip: "",
    maxWidth: 160,
  },
};

const repayingButtonConditions = (
  userPosition: UserPosition,
  userLTV: number,
  targetLTV: number,
  // supplyThreshold: number,
  liquidationThreshold: number
) => {

  // Catch missing data
  if (!userPosition || userPosition.loan.loanApy === undefined || !liquidationThreshold || !targetLTV || userLTV === undefined) {
    return "";
  }

  // User subject to liquidation
  if (userLTV > liquidationThreshold) {
    return RepayingStates.liquidation;
  }
  // User not actively contributing
  if (userLTV > targetLTV) {
    return RepayingStates.notEarning;
  }
  // loanAPY is positive
  if (userPosition && userPosition.loan.loanApy < 0 && userLTV  > 0) {
    return RepayingStates.smartRepayment;
  }
  // loanAPY is positive and user has no borrows
  if (userPosition && userPosition.loan.loanApy === 0 && userLTV === 0) {
    return RepayingStates.earning;
  }
  // loanAPY is positive and user has a loan
  if (userPosition && userPosition.loan.loanApy >= 0 && userLTV > 0) {
    return RepayingStates.reducingInterest;
  }

  return "";
};

export const RepayingButton = ({
  userPosition,
  userLTV,
  targetLTV,
  liquidationThreshold,
}: RepayingButtonInterface) => {
  const buttonState = useMemo(
    () =>
      buttonStates[
        repayingButtonConditions(
          userPosition,
          userLTV,
          targetLTV,
          liquidationThreshold
        )
      ],
    [userPosition, userLTV, targetLTV, liquidationThreshold]
  );

  if (!buttonState) {
    return <></>;
  }

  return (
    <CustomPopover
      marginTop={"0"}
      positions={["bottom"]}
      button={
        <Styled.PillButton
          $maxWidth={buttonState.maxWidth}
          $background={buttonState.background}
        >
          <CheckCircle type={buttonState.iconType} />
          <Styled.PillButtonText $color={buttonState.color}>
            {buttonState.label}
          </Styled.PillButtonText>
        </Styled.PillButton>
      }
    >
      {/* <PopoverStyles.Header>{buttonState.label}</PopoverStyles.Header> */}
      <PopoverStyles.Paragraph>
        {buttonState.popoverContent}
      </PopoverStyles.Paragraph>
      {buttonState.popoverTip && (
        <PopoverStyles.Paragraph>
          Tip: {buttonState.popoverTip}
        </PopoverStyles.Paragraph>
      )}
    </CustomPopover>
  );
};
