import { AnalyticsInterface } from "../analytics";

const GTM_CONTAINER_ID = process.env.REACT_APP_GTM_CONTAINER_ID; // 'GTM-XXX'

function scriptLoaded(containerId: string) {
  const scripts = document.querySelectorAll('script[src]')
  return !!Object.values(scripts).filter((script: any) => (script?.src || '').match(new RegExp(containerId, 'i'))).length
}

export class GtmAnalytics implements AnalyticsInterface {
  constructor() {
    if (GTM_CONTAINER_ID && !scriptLoaded(GTM_CONTAINER_ID)) {
      /* eslint-disable */
      (function(w,d,s,l,i){ // @ts-ignore
        w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:''; // @ts-ignore
        j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl; // @ts-ignore
        f.parentNode.insertBefore(j,f);
      })(window, document,'script','dataLayer',GTM_CONTAINER_ID);
      /* eslint-enable */
    }
  }
  identify(id: string, data: object = {}) {}
  track(eventId: string, data: object = {}) {}
  page(data: object = {}) {}
}
