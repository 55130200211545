type CheckCircleProps = {
  type: "green" | "purple" | "warn" | "danger";
}

const CheckCircle = ({ type }: CheckCircleProps) => {
  switch (type) {
    case "danger":
      return <Danger />;
    case "warn":
      return <Warn />;
    case "purple":
      return <Purple />;
    case "green":
      return <Green />;
  }
}

export default CheckCircle;

const Green = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.08333 8.21667L4.65 6.76667C4.52778 6.65556 4.38333 6.6 4.21667 6.6C4.05 6.6 3.90556 6.66111 3.78333 6.78333C3.66111 6.90556 3.6 7.05267 3.6 7.22467C3.6 7.39711 3.66111 7.54444 3.78333 7.66667L5.65 9.55C5.77222 9.66111 5.91933 9.71667 6.09133 9.71667C6.26378 9.71667 6.40556 9.66111 6.51667 9.55L10.25 5.81667C10.3611 5.70556 10.4167 5.56667 10.4167 5.4C10.4167 5.23333 10.3556 5.08333 10.2333 4.95C10.1111 4.82778 9.964 4.76667 9.792 4.76667C9.61956 4.76667 9.47222 4.82778 9.35 4.95L6.08333 8.21667ZM7 13.5C6.1 13.5 5.25556 13.3307 4.46667 12.992C3.67778 12.6529 2.98889 12.1889 2.4 11.6C1.81111 11.0111 1.34733 10.3222 1.00867 9.53333C0.669556 8.74444 0.5 7.9 0.5 7C0.5 6.08889 0.669556 5.23889 1.00867 4.45C1.34733 3.66111 1.81111 2.97489 2.4 2.39133C2.98889 1.80822 3.67778 1.34711 4.46667 1.008C5.25556 0.669333 6.1 0.5 7 0.5C7.91111 0.5 8.76111 0.669333 9.55 1.008C10.3389 1.34711 11.0251 1.80822 11.6087 2.39133C12.1918 2.97489 12.6529 3.66111 12.992 4.45C13.3307 5.23889 13.5 6.08889 13.5 7C13.5 7.9 13.3307 8.74444 12.992 9.53333C12.6529 10.3222 12.1918 11.0111 11.6087 11.6C11.0251 12.1889 10.3389 12.6529 9.55 12.992C8.76111 13.3307 7.91111 13.5 7 13.5Z"
          fill="#00D83C"/>
  </svg>
)

const Purple = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.08333 8.21667L4.65 6.76667C4.52778 6.65556 4.38333 6.6 4.21667 6.6C4.05 6.6 3.90556 6.66111 3.78333 6.78333C3.66111 6.90556 3.6 7.05267 3.6 7.22467C3.6 7.39711 3.66111 7.54444 3.78333 7.66667L5.65 9.55C5.77222 9.66111 5.91933 9.71667 6.09133 9.71667C6.26378 9.71667 6.40556 9.66111 6.51667 9.55L10.25 5.81667C10.3611 5.70556 10.4167 5.56667 10.4167 5.4C10.4167 5.23333 10.3556 5.08333 10.2333 4.95C10.1111 4.82778 9.964 4.76667 9.792 4.76667C9.61956 4.76667 9.47222 4.82778 9.35 4.95L6.08333 8.21667ZM7 13.5C6.1 13.5 5.25556 13.3307 4.46667 12.992C3.67778 12.6529 2.98889 12.1889 2.4 11.6C1.81111 11.0111 1.34733 10.3222 1.00867 9.53333C0.669556 8.74444 0.5 7.9 0.5 7C0.5 6.08889 0.669556 5.23889 1.00867 4.45C1.34733 3.66111 1.81111 2.97489 2.4 2.39133C2.98889 1.80822 3.67778 1.34711 4.46667 1.008C5.25556 0.669333 6.1 0.5 7 0.5C7.91111 0.5 8.76111 0.669333 9.55 1.008C10.3389 1.34711 11.0251 1.80822 11.6087 2.39133C12.1918 2.97489 12.6529 3.66111 12.992 4.45C13.3307 5.23889 13.5 6.08889 13.5 7C13.5 7.9 13.3307 8.74444 12.992 9.53333C12.6529 10.3222 12.1918 11.0111 11.6087 11.6C11.0251 12.1889 10.3389 12.6529 9.55 12.992C8.76111 13.3307 7.91111 13.5 7 13.5Z"
          fill="#E9C2F7"/>
  </svg>
)

const Danger = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0C3.13403 0 0 3.134 0 7C0 10.866 3.13403 14 7 14ZM6.28015 11.6941C6.46863 11.8896 6.72852 12 7 12C7.27148 12 7.53137 11.8896 7.71985 11.6941L10.7198 8.58302C11.1031 8.18547 11.0917 7.5524 10.6941 7.16905C10.2966 6.78569 9.66357 6.7972 9.28015 7.19475L8 8.52232V4C8 3.44771 7.55225 3 7 3C6.44775 3 6 3.44771 6 4V8.52232L4.71985 7.19475C4.33643 6.7972 3.70337 6.78569 3.30591 7.16904C2.90833 7.5524 2.89685 8.18547 3.28015 8.58302L6.28015 11.6941Z"
          fill="#D85830"/>
  </svg>
)

const Warn = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0C3.13403 0 0 3.134 0 7C0 10.866 3.13403 14 7 14ZM11.1942 8.21985C11.3896 8.03137 11.5 7.77151 11.5 7.5C11.5 7.22849 11.3896 6.96863 11.1942 6.78015L8.08301 3.78015C7.68549 3.39679 7.05243 3.40831 6.66907 3.80586C6.28571 4.20343 6.29718 4.83649 6.69476 5.21985L8.02234 6.5H3.5C2.94769 6.5 2.5 6.94771 2.5 7.5C2.5 8.05229 2.94769 8.5 3.5 8.5H8.02234L6.69476 9.78015C6.29718 10.1635 6.28571 10.7966 6.66907 11.1941C7.05243 11.5917 7.68549 11.6032 8.08301 11.2198L11.1942 8.21985Z"
          fill="#D8A800"/>
  </svg>
)
