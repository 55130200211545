import Intercom, { trackEvent } from "@intercom/messenger-js-sdk";
import { AnalyticsInterface } from "../analytics";

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID; // 'fk3lhm0c'

export class IntercomAnalytics implements AnalyticsInterface {
  constructor() {
    if (INTERCOM_APP_ID && !window.Intercom) {
      Intercom({
        app_id: INTERCOM_APP_ID,
      });
    }
  }
  identify(id: string, data: object = {}) {
    window.Intercom && window.Intercom('update', {
      wallet_address: id,
      ...data,
      name: ''
    });
  }
  track(eventId: string, data: object = {}) {
    window.Intercom && trackEvent(eventId, data);
  }
  page(data: object = {}) {
    window.Intercom && window.Intercom('update');
  }
}
