import { ReactComponent as TrendUp } from "../icons/trendUp.svg";
import { ReactComponent as TrendDown } from "../icons/trendDown.svg";

type TrendProps = {
  type: 'up' | 'down'
  width?: number
  height?: number
}
const Trend = ({ type, ...rest }: TrendProps) => (
  type === 'up' ? <TrendUp {...rest} /> : <TrendDown {...rest} />
);
export default Trend;
