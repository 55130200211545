import useSWR from "swr";
import { ApyData } from "@altitude-fi/altitude-v1-sdk/dist/types";

export const baseUrl = 'https://api.altitude.fi'

type FetcherKey = [string, Record<string, string>?]

const fetcher = <Data, Key extends FetcherKey>(input: Key): Promise<Data> => {
  const [path, params] = input;
  const searchParams = new URLSearchParams(params);
  const query = searchParams.toString();
  const url = `${baseUrl}${path}${query ? `?${searchParams.toString()}` : ''}`;
  return fetch(url).then(res => res.json())
}
export const useApyData = () => useSWR<{ data: ApyData, datetime: string }, any, FetcherKey>(['/apyData'], fetcher)
export const useUserIncentives = (userAddress?: string) => useSWR<{ data: number }, any, FetcherKey | null>(userAddress ? ['/incentivesData', { userAddress }] : null, fetcher)
