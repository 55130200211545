import styled from "styled-components";
import { Box, GridVars } from "../../utils";
import { Button } from "../../utils/styles/button";
import { TextHeader, Text } from "../../utils/styles/text";

export const RewardsContainer = styled(Box)`
  padding-top: ${GridVars.padding.p5};
  background: transparent;
`;

export const RewardHeader = styled(TextHeader).attrs({ $size: 'larger' })`
  text-align: center;
`;

export const RewardsLine = styled.div`
  margin-top: ${GridVars.margin.m5};
  hr {
    margin-bottom: ${GridVars.margin.m1}
  }
`;

export const TotalBalance = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${GridVars.margin.m3};
`;

export const ClaimRewardsBtn = styled(Button).attrs({
  $fluid: true
})``;
