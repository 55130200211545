import * as Styles from "../style";
import { Trend } from "../../../../../assets/images/iconComponents";
import * as PopoverStyles from "../../../../../components/custom-popover/styles";
import { InventivesNetApy } from "./style";
import PercentageFormatter from "../../../../../components/percentage-formatter";
import { InfoPopover } from "../../../../../components";
import { useUserPosition } from "../../../../../hooks/useUserPosition";
import { useVault } from "../../../../../hooks/useAppSelector";
import TokenBalance from "../../../../../components/token-balance";
import { TokenDisplayType } from "../../../../../models/vault";
import { useCallback } from "react";
import { ElementMarginMedium, ElementInlineIconLeft } from "../../../../../utils";

export const LoanInfoPopover = () => {
  const { data: position } = useUserPosition();
  const { data: vault } = useVault();
  const renderAmountValue = useCallback((amount: number | undefined) => (
    <PopoverStyles.RowValue>
      {amount !== undefined ? (
        <>
          <span>~&nbsp;</span>
          <TokenBalance
            amount={amount}
            vaultType={vault?.id || ''}
            displayType={TokenDisplayType.borrow}
          />
          <span>&nbsp;per day</span>
        </>
      ) : 'N/A'}
    </PopoverStyles.RowValue>
  ), [vault]);

  return (
    <InfoPopover>
      <Styles.PopoverContent>
        <PopoverStyles.Header>Loan APY</PopoverStyles.Header>
        <PopoverStyles.Paragraph>Your Loan APY shows the effect of Altitude's earnings on your loan.</PopoverStyles.Paragraph>
        <ElementMarginMedium />
        <PopoverStyles.Row>
          <PopoverStyles.RowLabel>
            <ElementInlineIconLeft>
              <Trend type="down" height={18} width={18} />
            </ElementInlineIconLeft>
            <span>Borrow Cost</span>
          </PopoverStyles.RowLabel>
          {renderAmountValue(position?.loan.dailyBorrowCost)}
        </PopoverStyles.Row>
        <Styles.RowHr />
        <PopoverStyles.Row>
          <PopoverStyles.RowLabel>
            <ElementInlineIconLeft>
              <Trend type="up" height={18} width={18} />
            </ElementInlineIconLeft>
            <span>Altitude Earnings</span>
          </PopoverStyles.RowLabel>
          {renderAmountValue(position?.projected.dailyEarnings)}
        </PopoverStyles.Row>
        <Styles.RowHr />
        <PopoverStyles.Row>
          <PopoverStyles.RowLabel>
            <InventivesNetApy>Loan APY</InventivesNetApy>
          </PopoverStyles.RowLabel>
          <PopoverStyles.RowValue>
            <InventivesNetApy><PercentageFormatter percentage={position?.loan.loanApy || 0} fractionDigits={2} /><span>%</span></InventivesNetApy>
          </PopoverStyles.RowValue>
        </PopoverStyles.Row>
      </Styles.PopoverContent>
    </InfoPopover>
  )
}
