import useSWR from "swr";
import { useBlockNumber, usePublicClient, useReadContract } from "wagmi";
import { parseAbiItem } from "viem";
import lidoLocatorAbi from './locatorAbi.json';
import { useMemo } from "react";
import useLocalStorage from "../useLocalStorage";

const CONTRACT_LOCATOR_ADDRESS = '0xC1d0b3DE6792Bf6b4b37EccdcC24e45978Cfd2Eb';
const DEFAULT_STEP_BLOCK = BigInt(7600); // 2 days worth of blocks

const LIDO_APR_ENABLED_KEY = 'enable-lido-apr'
const useIncludeLidoApr = () => useLocalStorage<boolean>(LIDO_APR_ENABLED_KEY, true);

const useLido = () => {
  const { data: lidoAddress } = useReadContract({
    abi: lidoLocatorAbi,
    address: CONTRACT_LOCATOR_ADDRESS,
    functionName: 'lido'
  })
  const publicClient = usePublicClient();
  const { data: blockNumber } = useBlockNumber({ cacheTime: 60_000 });

  const shouldFetch = useMemo(() => lidoAddress && blockNumber && publicClient, [lidoAddress, blockNumber, publicClient]);
  const { data: tokenRebasedEvents, isLoading } = useSWR(shouldFetch ? ['LidoEvents', lidoAddress, blockNumber] : null, async ([, lidoAddress, blockNumber]) => {
    if (!publicClient || !blockNumber) {
      return null;
    }
    return await publicClient.getLogs({
      address: lidoAddress as `0x${string}`,
      event: parseAbiItem('event TokenRebased(uint256 indexed reportTimestamp, uint256 timeElapsed, uint256 preTotalShares, uint256 preTotalEther, uint256 postTotalShares, uint256 postTotalEther, uint256 sharesMintedAsFees)'),
      fromBlock: blockNumber - DEFAULT_STEP_BLOCK,
      toBlock: blockNumber
    })
  }, {
    keepPreviousData: true
  });

  const lastTokenEvent = useMemo(() => tokenRebasedEvents && tokenRebasedEvents[0], [tokenRebasedEvents]);

  const lidoApr = useMemo(() => {
    if (!lastTokenEvent) {
      return 0;
    }

    const {
      preTotalEther = BigInt(0),
      preTotalShares = BigInt(0),
      postTotalEther = BigInt(0),
      postTotalShares = BigInt(0),
      timeElapsed = BigInt(0)
    } = lastTokenEvent.args;
    const preShareRate = (preTotalEther * BigInt(10 ** 27)) / preTotalShares;
    const postShareRate = (postTotalEther * BigInt(10 ** 27)) / postTotalShares;
    const mulForPrecision = 1000000;

    const secondsInYear = BigInt(31536000);
    const userAPR =
      (secondsInYear *
        ((postShareRate - preShareRate) * BigInt(mulForPrecision))) /
      preShareRate /
      timeElapsed;

    return Number(userAPR) / mulForPrecision;
  }, [lastTokenEvent]);

  return {
    lidoApr,
    isLoading
  };
}

export {
  useLido,
  useIncludeLidoApr
};
