import { ModalProps } from "../../../../modules/dashboard/modals/interfaces";

import { DepositTab } from "./tabs/deposit.tab";
import { WithdrawTab } from "./tabs/withdraw.tab";
import TabsModal from "../../../../components/tabs-modal";
import {useUserVaultInfo} from "../../../../hooks/useAppSelector";
import {useMemo} from "react";

export const Tabs: Record<string, string> = {
  deposit: "Deposit",
  withdraw: "Withdraw",
};

const TabsMap = {
  [Tabs.deposit]: <DepositTab />,
  [Tabs.withdraw]: <WithdrawTab />,
}

export const DepositWithdrawModal = ({ isOpen, onClose, defaultTab }: ModalProps) => {
  const { data: { collateralTokenAmountBn } = {} } = useUserVaultInfo();
  const tabsWithState = useMemo(() =>
    collateralTokenAmountBn && collateralTokenAmountBn.gt(0)
      ? Tabs
      : { ...Tabs, withdraw: { name: Tabs.withdraw, disabled: true } },
    [collateralTokenAmountBn]
  )

  return (
    <TabsModal
      onClose={onClose}
      show={isOpen}
      defaultTab={defaultTab || Tabs.deposit}
      tabs={tabsWithState}
      testId="deposit-withdraw-modal"
    >
      {(activeTab) => TabsMap[activeTab]}
    </TabsModal>
  );
};
