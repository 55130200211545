import styled from "styled-components";
import { TextHeader, TextParagraph, Text, textSizes } from "../../../utils/styles/text";
import {
  Box,
  ElementHR,
  GridVars,
  ThemeGray50,
  ThemePrimary,
  ThemePrimaryText,
  ThemeSecondaryText
} from "../../../utils";

export const TransactionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${GridVars.padding.p5} ${GridVars.padding.p3} ${GridVars.padding.p4} ${GridVars.padding.p3};
  min-height: 400px;
`;

export const TransactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GridVars.gap};
  padding-top: ${GridVars.padding.p3};
`

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GridVars.gap};
  padding-top: ${GridVars.padding.p3};
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${GridVars.margin.m1};
`
export const ActionIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
      height: 36px;
      width: 36px;
  }
`

export const ActionDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`
export const ActionLabel = styled(Text).attrs({ $size: 'medium', $weight: 'strong' })``
export const ActionValue = styled(Text)`
  span {
    color: ${ThemePrimaryText};
  }
`

export const TransactionHeader = styled(TextHeader).attrs({ $size: 'larger' })`
  font-size: 1.6rem;
  text-align: center;
`;

export const TransactionBody = styled.div``;

export const TransactionFooter = styled.div``;


export const TransactionDesc = styled(TextParagraph)`
  text-align: center;
`;

export const IconContainer = styled(Text)`
  margin-top: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${GridVars.padding.p1};
`
export const ErrorTitle = styled(Text)`
  margin-bottom: ${GridVars.margin.m1};
  color: ${ThemePrimaryText};
`

export const ShowMore = styled.button`
  display: flex;
  color: ${ThemeSecondaryText};
  text-decoration: dashed underline;
  background: none;
  border: none;
`

export const ErrorDetails = styled(Text)`
  display: block;
  font-family: monospace;
  width: 100%;
  margin: ${GridVars.margin.m2} 0 ${GridVars.margin.m2} 0;
  white-space: pre-line;
  background: ${ThemeGray50};
  padding: ${GridVars.padding.p1};
  border-radius: 6px;
`;

export const Divider = styled(ElementHR)``

export const ConfirmedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${GridVars.gap};
  a {
    color: ${ThemePrimary};
    font-size: ${textSizes.normal};
  }
`
