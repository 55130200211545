import { SVGProps } from "react";

const SvgLogoMorpho = (props: SVGProps<SVGSVGElement>) => (
  <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1122_283)">
      <path
        d="M150 300C232.843 300 300 232.843 300 150C300 67.1573 232.843 0 150 0C67.1573 0 0 67.1573 0 150C0 232.843 67.1573 300 150 300Z"
        fill="#2470FF"/>
      <path opacity="0.8"
            d="M87.4323 177.299V221.974C87.4323 224.725 89.7598 225.866 90.4839 226.125C91.208 226.437 93.639 227.111 95.8113 225.088L129.608 192.609C132.486 189.843 135.264 186.936 137.352 183.535C138.334 181.936 138.741 181.035 138.741 181.035C140.811 176.832 140.811 172.785 138.793 168.737C135.794 162.718 128.138 156.595 116.604 150.784L96.8975 161.784C91.053 165.105 87.4323 170.969 87.4323 177.299Z"
            fill="white"/>
      <path
        d="M68 77.9327V124.788C68 130.651 71.931 135.84 77.5172 137.5C96.5511 143 129.705 154.831 137.723 172.213C138.756 174.496 139.377 176.728 139.585 179.062C144.913 169.359 147.343 158.203 146.206 146.892C144.653 130.858 136.17 116.278 122.93 106.99L75.7068 73.9373C74.8791 73.3146 73.8964 73.0033 72.9137 73.0033C72.0861 73.0033 71.362 73.159 70.5862 73.5741C69.0344 74.4562 68 76.0647 68 77.9327Z"
        fill="white"/>
      <path opacity="0.8"
            d="M213.136 177.299V221.974C213.136 224.725 210.809 225.866 210.084 226.125C209.361 226.437 206.928 227.111 204.757 225.088L170.173 191.854C167.819 189.591 165.571 187.198 163.798 184.456C162.393 182.285 161.827 181.035 161.827 181.035C159.758 176.832 159.758 172.785 161.774 168.737C164.775 162.718 172.43 156.595 183.963 150.784L203.67 161.784C209.567 165.105 213.136 170.969 213.136 177.299Z"
            fill="white"/>
      <path
        d="M232.6 77.9294V124.784C232.6 130.648 228.668 135.836 223.082 137.497C204.048 142.997 170.895 154.828 162.877 172.21C161.842 174.493 161.221 176.724 161.015 179.059C155.687 169.356 153.257 158.2 154.394 146.889C155.945 130.855 164.428 116.275 177.67 106.987L224.893 73.934C225.721 73.3113 226.703 73 227.686 73C228.513 73 229.238 73.1557 230.012 73.5708C231.565 74.4529 232.6 76.0614 232.6 77.9294Z"
        fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1122_283">
        <rect width="300" height="300" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);
export default SvgLogoMorpho;
