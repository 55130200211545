import { CloseIcon } from "../../assets/images/iconComponents";
import * as Styles from "../../utils/styles/modal.styles";
import { createRef, useCallback, useEffect, useState } from "react";
import { useOutside } from "../../hooks";
import { AnimationDurationMs } from "../../utils";

export interface CustomModalProps {
  show: boolean;
  onClose?: () => void;
  preventOutsideClick?: boolean;
  children: React.ReactNode;
  testId?: string;
  heading?: string;
}

const CustomModal = (props: CustomModalProps) => {
  const { show, onClose, testId, preventOutsideClick } = props;

  const ref = createRef<HTMLDivElement>();
  const close = useCallback(() => onClose && onClose(), [onClose]);
  const isClosable = !!onClose;

  const [shouldRender, setShouldRender] = useState(show);

  useOutside(ref, preventOutsideClick ? () => {} : close);

  // Wait for the animation to complete before mounting/unmounting
  useEffect(() => {
    if (show) {
      setShouldRender(true);
    } else {
      const timeoutId = setTimeout(() => setShouldRender(false), AnimationDurationMs.short);
      return () => clearTimeout(timeoutId);
    }
  }, [show]);

  if (!shouldRender) {
    return null;
  }

  return (
    <Styles.ModalBackground
      data-testid={testId}
      $show={show}
    >
      <Styles.ModalWrapper>
        {isClosable && (
          <Styles.ModalCloseBtn
            data-testid="closeButton"
            onClick={close}
          >
            <span>Close</span>
            <CloseIcon />
          </Styles.ModalCloseBtn>
        )}
        <div ref={ref}>
          {props.children}
        </div>
      </Styles.ModalWrapper>
    </Styles.ModalBackground>
  );
};
export default CustomModal;
