import { InfoPopover, LoaderInline } from "../../../../components";
import * as PopoverStyles from "../../../../components/custom-popover/styles";
import { BaseStrategyInterface, StrategyType } from "../../../../models/vault";
import { Icon, iconToSvg } from "../../../../utils/icons.mapping";

import * as Styles from "./style";
import { Trend } from "../../../../assets/images/iconComponents";
import CustomPopover from "../../../../components/custom-popover";
import { PROJECT_NAME } from "../../../../config";
import { useBackgroundLoading } from "../../../../hooks/useBackgroundLoading";
import { useVault } from "../../../../hooks/useAppSelector";

const VaultComposition = () => {
  const { data: { lenderStrategyInfo, farmStrategyInfo } = {}, isLoading: isVaultLoading } = useVault();
  const isLoading = useBackgroundLoading(isVaultLoading);

  const renderStrategy = (strategy: BaseStrategyInterface) => {
    const strategyInfos = {
      [StrategyType.lender]: {
        header: "Financing debt from",
        text1: `${PROJECT_NAME} is financing your debt from ${strategy.name}.`,
        text2: `We may change where we finance debt. `,
        icon: <Trend type="down" />,
        apyText: 'Debt APY'
      },
      [StrategyType.farm]: {
        header: "Generating yield from",
        text1: `${PROJECT_NAME} is generating yield from  ${strategy.name}.`,
        text2: `We may change where we generate yield. `,
        icon: <Trend type="up" />,
        apyText: 'Yield APY' },
    }
    const strategyInfo = strategyInfos[strategy.type];

    return (
      <Styles.VaultCompositionItemCol>
        <Styles.VaultCompositionItemRow>
          {iconToSvg(strategy.icon as Icon, { width: "40px", height: "40px" })}
          <div>
            <Styles.VaultCompositionHeader>
              {strategyInfo.header}
            </Styles.VaultCompositionHeader>
            <Styles.VaultCompositionSubHeader>
              {strategy.name}
            </Styles.VaultCompositionSubHeader>
            <CustomPopover marginLeft="-13px" marginTop="0px" button={
              <Styles.VaultCompositionRisks $size="inline">
                risks
              </Styles.VaultCompositionRisks>
            }>
              <Styles.PopoverContent>
                {strategyInfo.text1}
                <p>
                  You can find details regarding risks here:
                  <ul>
                    {strategy.risksUrls.map(link => (
                      <li key={link.url}>
                        <text><a href={link.url} target="_blank" rel="noreferrer noopener">{link.text}</a></text>
                      </li>
                    ))}
                  </ul>
                </p>
                {strategyInfo.text2}
                Please keep an eye on <a href="https://snapshot.org/#/allocation-wsteth-usdc.altitudefi.eth" target="_blank" rel="noreferrer noopener">allocation proposals</a> to be aware
                of any changes.
              </Styles.PopoverContent>
            </CustomPopover>
          </div>
        </Styles.VaultCompositionItemRow>
      </Styles.VaultCompositionItemCol>
    );
  };

  return (
    <Styles.VaultCompositionContainer>
      <Styles.VaultCompositionItemCol>
        <Styles.VaultCompositionItemRow>
          <Styles.VaultCompositionText>
            Vault Composition
          </Styles.VaultCompositionText>
          <InfoPopover>
            <PopoverStyles.Paragraph>
              This section displays the current lending and yield strategies that
              the vault is utilizing to borrow and generate yield.
            </PopoverStyles.Paragraph>
            <PopoverStyles.Paragraph>
              Please note that, through our yield allocation process, these strategies are
              subject to change at any time.
            </PopoverStyles.Paragraph>
          </InfoPopover>
        </Styles.VaultCompositionItemRow>
      </Styles.VaultCompositionItemCol>
      <Styles.VaultCompositionItemCol>
        <Styles.VaultCompositionItemRowStrategies>
          {lenderStrategyInfo && farmStrategyInfo && !isLoading ? (
            <>
              {renderStrategy(lenderStrategyInfo)}
              {renderStrategy(farmStrategyInfo)}
            </>
          ): ( <><VaultCompositionLoader /><VaultCompositionLoader /></> )}
        </Styles.VaultCompositionItemRowStrategies>
      </Styles.VaultCompositionItemCol>
    </Styles.VaultCompositionContainer>
  );
};


const VaultCompositionLoader = () => {
  const rects = [
    {
      y: 0,
      x: 0,
      rx: 20,
      ry: 20,
      width: 40,
      height: 40,
    },
    {
      y: 0,
      x: 50,
      rx: 3,
      ry: 3,
      width: 150,
      height: 40,
    }
  ];
  return <LoaderInline height={60} width={200} loaderSettings={rects} />;
};


export default VaultComposition;
