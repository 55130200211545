import {
  GridVars,
  ThemeStatus100,
  ThemeStatus200,
  ThemeStatus300,
  ThemeStatus400,
  ThemeStatus500,
} from "../../../../../utils";
import {useMemo, useState} from "react";
import {useMediaQuery} from "../../../../../hooks";

const BASE_SCALE = 100;

type ChartInfoInput = {
  borrowLimit: number
  liquidation: number
  balance: number
  supplyThreshold: number
  liquidationThreshold: number
  collateral: number
  borrowToUsdRatio: number
}
export const useChartInfo = ({
  borrowLimit,
  liquidation,
  balance,
  supplyThreshold,
  liquidationThreshold,
  collateral,
  borrowToUsdRatio,
}: ChartInfoInput) => {
  const [borrowLimitPercentage, liquidationPercentage, overallPercentage] =
    useMemo(
      () => getChartValues(borrowLimit, liquidation, balance),
      [borrowLimit, liquidation, balance]
    );
  const [borrowingLimit] = useState<number>(supplyThreshold * 100);
  const [liquidationLimit] = useState<number>(liquidationThreshold * 100);

  const liquidationAmount = getLiquidationAmount(balance, liquidationThreshold, collateral) * borrowToUsdRatio;
  const [borrowColor, liquidationColor] = useMemo(
    () => getChartColor(overallPercentage),
    [overallPercentage]
  );

  const isSM = useMediaQuery(`(max-width: ${GridVars.breakpoint.sm})`);

  // DEV: If supplyThreshold is above 75% - 80% this part of the chart will need redesign
  const supplyThresholdBreakpoint = 0.7;
  const isUnderChart = isSM || supplyThreshold < supplyThresholdBreakpoint;

  return {
    isUnderChart,
    borrowColor,
    liquidationAmount,
    borrowingLimit,
    borrowLimitPercentage,
    liquidationColor,
    liquidationLimit,
    liquidationPercentage,
  }
}

export const getChartValues = (
  borrowLimit: number,
  liquidation: number,
  balance: number
) => {
  if (!balance || !liquidation || balance <= 0 || borrowLimit <= 0) {
    return [0, 0, 0];
  }

  const overallPercentage = (balance / liquidation) * BASE_SCALE;

  const borrowingLimitLiquidationRatio =
    (borrowLimit / liquidation) * BASE_SCALE;
  const liquidationPart = BASE_SCALE - borrowingLimitLiquidationRatio;

  const borrowLimitPercentage =
    (overallPercentage / borrowingLimitLiquidationRatio) * BASE_SCALE;
  const liquidationPercentage =
    ((overallPercentage - borrowingLimitLiquidationRatio) / liquidationPart) *
    BASE_SCALE;

  return [borrowLimitPercentage, liquidationPercentage, overallPercentage];
};

const colors: Record<string | number, string> = {
  50: ThemeStatus100,
  65: ThemeStatus200,
  75: ThemeStatus300,
  85: ThemeStatus400,
  95: ThemeStatus500,
  default: ThemeStatus500,
};

export const getChartColor = (overallPercentage: number): [string, string] => {
  for (const key in colors) {
    if (overallPercentage <= Number(key)) {
      return [colors[key], colors[key]];
    }
  }
  return [colors.default, colors.default];
};

export const getLiquidationAmount = (
  loan: number,
  liquidationThreshold: number,
  collateralInETH: number
): number => {
  if (collateralInETH === 0 || liquidationThreshold === 0) {
    return 0;
  }
  return loan / (liquidationThreshold * collateralInETH)
};
