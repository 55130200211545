import styled from "styled-components";
import {
  GridVars,
  ModalContent,
  FormInputBox,
  FormInputText,
} from "../../../../utils";
import { Button } from "../../../../utils/styles/button";
import {
  TextHeader,
  Text,
  TextLink,
} from "../../../../utils/styles/text";

export const SelectedContainer = styled(ModalContent)`
  padding: ${GridVars.padding.p5} ${GridVars.padding.p4} ${GridVars.padding.p4}
    ${GridVars.padding.p4};
`;

export const SelectedHeader = styled(TextHeader).attrs({ $size: 'larger' })`
  text-align: center;
  margin-bottom: ${GridVars.margin.m4};
`;

export const WalletName = styled(Text).attrs({ $weight: 'strong' })``;

export const AddressContainer = styled(FormInputBox)`
  margin-top: ${GridVars.margin.m1};

  img {
    width: 27px;
    flex-shrink: 0;
    display: inline-block;
  }
`;

export const UserAddress = styled(FormInputText)``;

export const AddressActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${GridVars.margin.m2};
  font-size: 0.8rem;
`;

export const AddressActionItem = styled(TextLink)`
  display: flex;

  cursor: pointer;
`;

export const DisconnectBtn = styled(Button).attrs({
  $fluid: true
})`
  margin-top: ${GridVars.margin.m4};
`;
