import styled from "styled-components";
import {
  ThemeGray500,
  GridVars,
  ElementHR,
} from "../../utils";
import {
  TextHeader,
  TextLabel,
  TextParagraph,
  TextValue,
} from "../../utils/styles/text";
import { AnimationDuration, AnimationFadeIn, AnimationFadeOut } from "../../utils";

interface PopoverContainerProps {
  readonly $show: boolean;
}

export const PopoverContainer = styled.div<PopoverContainerProps>`
  background: ${ThemeGray500};
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: ${GridVars.padding.p3};
  
  visibility: ${(props) => (props.$show ? "visible" : "hidden")};
  transition-property: visibility;
  transition-delay:  ${AnimationDuration.short};
  transition-duration: 0s;
  ${props => props.$show ? AnimationFadeIn: AnimationFadeOut};

`;

export const PopoverButton = styled.div`
  margin-left: ${GridVars.margin.m1};
  display: flex;
  align-items: center;
  align-self: center;
`;

export const PopoverHR = styled(ElementHR)`
  margin: 0 0 ${GridVars.margin.m1} 0;
`;

export const Header = styled(TextHeader).attrs({ $size: 'normal' })`
  display: flex;
  align-items: center;
`;

export const PopoverStrong = styled(TextValue)`
  padding-left: 0;
  padding-top: ${GridVars.padding.p1};
`;

export const Paragraph = styled(TextParagraph)``;

export const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${GridVars.margin.m1};

  margin-top: ${GridVars.margin.m1};
  margin-bottom: ${GridVars.margin.m1};
`;

export const RowLabel = styled(TextLabel)`
  display: flex;
  gap: 4px;
  flex: 8;
  white-space: nowrap;
  align-items: center;
`;

export const RowValue = styled(TextLabel)`
  flex: 3;
  text-align: right;
  display: flex;
  justify-content: right;
  white-space: nowrap;
`;

export const RowIcon = styled.div`
  flex: 1;
  text-align: right;
  margin-left: ${GridVars.margin.m1};

  height: 18px;

  img {
    width: 18px;
    height: 18px;
  }
`;
