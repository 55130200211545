import { useSdk } from "../services/sdk-provider";
import useLocalStorage from "./useLocalStorage";
import { useVault } from "./useAppSelector";
import { parseValue } from "../utils";
import useSWR from "swr";
import { BigNumber, constants} from "ethers";
import { useMemo } from "react";
import { formatUnits } from "ethers/lib/utils";
import { IncentivesData } from "@altitude-fi/altitude-v1-sdk/dist/types";

const ALTI_VALUATION_KEY = 'alti-valuation';

export const useValuation = () => useLocalStorage<string>(ALTI_VALUATION_KEY);

export const useIncentives = (): IncentivesData => {
  const [valuation] = useValuation();
  const { sdk } = useSdk();
  const { data: vault } = useVault();

  const supplyUsdPrice = useMemo(() => {
    if (!vault) {
      return constants.Zero;
    }
    const borrowToUsdBn = parseValue(vault.borrowToUsdRatio, vault.borrowDecimals)
    const price = formatUnits(vault.supplyBasePriceBn.mul(borrowToUsdBn).div(parseValue('1', vault.borrowDecimals)), vault.borrowDecimals);
    return parseValue(price);
  }, [vault])

  const { data: [supplyTotalSupply = 0] = [] } = useSWR<BigNumber[]>(['IncentivesSupplyData'], () => sdk.getMultiData([
    sdk.multi.vaults.wstethUsdc.supplyToken.totalSupply(),
  ]));

  return useMemo(() => {
    return sdk.utils.incentives({
      valuation: valuation ? parseValue(valuation) : constants.Zero,
      tokenSupply: parseValue(process.env.REACT_APP_ALTI_TOTAL_SUPPLY || 0)
    }, {
      tokenSupply: supplyTotalSupply,
      usdPrice: supplyUsdPrice
    }, parseValue(process.env.REACT_APP_ALTI_TOKENS_PER_DAY || 0))
  }, [sdk, supplyTotalSupply, supplyUsdPrice, valuation])

}
