import Hotjar from "@hotjar/browser";
import { isDevelopment } from "../../../utils";
import { AnalyticsInterface } from "../analytics";

export class HotjarAnalytics implements AnalyticsInterface {
  constructor() {
    if (process.env.REACT_APP_HOTJAR_SITE_ID) {
      Hotjar.init(parseInt(process.env.REACT_APP_HOTJAR_SITE_ID), 6, {
        debug: isDevelopment(),
      });
    }
  }
  identify(id: string, data: object | undefined): void {
    if (Hotjar.isReady()) {
      Hotjar.identify(id, { id, ...data });
    }
  }

  page(data: object | undefined): void {
    if (Hotjar.isReady() && window) {
      Hotjar.stateChange(window.location.pathname)
    }
  }

  track(eventId: string, data: object | undefined): void {
    if (Hotjar.isReady()) {
      Hotjar.event(eventId);
    }
  }
}
