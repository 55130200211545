import React, { useMemo, useCallback } from "react";
// import { DateTime } from "luxon";

import { InfoPopover, LoaderInline, MoneyFormatter } from "../../../../components";
import TokenBalance from "../../../../components/token-balance";
import { PROJECT_NAME } from "../../../../config";
import { useAppSelector } from "../../../../hooks";
import { TokenDisplayType } from "../../../../models/vault";
import { TextLink, TextParagraph } from "../../../../utils/styles/text";
import ClaimModal from "../../../../components/claim-modal";
import * as Styles from "./style";
import { useUserPosition } from "../../../../hooks/useUserPosition";
import { usePopupManager } from "react-popup-manager";
import { useUserIncentives } from "../../../../hooks/useApi";
import { useUser, useUserVaultInfo } from "../../../../hooks/useAppSelector";
import { useBackgroundLoading } from "../../../../hooks/useBackgroundLoading";
import { useIncentives } from "../../../../hooks/useIncentives";
import { parseValue} from "../../../../utils";
import { formatUnits } from "ethers/lib/utils";
import { SetValuation } from "../user-stats/components/set-valuation";
import CustomPopover from "../../../../components/custom-popover";
import * as PopoverStyles from "../../../../components/custom-popover/styles";
import { PRECISION, PRECISION_DECIMALS } from "@altitude-fi/altitude-v1-sdk/dist/utils";

const SmartRepayments = () => {
  const { selectedVault } = useAppSelector((state) => state.global);
  const popupManager = usePopupManager();

  const { data: userVaultInfo, isLoading: userVaultInfoLoading } = useUserVaultInfo();
  const { data: userPosition, isLoading: userPositionLoading } = useUserPosition();
  const { data: user } = useUser();
  const { data: userIncentives, isLoading: incentivesLoading } = useUserIncentives(user?.userAddress);
  const incentivesData = useIncentives();

  const earnedRewards = useMemo(() => userIncentives?.data || 0, [userIncentives]);
  const earnedRewardsInUsd = useMemo(() =>
    incentivesData.altiPrice.gt(0)
      ? +formatUnits(
        incentivesData.altiPrice
          .mul(parseValue(earnedRewards))
          .div(PRECISION),
        PRECISION_DECIMALS
      )
      : '',
    [earnedRewards, incentivesData]
  );
  const claimableRewards = useMemo(() => userVaultInfo?.claimableRewards || 0, [userVaultInfo]);
  const isDataLoading = useMemo(() => (userVaultInfoLoading || incentivesLoading || userPositionLoading), [userPositionLoading, incentivesLoading, userVaultInfoLoading])
  const isLoading = useBackgroundLoading(isDataLoading);

  // const renderLoanRepaid = () => {
  //
  //   if (isLoading) {
  //     return <ValueLoader />;
  //   }
  //
  //   if (userPosition?.projected && userPosition.projected.repaidBy > 0) {
  //     const repaidByDateInMs = new Date().getTime() + userPosition.projected.repaidBy
  //     if (repaidByDateInMs > Number.MAX_SAFE_INTEGER) {
  //       return <Styles.SmartValueValue>&infin;</Styles.SmartValueValue>;
  //     }
  //     const repaidByDate = DateTime.fromISO(new Date(repaidByDateInMs).toISOString());
  //     const now = DateTime.now();
  //     const diff = repaidByDate.diff(now, ["years", "months", "days", "hours"]);
  //     return (
  //       <Styles.SmartValueValue>
  //         <span>around</span> {diff.years} {diff.years > 1 ? "years" : "year"},{" "}
  //         {diff.months} {diff.months > 1 ? "months" : "month"}
  //       </Styles.SmartValueValue>
  //     );
  //   }
  //
  //   return <Styles.SmartValueValue>n/a</Styles.SmartValueValue>;
  // };

  const openClaimEarningsPopup = useCallback(() => {
    popupManager.open<any>(ClaimModal, {
      title: "ClaimEarningsModal",
      onClose: () => {},
    });
  }, [popupManager]);

  const renderClaimableRewards = useCallback(() => {
    if (isLoading) {
      return <ValueLoader />
    }
    if (claimableRewards > 0) {
      return (
        <>
          <Styles.SmartValueAmount>
            <TokenBalance
              displayType={TokenDisplayType.borrow}
              vaultType={selectedVault}
              amount={claimableRewards}
            />
          </Styles.SmartValueAmount>
          <Styles.InlineButton onClick={openClaimEarningsPopup}>
            <span>claim</span>
          </Styles.InlineButton>
        </>
      )
    }
    return <Styles.SmartValueAmount>n/a</Styles.SmartValueAmount>
  }, [claimableRewards, openClaimEarningsPopup, selectedVault, isLoading]);

  const renderEarnedRewards = useCallback(() => {
    if (isLoading) {
      return <ValueLoader />
    }
    if (earnedRewards > 0) {
      return (
        <Styles.SmartValuePopover>
          <Styles.SmartValueAmount>
            <TokenBalance
              displayType={TokenDisplayType.incentive}
              vaultType={selectedVault}
              amount={earnedRewards}
            />
          </Styles.SmartValueAmount>
          <CustomPopover
            button={earnedRewardsInUsd ? (
              <Styles.IncentivesValueButton>
                <MoneyFormatter value={earnedRewardsInUsd} prefix={" $"} />
              </Styles.IncentivesValueButton>
            ) : (
              <Styles.InlineButton>
                set
              </Styles.InlineButton>
            )}
          >
            <SetValuation />
          </CustomPopover>
        </Styles.SmartValuePopover>
      )
    }
    return <Styles.SmartValueAmount>n/a</Styles.SmartValueAmount>
  }, [earnedRewards, earnedRewardsInUsd, isLoading, selectedVault]);

  const renderPendingHarvest = useCallback(() => {
    if (isLoading) {
      return <ValueLoader />
    }
    if (userPosition?.projected && userPosition?.currentHarvest.currentHarvestEarnings) {
      return (
        <>
          <span>around</span>{" "}
          <Styles.SmartValueAmount>
            <TokenBalance
              displayType={TokenDisplayType.borrow}
              vaultType={selectedVault}
              amount={userPosition?.currentHarvest.currentHarvestEarnings}
            />
          </Styles.SmartValueAmount>
        </>
      )
    }
    return <Styles.SmartValueAmount>n/a</Styles.SmartValueAmount>
  }, [selectedVault, userPosition, isLoading])

  return (
    <Styles.SmartRepaymentContainer>
      <Styles.SmartRepaymentWrapper>
        <Styles.SmartHeader>Smart Repayments</Styles.SmartHeader>
        <Styles.SmartDesc>
          <TextParagraph>
            {PROJECT_NAME} puts un-utilized borrowing capacity to work by generating yield, which is used
            to automatically repay your loan.
          </TextParagraph>
          {/* <TextParagraph>
            The amount below is the total amount that has been automatically
            repaid to date.
          </TextParagraph> */}
        </Styles.SmartDesc>
        <Styles.SmartLine />
        <Styles.SmartValueBoxContainer>
          {/*<Styles.SmartValueBox>*/}
          {/*  <Styles.SmartValueLabel>*/}
          {/*    Estimated Monthly Repayments*/}
          {/*  </Styles.SmartValueLabel>*/}
          {/*  {userPosition?.projected && userPosition?.projected.monthlyRepayments > 0 ? (*/}
          {/*    <Styles.SmartValueValue>*/}
          {/*      <span>around</span>{" "}*/}
          {/*      <Styles.SmartValueAmount>*/}
          {/*        <TokenBalance*/}
          {/*          displayType={TokenDisplayType.borrow}*/}
          {/*          vaultType={selectedVault}*/}
          {/*          amount={userPosition?.projected.monthlyRepayments || 0}*/}
          {/*        />*/}
          {/*      </Styles.SmartValueAmount>*/}
          {/*    </Styles.SmartValueValue>*/}
          {/*  ) : (*/}
          {/*    <Styles.SmartValueValue>*/}
          {/*      {isLoading ? (<ValueLoader />) : "n/a"}*/}
          {/*    </Styles.SmartValueValue>*/}
          {/*  )}*/}
          {/*</Styles.SmartValueBox>*/}
          {/*<Styles.SmartValueBox>*/}
          {/*  <Styles.SmartValueLabel>*/}
          {/*    Expected Full Loan Repayment*/}
          {/*  </Styles.SmartValueLabel>*/}
          {/*  <Styles.SmartValueValue>*/}
          {/*    {renderLoanRepaid()}*/}
          {/*  </Styles.SmartValueValue>*/}
          {/*</Styles.SmartValueBox>*/}

          <Styles.SmartValueBox>
            <Styles.SmartValueLabel>
              Earnings Pending Harvest
              <InfoPopover>
                <PopoverStyles.Paragraph>
                  Your estimated earnings for the upcoming harvest. If you have an outstanding loan, your loan will be reduced with these earnings. If you don't have a loan, you'll be able to claim the amount.
                </PopoverStyles.Paragraph>
                <PopoverStyles.Paragraph>
                  For more information, please see <TextLink href="https://docs.altitude.fi/general/yield-generation-process/how-are-yields-recognised" target="_blank">how yields are recognised.</TextLink>
                </PopoverStyles.Paragraph>
              </InfoPopover>
            </Styles.SmartValueLabel>
            <Styles.SmartValueContainer>
              <Styles.SmartValueValue>
                {renderPendingHarvest()}
              </Styles.SmartValueValue>
            </Styles.SmartValueContainer>
          </Styles.SmartValueBox>
          <Styles.SmartValueBox>
            <Styles.SmartValueLabel>
              Claimable Earnings
            </Styles.SmartValueLabel>
            <Styles.SmartValueContainer>
              {renderClaimableRewards()}
            </Styles.SmartValueContainer>
          </Styles.SmartValueBox>
          <Styles.SmartValueBox>
            <Styles.SmartValueLabel>
              Earned Rewards
            </Styles.SmartValueLabel>
            <Styles.SmartValueContainer>
              {renderEarnedRewards()}
            </Styles.SmartValueContainer>
          </Styles.SmartValueBox>
        </Styles.SmartValueBoxContainer>
      </Styles.SmartRepaymentWrapper>
    </Styles.SmartRepaymentContainer>
  );
};

const ValueLoader = () => {
  const rects = [{
    y: 0,
    x: "70%",
    rx: 4,
    ry: 4,
    width: "30%",
    height: 15,
  }];
  return <LoaderInline height={15} loaderSettings={rects} />
}

export default SmartRepayments;
