import styled from "styled-components";
import { TextLink, TextHeader, TextParagraph } from "../utils/styles/text";
import { showReportDialog } from '@sentry/react';

import { ThemeAppBackground } from "../utils/styles/theme.styles";
import { ROUTES } from "../utils";
import { Button } from "../utils/styles/button";

const ErrorPageWrapper = styled.div`
  background: ${ThemeAppBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
`;
const ButtonContainer = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  a {
    width: 100%;
  }
`;

export const ErrorPage = () => {
  return (
    <ErrorPageWrapper>
      <TextHeader $size="huge">Oops! Something went wrong here.</TextHeader>
      <TextParagraph>An unexpected error happened. If the problem persists please report it the Altitude team.</TextParagraph>
      <ButtonContainer>
        <Button $size="compact" $variant="secondary" onClick={() => showReportDialog({ })}>Report</Button>
        <TextLink href={ROUTES.dashboard}>Go back</TextLink>
      </ButtonContainer>
    </ErrorPageWrapper>
  )
}
