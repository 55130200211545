import { createSlice } from "@reduxjs/toolkit";
import { TRANSACTION_STATUS, TransactionStatus } from "../../utils";
import {TokenName, VaultName} from "@altitude-fi/altitude-v1-sdk/dist/types";
import { BigNumber } from "ethers";
import { TransactionReceipt } from "viem"
import { extractError, TxError, USER_REJECTED } from "../../utils/txErrors";

export const enum TransactionType {
  APPROVAL = 'APPROVAL',
  DEPOSIT_AND_BORROW = 'DEPOSIT_AND_BORROW',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  BORROW = 'BORROW',
  REPAY = 'REPAY',
  CLAIM = 'CLAIM'
}

export interface ApprovalInfo {
  token: TokenName
  amount: BigNumber
  spender: string
  owner: string
}

interface VaultInfo {
  vault: VaultName
}
export interface DepositAndBorrowInfo extends VaultInfo {
  supplyToken: TokenName
  borrowToken: TokenName
  depositAmount: BigNumber
  borrowAmount: BigNumber
}

export interface DepositInfo extends VaultInfo {
  userAddress: string
  supplyToken: TokenName
  depositAmount: BigNumber
}

export interface BorrowInfo extends VaultInfo {
  borrowToken: TokenName
  borrowAmount: BigNumber
}
export interface RepayInfo extends VaultInfo {
  userAddress: string
  borrowToken: TokenName
  repayAmount: BigNumber
}

export interface WithdrawInfo extends VaultInfo {
  supplyToken: TokenName
  withdrawAmount: BigNumber
  userAddress: string
}

export interface ClaimInfo extends VaultInfo {
  claimToken: TokenName
  claimAmount: BigNumber
}

export type TransactionInfo = ApprovalInfo | DepositAndBorrowInfo | DepositInfo | BorrowInfo | WithdrawInfo | RepayInfo | ClaimInfo

export type TxHash = `0x${string}`;

export interface TransactionDetails {
  id: TxId
  index: number
  status: TransactionStatus
  type: TransactionType
  info: TransactionInfo
  hash?: TxHash
  addedTime: number
  error?: TxError | null
  confirmedTime?: number
  confirmedBlock?: string
}

export type TxId = string

interface PayloadWithId {
  id: TxId
}

interface TransactionPayload extends PayloadWithId {
  index: number
  type: TransactionType
  info: TransactionInfo
}

export type TransactionFlowPayload = TransactionPayload[]

export interface TransactionState {
  transactions: {
    [txId: TxId]: TransactionDetails
  }
  currentTransaction: TxId | null
}

export type TransactionAccepted = PayloadWithId & {
  txHash: TxHash
}

export type TransactionFailed = PayloadWithId & {
  txError: TxError | null | undefined
}

export type TransactionConfirmed = PayloadWithId & {
  txReceipt: TransactionReceipt
}
export const initialState: TransactionState = {
  transactions: {},
  currentTransaction: null
}

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addTransactionFlow(
      state,
      {
        payload: transactionFlow,
      }: { payload: TransactionFlowPayload },
    ) {
      for(const transaction of transactionFlow) {
        if (state.transactions[transaction.id]) {
          continue
        }
        state.transactions[transaction.id] = {
          ...transaction,
          addedTime: Date.now(),
          status: TRANSACTION_STATUS.initiated
        }
      }
    },
    clearAllTransactions(state) {
      state.transactions = {};
      state.currentTransaction = null;
    },
    removeTransaction(
      state,
      { payload: { id } }: { payload: { id: string } },
    ) {
      if (state.transactions[id]) {
        delete state.transactions[id]
      }
    },
    finalizeTransaction(state, { payload }: { payload: TransactionConfirmed }) {
      const tx = state.transactions[payload.id]
      if (!tx) {
        return
      }
      state.transactions[payload.id] = {
        ...tx,
        status: TRANSACTION_STATUS.confirmed,
        confirmedBlock: String(payload.txReceipt.blockNumber),
        confirmedTime: Date.now(),
      }
    },
    acceptTransaction(state, { payload }: { payload: TransactionAccepted }) {
      if (!payload) {
        return;
      }
      const tx = state.transactions[payload.id]
      if (!tx) {
        return
      }
      state.transactions[payload.id] = {
        ...tx,
        status: TRANSACTION_STATUS.processing,
        hash: payload.txHash
      }
    },
    failTransaction(state, { payload }: { payload: TransactionFailed }) {
      if (!payload) {
        return;
      }
      const tx = state.transactions[payload.id]
      if (!tx) {
        return
      }
      let isRejectedByUser = false;
      if (payload.txError) {
        const [,, type] = extractError(payload.txError);
        if (type === USER_REJECTED) {
          isRejectedByUser = true;
        }
      }
      state.transactions[payload.id] = {
        ...tx,
        status: isRejectedByUser ? TRANSACTION_STATUS.rejected : TRANSACTION_STATUS.failed
      }
    },
    retryTransaction(state, { payload }: { payload: { id: TxId } }) {
      if (!payload) {
        return;
      }
      const tx = state.transactions[payload.id]
      if (!tx) {
        return
      }
      state.transactions[payload.id] = {
        ...tx,
        status: TRANSACTION_STATUS.initiated
      }
      state.currentTransaction = payload.id;
    },
    startTransactionFlow(state) {
      if (state.currentTransaction === null) {
        const firstTx = Object.values(state.transactions).find(t => t.index === 0);
        if (!firstTx) {
          return;
        }
        state.currentTransaction = firstTx.id;
      }
    },
    nextTransaction(state) {
      if (!state.currentTransaction) {
        return;
      }
      const tx = state.transactions[state.currentTransaction];
      const nextIndex = tx.index + 1;
      const nextTx = Object.values(state.transactions).find(t => t.index === nextIndex)
      if (!nextTx) {
        state.currentTransaction = null;
        return;
      }
      state.currentTransaction = nextTx.id;
    },
  },
});
export default transactionSlice;
