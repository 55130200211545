import { createAsyncThunk } from "@reduxjs/toolkit";
import { isNativeToken } from "../../../services/contracts/base";
import * as store from "../../../store";
import { selectUser } from "../../../store/user/user.slice";
import { selectVault } from "../../../store/vault/vault.slice";
import { sdk } from "../../../services/sdk-provider";

import {TokenName, VaultName} from "@altitude-fi/altitude-v1-sdk/dist/types";
import { BigNumber } from "ethers";
import { captureException } from "@sentry/react";
import { TransactionFlowPayload, TransactionType } from "../../transaction/transaction.slice";
import { addTransactionFlow, startTransactionFlow } from "../../../store";

interface DepositAndBorrowPayload {
  deposit?: BigNumber;
  borrow?: BigNumber;
  selectedVault: VaultName;
}

export const depositAndBorrow = createAsyncThunk<void, DepositAndBorrowPayload>(
  "userVault/depositAndBorrow",
  async (
    { deposit, borrow, selectedVault }: DepositAndBorrowPayload,
    thunkApi
  ) => {
    const { vault, user } = store.default.getState();
    const { userAddress }: any = selectUser(user);
    const { supplySymbol, supplyName, borrowName }: any =
      selectVault(vault, selectedVault) || {};

    const txSendArgs: any = {
      from: userAddress,
    };

    const vaultContract = sdk.contracts.vaults[selectedVault].vault;
    const tokenContract = sdk.contracts.tokens[supplyName as TokenName];

    const transactionFlow: TransactionFlowPayload = [];
    const approveTxId = `approve-${supplyName}`;
    if (deposit?.gt(0)) {
      try {
        if (isNativeToken(supplySymbol)) {
          txSendArgs.value = deposit;
        } else {
          const currentAllowance = await tokenContract.allowance(userAddress, vaultContract.address);
          if (currentAllowance.lt(deposit)) {
            transactionFlow.push({
              id: approveTxId,
              index: 0,
              type: TransactionType.APPROVAL,
              info: {
                token: supplyName,
                amount: deposit,
                spender: vaultContract.address,
                owner: userAddress
              }
            });
          }
        }
      } catch (error: any) {
        captureException(error);
        return thunkApi.rejectWithValue({
          id: approveTxId,
          error
        });
      }
    }

    const txId = `deposit-and-borrow-${supplyName}-${borrowName}`;
    try {
      let txInfo;
      let txType = TransactionType.DEPOSIT;
      if (deposit?.gt(0) && borrow?.gt(0)) {
        txInfo = {
          vault: selectedVault,
          supplyToken: supplyName,
          borrowToken: borrowName,
          depositAmount: deposit,
          borrowAmount: borrow
        };
        txType = TransactionType.DEPOSIT_AND_BORROW;
      } else if (deposit?.gt(0)) {
        txInfo = {
          vault: selectedVault,
          supplyToken: supplyName,
          depositAmount: deposit,
          userAddress
        };
        txType = TransactionType.DEPOSIT;
      } else if (borrow?.gt(0)) {
        txInfo = {
          vault: selectedVault,
          borrowToken: borrowName,
          borrowAmount: borrow,
        };
        txType = TransactionType.BORROW;
      } else {
        return;
      }

      transactionFlow.push({
        id: txId,
        index: transactionFlow.length,
        type: txType,
        info: txInfo
      })
      thunkApi.dispatch(addTransactionFlow(transactionFlow));
      thunkApi.dispatch(startTransactionFlow())
    } catch (error: any) {
      captureException(error);
      return thunkApi.rejectWithValue({
        id: txId,
        error
      });
    }
  }
);
