import styled from "styled-components";
import {
  Box,
  BoxHeader,
  BoxHeaderSquare,
  GridVars,
  ThemePrimaryText,
  AnimationFadeIn,
} from "../../../../utils";
import { Text, TextLabel, TextParagraph, TextValue } from '../../../../utils/styles/text'

interface TextValueProps {
  color?: string;
  fontSize?: number;
  readonly $lineHeight?: number;
}

interface ChartCaptionsProps {
  bottom: number;
}

interface ChartCaptionsWrapperProps {
  width: number;
}

interface ChartCaptionsPercentageSignProps extends TextValueProps {
  value: number;
}

const TextLabelSmallSVG = styled(TextLabel).attrs({ as: "text", size: "small" })``;
const TextValueSmallSVG = styled(TextValue).attrs({ as: "text", size: "small" })``;

export const BorrowingCapacityContainer = styled(Box)`
  grid-area: borrowingCapacity;
  display: grid;
  grid-template-areas:
    "borrowingCapacityTitle"
    "borrowingCapacityDescription"
    "borrowingCapacityChart";
  grid-template-rows: auto auto 1fr;
`;

export const BorrowingCapacitySubHeader = styled(TextParagraph)``;

export const CapturedBoxStick = styled.svg`
  ${AnimationFadeIn};
`;

export const CapturedValueCaption = styled(TextLabelSmallSVG)<{ y: number }>``;

export const CapturedValueValue = styled(TextValueSmallSVG)<{ y: number }>`
  fill: ${ThemePrimaryText};
`;

export const BorrowingCapacityPopover = styled(Text)``;

export const ChartWrapper = styled.div`
  grid-area: borrowingCapacityChart;
  align-self: center;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BorrowingCapacityTitle = styled.div`
  grid-area: borrowingCapacityTitle;
`;

export const BorrowingCapacityDescription = styled.div`
  grid-area: borrowingCapacityDescription;

  @media (min-width: ${GridVars.breakpoint.md}) and (max-width: ${GridVars
      .breakpoint.lg}) {
    margin-top: ${GridVars.margin.m2};
  }
`;

export const ChartCaptionsWrapper = styled.div<ChartCaptionsWrapperProps>`
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  top: 0;
  width: ${(props) => `${props.width}px`};
  height: 100%;
`;

export const ChartCaptions = styled.div<ChartCaptionsProps>`
  text-align: center;
  position: absolute;
  bottom: ${(props) => `${props.bottom}px`};
`;

export const ChartCaptionsPercentageWrapper = styled.div<TextValueProps>``;

export const ChartCaptionsPercentage = styled(Text)<TextValueProps>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontSize}px` || "48px"};
  font-weight: 600;
  line-height: ${(props) =>
    `${props.$lineHeight && props.$lineHeight}px` || "58px"};
  display: block;
  position: relative;
  margin-bottom: -5px;
`;

export const ChartCaptionsPercentageSign = styled(Text).attrs({ $weight: 'strong' })<ChartCaptionsPercentageSignProps>`
  font-size: ${(props) => `${props.fontSize}px` || "24px"};
  position: absolute;
  display: inline-block;
  margin-left: 4px;
`;

export const ChartCaptionsText = styled(Text).attrs({ $weight: 'strong' })<TextValueProps>`
  font-size: ${(props) => `${props.fontSize}px` || "12px"};
`;

export const Popover = styled.svg`
  width: 50px;
  height: 50px;

  color: ${ThemePrimaryText};
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #1e212a;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 18px;
  pointer-events: none;
`;

export const LoanToValueChartContainer = styled.div`
    width: 100%;
    overflow: hidden;
    margin-top: 16px
`
export const LoanHealthInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GridVars.margin.m1};
`
export const LoanHealthInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex: auto 1;
`
export const LoanHealthInfoLabel = styled(Text)``
export const LoanHealthInfoValue = styled(Text)`
  span {
    color: ${ThemePrimaryText};
  }
`

export { BoxHeader, BoxHeaderSquare };
