import * as Styles from "../../components/style";
import { Actions } from "../index";

const Processing = () => {
  return (
    <>
      <Styles.TransactionHeader>
        Confirm Transactions
      </Styles.TransactionHeader>
      <Actions />
    </>
  );
};

export default Processing;
