import React, { useCallback, useMemo, useState } from "react";
import TokenBalance from "../../components/token-balance/token-balance";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { TokenDisplayType } from "../../models/vault";
import { selectUser } from "../../store/user/user.slice";
import { claimRewards } from "../../store/userVault/actions/claimRewards";
import { selectUserVault } from "../../store/userVault/userVault.slice";
import { ElementHR, ModalContent, parseValue } from "../../utils";

import * as Styles from "./style";
import { CustomModal, TokenInput } from "../index";
import { selectVault } from "../../store/vault/vault.slice";
import { claimInputValidator } from "../../modules/dashboard/modals/validators";
import { ModalProps } from "../../modules/dashboard/modals/interfaces";
import { ValidatorFunction } from "../token-input/token-input";
import ReadOnlyWarning from "../../modules/dashboard/modals/components/read-only-warning";
import { useUser } from "../../hooks/useAppSelector";
import { BigNumber, constants } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { useVaultRateLimits, RateLimit } from "../../hooks/useVaultRateLimits";

const ClaimModal = ({ isOpen, onClose }: ModalProps) => {
  const dispatch = useAppDispatch();
  const userStore = useAppSelector((state) => state.user);
  const vaultStore = useAppSelector((state) => state.vault);
  const userVaultStore = useAppSelector((state) => state.userVault);
  const { selectedVault } = useAppSelector((state) => state.global);
  const { data: { isReadOnly } = {} } = useUser();

  const { walletConnected } = selectUser(userStore) || {};
  const { borrowSymbol = '', borrowToUsdRatio, borrowDecimals, borrowPrecision } = selectVault(vaultStore, selectedVault) || {};
  const { claimableRewards }: any =
    selectUserVault(userVaultStore, selectedVault) || {};

  const claimableRewardsBn = useMemo(() => parseValue(claimableRewards, borrowDecimals), [borrowDecimals, claimableRewards])
  const [claimAmount, setClaimAmount] = useState<BigNumber>(claimableRewardsBn);

  const handleClaim = useCallback(() => {
    if (walletConnected) {
      dispatch(claimRewards({ selectedVault, claimAmount }));
    }
  }, [claimAmount, dispatch, selectedVault, walletConnected]);

  const [isValid, setIsValid] = useState(true);
  const { data: rateLimits } = useVaultRateLimits();
  const claimRateLimit = useMemo(() => rateLimits && rateLimits[RateLimit.claim], [rateLimits]);
  const claimValidator = useCallback<ValidatorFunction>((value) => {
      if (!value) {
        return "";
      }
      const error = claimInputValidator(parseValue(value, borrowDecimals), claimableRewardsBn, claimRateLimit);
      setIsValid(!error);
      return error;
    },
    [borrowDecimals, claimableRewardsBn, claimRateLimit]
  );

  const isClaimButtonDisabled = useMemo(() =>  isReadOnly || !isValid || claimAmount.lte(0), [claimAmount, isReadOnly, isValid])

  // TODO: if we wrap this in {isOpen && ...} it will destroy the modal, but not render the close animation
  return (
    <CustomModal
      show={isOpen}
      onClose={onClose}
      heading={"Your Balance"}
      testId="claim-balance-modal"
    >
      <ModalContent data-testid="claimModal">
        <Styles.RewardsContainer>
          <Styles.RewardHeader>Claim Earnings</Styles.RewardHeader>
          <TokenInput
            defaultValue={formatUnits(claimableRewardsBn, borrowDecimals)}
            label={"Claim amount"}
            tokenName={borrowSymbol}
            decimals={borrowDecimals}
            precision={borrowPrecision}
            name={borrowSymbol + "Amount"}
            validator={claimValidator}
            onValueChange={(value) => setClaimAmount(value || constants.Zero)}
            maxValue={claimableRewardsBn}
            currencyExchangeRate={borrowToUsdRatio}
          />
          <Styles.RewardsLine>
            <ElementHR />
          </Styles.RewardsLine>
          <Styles.TotalBalance>
            <div>Claimable Earnings</div>
            <TokenBalance
              displayType={TokenDisplayType.borrow}
              vaultType={selectedVault}
              amount={claimableRewards}
              backLabel={true}
            />
          </Styles.TotalBalance>
          <Styles.ClaimRewardsBtn
            data-testid="claimRewards"
            onClick={handleClaim}
            disabled={isClaimButtonDisabled}
          >
            Claim Earnings
          </Styles.ClaimRewardsBtn>
          <ReadOnlyWarning />
        </Styles.RewardsContainer>
      </ModalContent>
    </CustomModal>
  );
};

export default ClaimModal;
