import { ToastTypes } from "../../models";
import styled from "styled-components";
import {
  GridVars,
  ThemePrimaryText,
  ThemeError,
  ThemeInfo,
  ThemeWarning,
} from "../../utils";
import { TextParagraph } from "../../utils/styles/text"

interface TopToastBarContainerProps {
  type: ToastTypes;
}

const getColor = (type: ToastTypes) => {
  switch (type) {
    case "error":
      return ThemeError;
    case "info":
      return ThemeInfo;
    case "warn":
      return ThemeWarning;
  }
};

export const TopToastBarContainer = styled.div`
  @media (max-width: ${GridVars.breakpoint.md}) {
    margin-top: 47px;
  }
`;

export const TopToastBarItemContainer = styled.div<TopToastBarContainerProps>`
  padding: ${GridVars.padding.p2};
  background-color: ${(props) => getColor(props.type)};
  text-align: center;
  a {
    color: ${ThemePrimaryText};
  }
`;

export const TopToastBarItemText = styled(TextParagraph)`
  color: ${ThemePrimaryText};
  text-align: center;
`;
