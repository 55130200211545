import { useValuation } from "../../../../../hooks/useIncentives";
import React, { useCallback, useState } from "react";
import * as Styles from "../style";
import * as PopoverStyles from "../../../../../components/custom-popover/styles";
import { BackButton} from "./style";
import { ArrowLeft } from "../../../../../assets/images/iconComponents";
import { FormNumericInput } from "../../../../../utils";
import { Button } from "../../../../../utils/styles/button";

type SetValuationProps = {
  onBack?: () => void
  onSubmit?: () => void
}
export const SetValuation = ({ onBack, onSubmit }: SetValuationProps) => {
  const [storedValuation, storeValuation] = useValuation();
  const [valuation, setValuation] = useState(storedValuation);
  const updateValuation = useCallback((value: string) =>  {
    storeValuation(value);
    onSubmit && onSubmit();
  }, [onSubmit, storeValuation])
  return (
    <Styles.PopoverContent>
      <PopoverStyles.Header>
        {onBack && <BackButton $size="inline" $variant="basic" onClick={onBack}><ArrowLeft /></BackButton>}
        <span>Set Fully Diluted Valuation</span>
      </PopoverStyles.Header>
      <PopoverStyles.Paragraph>ALTI tokens are currently non-transferrable.</PopoverStyles.Paragraph>
      <PopoverStyles.Paragraph>
        Set & save a fully diluted valuation below to update your supply APY
      </PopoverStyles.Paragraph>
      <PopoverStyles.Paragraph>
        <FormNumericInput
          id="incentives-valuation"
          name="incentives-valuation-input"
          value={valuation}
          placeholder="Enter fully diluted valuation"
          prefix="$"
          thousandSeparator=","
          allowNegative={false}
          allowLeadingZeros={false}
          onKeyDown={e => e.key === 'Enter' && updateValuation(valuation)}
          onValueChange={({ value }) => setValuation(value || '0')}
        />
      </PopoverStyles.Paragraph>
      {storedValuation && (
        <PopoverStyles.Paragraph>
          <Button $size="compact" $fluid $variant="secondary" onClick={() => updateValuation('')}>Exclude from APY</Button>
        </PopoverStyles.Paragraph>
      )}
      <PopoverStyles.Paragraph>
        <Button $size="compact" $fluid $variant="primary" onClick={() => updateValuation(valuation)}>Include in APY</Button>
      </PopoverStyles.Paragraph>
    </Styles.PopoverContent>
  )
}
