import { BarChart } from "../../modals/components/bar-chart/bar-chart-v2";
import React, { useMemo } from "react";
import { useUserVaultInfo, useVault } from "../../../../hooks/useAppSelector";
import { useUserPosition } from "../../../../hooks/useUserPosition";
import { LoaderInline } from "../../../../components";
import * as Styles from "./style";
import { useBackgroundLoading } from "../../../../hooks/useBackgroundLoading";

const LoanToValueChart = () => {
  const { data: vault, isLoading: vaultLoading } = useVault();
  const { data: position, isLoading: userPositionLoading } = useUserPosition();
  const { data: userVault, isLoading: userVaultInfoLoading } = useUserVaultInfo();

  const isDataLoading = useMemo(() => (vaultLoading || userPositionLoading || userVaultInfoLoading), [vaultLoading, userPositionLoading, userVaultInfoLoading]);
  const isLoading = useBackgroundLoading(isDataLoading);

  const load = [
    {
      y: 0,
      x: 0,
      rx: 3,
      ry: 3,
      width: 80,
      height: 35,
    },
    {
      y: 50,
      x: 0,
      rx: 3,
      ry: 3,
      width: "100%",
      height: 15,
    },
    {
      y: 80,
      x: 0,
      rx: 3,
      ry: 3,
      width: "100%",
      height: 25,
    },
  ]
  return (
    <Styles.LoanToValueChartContainer>
      {isLoading ? <LoaderInline height={150} loaderSettings={load}/> : (
        <BarChart
          toLabel="Loan to Value"
          from={position?.current.loanToValue || 0}
          to={position?.current.loanToValue || 0}
          balance={userVault?.debtTokenAmount || 0}
          liquidation={position?.current.liquidationLimit || 0}
          borrowLimit={position?.current.borrowLimit || 0}
          supplyThreshold={vault?.supplyThreshold || 0}
          liquidationThreshold={vault?.liquidationThreshold || 0}
          collateral={userVault?.collateralTokenAmount || 0}
          borrowToUsdRatio={vault?.borrowToUsdRatio || 0}
          borrowName={vault?.borrowSymbol || ''}
          supplySymbol={vault?.supplySymbol || ''}
        />
      )}
    </Styles.LoanToValueChartContainer>
  );
}

export default LoanToValueChart
