import { ReactElement } from "react";

import {
  IconAltitude,
  SvgLogoAave,
  SvgLogoCompound,
  SvgLogoConvexFinance,
  SvgLogoMorpho,
  LogoDai, LogoEth, LogoUsdc, LogoWbtc, LogoWstEth,
} from "../assets/images/iconComponents";
import {TokenName} from "@altitude-fi/altitude-v1-sdk/dist/types";

type options = { width: string; height: string };
export type Icon = "aave" | "convex" | "compound" | "morpho" | "lido";

export const iconToSvg = (icon: Icon, options: options): ReactElement => {
  switch (icon) {
    case "morpho":
      return (
        <SvgLogoMorpho width={options.width} height={options.height} />
      );
    case "convex":
      return (
        <SvgLogoConvexFinance width={options.width} height={options.height} />
      );
    case "aave":
      return <SvgLogoAave width={options.width} height={options.height} />;
    case "compound":
      return <SvgLogoCompound width={options.width} height={options.height} />;
    case "lido":
      return <LogoWstEth width={options.width} height={options.height} />;
    default:
      return <IconAltitude width={options.width} height={options.height} />;
  }
};

export const TokenLogoComponentMapping: Record<string, (props: any) => JSX.Element> = {
  ETH: LogoEth,
  WSTETH: LogoWstEth,
  USDC: LogoUsdc,
  WBTC: LogoWbtc,
  DAI: LogoDai,
}
export const getTokenLogoComponent = (tokenName: TokenName) => {
  return TokenLogoComponentMapping[tokenName.toUpperCase()];
};
