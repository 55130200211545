import * as Styles from "./components/style";
import { Actions, Failure, Processing, Transaction } from "./components";
import Confirmed from "./components/confirmed";
import {
  TransactionMode,
  useTransactionMode,
} from "../../hooks/useTrasactionMode";

const TransactionStatus = () => {
  const { transactions, transactionMode } = useTransactionMode();

  return (
    <Styles.TransactionContainer>
      <Styles.TransactionBody>
        {transactionMode === TransactionMode.confirmed && <Confirmed />}
        {transactionMode === TransactionMode.processing && <Processing />}
        {transactionMode === TransactionMode.failed && <Failure />}
      </Styles.TransactionBody>
      <Styles.TransactionFooter>
        <Styles.Divider />
        {transactionMode !== TransactionMode.confirmed && transactionMode !== TransactionMode.failed
          ? (
            <Styles.TransactionsContainer>
              {transactions.map((tx) => (
                <Transaction key={tx.id} transaction={tx} />
              ))}
            </Styles.TransactionsContainer>
          ) : (
            <Actions />
          )
        }
      </Styles.TransactionFooter>
    </Styles.TransactionContainer>
  );
};

export default TransactionStatus;
