import React from "react";
import { ModalProps } from "../../../../modules/dashboard/modals";

import { BorrowTab } from "./tabs/borrow.tab";
import { RepayTab } from "./tabs/repay.tab";
import TabsModal from "../../../../components/tabs-modal";

export const Tabs: Record<string, string> = {
  borrow: "Borrow",
  repay: "Repay",
};

const TabsMap = {
  [Tabs.borrow]: <BorrowTab />,
  [Tabs.repay]: <RepayTab />,
}

export const BorrowRepaymentModal = ({ isOpen, onClose, defaultTab }: ModalProps) => {
  return (
    <TabsModal
      tabs={Tabs}
      defaultTab={defaultTab || Tabs.borrow}
      show={isOpen}
      onClose={onClose}
      testId="borrow-repay-modal"
    >
      {(activeTab) => TabsMap[activeTab]}
    </TabsModal>
  );
};
