import { cssAssets } from "../../assets/images/css";
import styled from "styled-components";
import {
  GridVars,
  Box,
  ThemeBannerBackground,
  ThemeGray50,
} from "../../utils";

import { Button } from "../../utils/styles/button";
import {
  TextHeader,
  TextParagraph,
} from "../../utils/styles/text";

export const Banner = styled(Box)`
  background: ${ThemeBannerBackground};
  background-blend-mode: overlay, normal;
  grid-area: banner;
  padding: 0;
`;

export const BannerBackground = styled.div`
  background-image: url(${cssAssets.bubbles.left});
  background-repeat: no-repeat;
  background-size: contain;
  padding: ${GridVars.padding.p3};

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${GridVars.breakpoint.sm}) {
    background-size: contain;
    background-image: url(${cssAssets.bubbles.top});
  }
`;

export const BannerRotatedBackground = styled.div`
  background-image: url(${cssAssets.bubbles.right});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;

  @media (max-width: ${GridVars.breakpoint.lg}) {
    background: none;
  }
`;

export const BannerButtonContainer = styled.div`
  width: 100%;
  max-width: ${GridVars.breakpoint.sm};
`;

export const MainActionBtn = styled(Button).attrs({
  $variant: 'primary',
  $fluid: true
})`
  &:disabled {
    background: ${ThemeGray50};
  }
`;

export const SecondaryActionBtn = styled(Button).attrs({
  $variant: 'secondary',
  $fluid: true
})``;

export const BannerHeader = styled(TextHeader).attrs({ $size: 'larger' })`
  text-align: center;
`;

export const BannerSubHeader = styled(TextParagraph)`
  text-align: center;
`;

export const BannerContent = styled.div`
  max-width: ${GridVars.breakpoint.sm};
`;

export const BannerActions = styled.div`
  display: flex;
  gap: ${GridVars.gap};
  width: 100%;
`;
