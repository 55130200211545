import { CustomModal } from "../index";
import React, { useCallback, useEffect, useState } from "react";
import { TransactionStatus } from "./index";
import { useCurrentTransaction } from "../../services/transaction-provider";

const POPUP_CLEAR_TRANSACTIONS_TIMEOUT = 1000;

const TransactionModal = () => {
  const { isProcessing, clearTransactions } = useCurrentTransaction();
  const [showModal, setShowModal] = useState(isProcessing);

  const closeModal = useCallback(
    () => {
      let timeout: NodeJS.Timeout | undefined
      setShowModal(false);
      setTimeout(() => clearTransactions(), POPUP_CLEAR_TRANSACTIONS_TIMEOUT);
      return () => clearTimeout(timeout);
    },
    [clearTransactions]
  );

  useEffect(() => {
    if (isProcessing) {
      setShowModal(true)
    } else {
      closeModal();
    }
  }, [closeModal, isProcessing]);

  return (
    <CustomModal
      preventOutsideClick
      onClose={closeModal}
      show={showModal}
      testId="deposit-modal"
    >
      <TransactionStatus />
    </CustomModal>
  )
}

export default TransactionModal;
