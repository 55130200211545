import * as Styles from "../../components/style";
import { useState } from "react";
import TransactionNotOk from "../icons/TransactionNotOk";
import { useCurrentTransaction } from "../../../../services/transaction-provider";
import { useBlockNumber, useWaitForTransactionReceipt } from "wagmi";

const Failure = () => {
  const [showMore, setShowMore] = useState(false);
  const { currentTransaction } = useCurrentTransaction();
  const { failureReason} = useWaitForTransactionReceipt({ hash: currentTransaction?.hash });
  const { data: blockNumber } = useBlockNumber();

  return (
    <>
      <Styles.TransactionHeader>
        Transaction Failed
      </Styles.TransactionHeader>
      <Styles.ActionsContainer>
        <Styles.ConfirmedContainer>
          <TransactionNotOk width="75px" height="75px" />
          {failureReason && (
            <Styles.ErrorContainer>
              <Styles.ErrorTitle>{'shortMessage' in failureReason && failureReason.shortMessage}</Styles.ErrorTitle>
              <Styles.ShowMore onClick={() => setShowMore(prev => !prev)}>Show {showMore ? `less` : `more`}</Styles.ShowMore>
              {showMore && (
                <Styles.ErrorDetails>
                  Block number: {blockNumber ? blockNumber.toString() : 'N/A'}
                  <br/><br/>
                  {failureReason.message}
                </Styles.ErrorDetails>
              )}
            </Styles.ErrorContainer>
          )}
        </Styles.ConfirmedContainer>
      </Styles.ActionsContainer>
    </>
  );
};

export default Failure;
