import { IconProps } from "../../../../assets/images/iconComponents";

const TransactionOk = ({ inactive, ...rest }: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="12" cy="12" r="12" fill={inactive ? '#606060' : '#4F8BFF'}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5.93934 8.06066C5.35355 7.47487 5.35355 6.52513 5.93934 5.93934C6.52513 5.35355 7.47487 5.35355 8.06066 5.93934L12 9.87868L15.9393 5.93934C16.5251 5.35355 17.4749 5.35355 18.0607 5.93934C18.6464 6.52513 18.6464 7.47487 18.0607 8.06066L14.1213 12L18.0607 15.9393C18.6464 16.5251 18.6464 17.4749 18.0607 18.0607C17.4749 18.6464 16.5251 18.6464 15.9393 18.0607L12 14.1213L8.06066 18.0607C7.47487 18.6464 6.52513 18.6464 5.93934 18.0607C5.35355 17.4749 5.35355 16.5251 5.93934 15.9393L9.87868 12L5.93934 8.06066Z"
          fill={inactive ? '#A5A5A5' : 'white'}/>
  </svg>
);

export default TransactionOk;
