import { useApyData } from "./useApi";
import { useUserVaultInfo, useVault, useVaultStats } from "./useAppSelector";
import { useDebounce } from "./useDebounce";
import { useSdk } from "../services/sdk-provider";
import { BigNumber } from "ethers";
import { PRECISION_DECIMALS } from "@altitude-fi/altitude-v1-sdk/dist/utils";
import { parseValue } from "../utils";
import { useIncentives } from "./useIncentives";
import { VaultModel } from "../models";
import { useIncludeLidoApr, useLido } from "./useLido";

// TODO Migrate to using SDK types and BigNumbers. Temporarily convert all BigNumbers to numbers
const defaultUserPosition = {
  net: {
    netWorth: 0,
    netWorthInOneYear: 0,
    netApy: 0,
  },
  supply: {
    supplyInOneYear: 0,
    supplyApy: 0,
    earningsApy: 0,
  },
  loan: {
    loanInOneYear: 0,
    dailyBorrowCost: 0,
    loanApy: 0,
  },
  projected: {
    dailyEarnings: 0,
    monthlyRepayments: 0,
    repaidBy: 0,
  },
  current: {
    activeAssets: 0,
    healthFactor: 0,
    loanToValue: 0,
    liquidationLimit: 0,
    borrowLimit: 0,
    borrowingCapacity: 0,
  },
  currentHarvest: {
    currentHarvestEarnings:0,
    harvestRatio: 0,
  },
}

export type UserPosition = typeof defaultUserPosition;

/**
 * Returns calculated user position
 * @returns user position
 */
export const useUserPosition = (newDebtAmount?: BigNumber, newCollateralAmount?: BigNumber): { data: UserPosition | undefined, isLoading: boolean} => {
  const { data: vault, isLoading: vaultLoading } = useVault();
  const { data: userVaultInfo, isLoading: userVaultLoading } = useUserVaultInfo();
  const { data: vaultStats, isLoading: vaultStatsLoading } = useVaultStats(vault as VaultModel);
  const { data: apysData, isLoading: apysDataLoading } = useApyData();
  const incentives = useIncentives();
  const { lidoApr, isLoading: isLidoLoading } = useLido();
  const [includeLidoApr] = useIncludeLidoApr();
  const { sdk } = useSdk();
  const apyData = apysData?.data;

  const isLoading = useDebounce(apysDataLoading || userVaultLoading || vaultLoading || vaultStatsLoading || isLidoLoading, 500);

  const debtAmount = newDebtAmount || userVaultInfo?.debtTokenAmountBn;
  const collateralAmount = newCollateralAmount || userVaultInfo?.collateralTokenAmountBn;
  let data = defaultUserPosition;
  if (apyData && vault?.supplyBasePrice && collateralAmount && debtAmount && vault?.liquidationThresholdBn && vaultStats?.totalSupplyBn) {
    const supplyBasePriceBn = parseValue(vault.supplyBasePrice, vault?.borrowDecimals);

    const userPositionData =  sdk.utils.apy.userPosition({
      collateralAmount,
      debtAmount,
      harvestJoiningBlock: userVaultInfo?.harvestJoiningBlock
    }, {
      totalBorrow: vaultStats?.totalBorrowBn,
      totalSupply: vaultStats?.totalSupplyBn,
      vaultBorrowAmount: vaultStats?.totalVaultBorrow,
      supplyThreshold: vault?.supplyThresholdBn,
      supplyBasePrice: supplyBasePriceBn,
      liquidationThreshold: vault?.liquidationThresholdBn,
      supplyDecimals: vault?.supplyDecimals,
      borrowDecimals: vault?.borrowDecimals
    }, {
      ...apyData,
      apys: {
        ...apyData.apys,
        tokenBaseApy: 0, // default value
        incentivesApy: incentives.apy,
      }
    });

    data = {
      net: {
        netWorth: sdk.utils.convertFromWei(userPositionData.net.netWorth.toString(), vault?.borrowDecimals),
        netWorthInOneYear: sdk.utils.convertFromWei(userPositionData.net.netWorthInOneYear.toString(), vault?.borrowDecimals),
        netApy: incentives.apy ? userPositionData.net.netApy + incentives.apy : userPositionData.net.netApy
      },
      supply: {
        supplyInOneYear: sdk.utils.convertFromWei(userPositionData.supply.supplyInOneYear.toString(), vault?.borrowDecimals),
        supplyApy: userPositionData.supply.supplyApy + (includeLidoApr ? lidoApr : 0),
        earningsApy: userPositionData.supply.earningsApy
      },
      loan: {
        loanInOneYear: sdk.utils.convertFromWei(userPositionData.loan.loanInOneYear.toString(), vault?.borrowDecimals),
        dailyBorrowCost: sdk.utils.convertFromWei(userPositionData.loan.dailyBorrowCost.toString(), vault?.borrowDecimals),
        loanApy: userPositionData.loan.loanApy
      },
      projected: {
        dailyEarnings: sdk.utils.convertFromWei(userPositionData.projected.dailyEarnings.toString(), vault?.borrowDecimals),
        monthlyRepayments: sdk.utils.convertFromWei(userPositionData.projected.monthlyRepayments.toString(), vault?.borrowDecimals),
        repaidBy: userPositionData.projected.repaidBy
      },
      current: {
        activeAssets: sdk.utils.convertFromWei(userPositionData.current.activeAssets.toString(), vault?.borrowDecimals),
        healthFactor: sdk.utils.convertFromWei(userPositionData.current.healthFactor.toString(), PRECISION_DECIMALS),
        loanToValue: sdk.utils.convertFromWei(userPositionData.current.loanToValue.toString(), PRECISION_DECIMALS),
        liquidationLimit: sdk.utils.convertFromWei(userPositionData.current.liquidationLimit.toString(), vault?.borrowDecimals),
        borrowLimit: sdk.utils.convertFromWei(userPositionData.current.borrowLimit.toString(), vault?.borrowDecimals),
        borrowingCapacity: sdk.utils.convertFromWei(userPositionData.current.borrowingCapacity.toString(), PRECISION_DECIMALS),
      },
      currentHarvest: {
        currentHarvestEarnings: sdk.utils.convertFromWei(userPositionData.currentHarvest.currentHarvestEarnings.toString(), vault?.borrowDecimals),
        harvestRatio: userPositionData.currentHarvest.harvestRatio,
      }
    }
  }

  return { data, isLoading }
}
