import { useAppDispatch } from "./useAppDispatch";
import { useCallback, useMemo } from "react";
import { DepositAndBorrowInfo, TransactionDetails, TransactionType } from "../store/transaction/transaction.slice";
import { BigNumber, constants } from "ethers";
import { TokenName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { TokenDisplayType } from "../models/vault";
import { TRANSACTION_STATUS } from "../utils";
import { clearAllTransactions } from "../store";
import { useAppSelector } from "./useAppSelector";

export const enum TransactionMode {
  idle,
  processing,
  confirmed,
  failed,
}
export const useTransactionMode = () => {
  const dispatch = useAppDispatch();
  const transactionFlow = useAppSelector((state) => state.transaction.transactions);
  const currentTransactionId = useAppSelector((state) => state.transaction.currentTransaction);
  const currentTransaction = useMemo(() => currentTransactionId !== null ? transactionFlow[currentTransactionId] : null, [currentTransactionId, transactionFlow])
  const transactions = useMemo(() => Object.values(transactionFlow).sort((a, b) => a.index > b.index ? 1 : -1 ), [transactionFlow]);
  const getActionDetails = (transactionDetails: TransactionDetails) => {
    const labelMap: any = {
      [TransactionType.DEPOSIT]: 'Deposit',
      [TransactionType.REPAY]: 'Repay',
      [TransactionType.BORROW]: 'Borrow',
      [TransactionType.WITHDRAW]: 'Withdraw',
    }
    let value = constants.Zero;
    let tokenName = '' as TokenName;
    let displayType = [TransactionType.DEPOSIT, TransactionType.WITHDRAW].includes(transactionDetails.type) ? TokenDisplayType.supply : TokenDisplayType.borrow;
    if ('amount' in transactionDetails.info) {
      value = transactionDetails.info.amount;
      tokenName = transactionDetails.info.token;
    }
    if ('depositAmount' in transactionDetails.info) {
      value = transactionDetails.info.depositAmount;
      tokenName = transactionDetails.info.supplyToken;
    }
    if ('borrowAmount' in transactionDetails.info) {
      value = transactionDetails.info.borrowAmount;
      tokenName = transactionDetails.info.borrowToken;
    }
    if ('claimAmount' in transactionDetails.info) {
      value = transactionDetails.info.claimAmount;
      tokenName = transactionDetails.info.claimToken;
    }
    if ('repayAmount' in transactionDetails.info) {
      value = transactionDetails.info.repayAmount;
      tokenName = transactionDetails.info.borrowToken;
    }
    if ('withdrawAmount' in transactionDetails.info) {
      value = transactionDetails.info.withdrawAmount;
      tokenName = transactionDetails.info.supplyToken;
    }
    return {
      label: labelMap[transactionDetails.type] || '',
      value,
      displayType,
      tokenName
    }
  }
  const actions = useMemo<{ label: string, value: BigNumber, displayType: TokenDisplayType, tokenName: TokenName }[]>(() => {
    const userActions = transactions.filter(t => t.type !== TransactionType.APPROVAL);
    const result = [];
    for (const action of userActions) {
      if (action.type === TransactionType.DEPOSIT_AND_BORROW) {
        const info = (action.info as DepositAndBorrowInfo)
        result.push({
          label: 'Deposit',
          value: info.depositAmount,
          displayType: TokenDisplayType.supply,
          tokenName: info.supplyToken
        });
        result.push({
          label: 'Borrow',
          value: info.borrowAmount,
          displayType: TokenDisplayType.borrow,
          tokenName: info.borrowToken
        })
      } else {
        result.push(getActionDetails(action))
      }
    }
    return result;
  }, [transactions]);
  const transactionMode = useMemo(() => {
    if (transactions.some((t) => [TRANSACTION_STATUS.initiated, TRANSACTION_STATUS.processing, TRANSACTION_STATUS.rejected].includes(t.status))) {
      return TransactionMode.processing;
    }
    if (transactions.every((t) => t.status === TRANSACTION_STATUS.confirmed)) {
      return TransactionMode.confirmed;
    }
    const finalTx = transactions[transactions.length - 1];
    if (finalTx && finalTx.status === TRANSACTION_STATUS.failed) {
      return TransactionMode.failed;
    }
    return TransactionMode.idle;
  }, [transactions]);

  const clearTransactions = useCallback((): any => dispatch(clearAllTransactions()), [dispatch]);

  return {
    clearTransactions,
    transactionMode,
    actions,
    transactions,
    currentTransaction
  }
}
