import { parseEther } from "ethers/lib/utils";
import { useMemo } from "react";
import { BigNumber, constants } from "ethers";

const PRICE_MARGIN = parseEther('0.0025');

/**
 * Calculates the maximum borrow amount, considering potential price fluctuations that may occur during the transaction.
 * @param borrowLimit current borrow limit
 * @param debtAmount current user debt
 */
export const useMaxBorrowAmount = (borrowLimit: BigNumber, debtAmount: BigNumber) => {
  return useMemo(() => {
    const priceChangeMargin = borrowLimit.mul(PRICE_MARGIN).div(parseEther('1'))
    const maxBorrow = borrowLimit.sub(debtAmount || constants.Zero).sub(priceChangeMargin);
    return maxBorrow.gt(0) ? maxBorrow : constants.Zero;
  }, [borrowLimit, debtAmount])
}
