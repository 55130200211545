import { IconProps } from "../../../../assets/images/iconComponents";

const TransactionOk = ({ inactive, ...rest }: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="12" cy="12" r="12" fill={inactive ? '#606060' : '#4F8BFF'}/>
    <path d="M6 11.3043L10.6716 17.6234C10.8017 17.7994 11.0703 17.7814 11.1757 17.5896L17 7" stroke={inactive ? '#A5A5A5' : 'white'}
          strokeWidth="3" strokeLinecap="round"/>
  </svg>
);

export default TransactionOk;
