import styled from "styled-components";
import { GridVars } from "../../utils";
import { TextHeader, TextParagraph } from "../../utils/styles/text"

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${GridVars.margin.m3};
  height: 100vh;
  max-height: 200px;
`;
export const NotFoundTitle = styled(TextHeader).attrs({ $size: 'larger' })`
  text-align: center;
`;
export const NotFoundText = styled(TextParagraph)`
  text-align: center;
`;
