import numbro from "numbro";
import { BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils";

interface PercentageFormatterProps {
  percentage: number | BigNumber;
  fractionDigits?: number;
}

const PercentageFormatter = ({
  percentage,
  fractionDigits = 1,
}: PercentageFormatterProps) => {
  if (BigNumber.isBigNumber(percentage)) {
    percentage = +formatEther(percentage);
  }
  if (isNaN(percentage)) {
    return <>0.00</>;
  }
  if (!isFinite(percentage)) {
    return <>&infin;</>
  }

  percentage = percentage * 100;
  return numbro(percentage).format({
    average: true,
    mantissa: fractionDigits
  });
};

export default PercentageFormatter;
