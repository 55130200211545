import {
  ApprovalInfo, BorrowInfo, ClaimInfo,
  DepositAndBorrowInfo,
  TransactionDetails,
  TransactionType
} from "../../../../store/transaction/transaction.slice";
import { useMemo } from "react";
import { getSymbolFromName, TRANSACTION_STATUS } from "../../../../utils";
import * as Styles from './style';
import { TransactionClaim, TransactionIn, TransactionInAndOut, TransactionOk, TransactionOut } from "../icons";
import { useCurrentTransaction } from "../../../../services/transaction-provider";
import { getTokenLogoComponent } from "../../../../utils/icons.mapping";
import TransactionNotOk from "../icons/TransactionNotOk";
import useBlockExplorer from "../../../../hooks/useBlockExplorer";

type TransactionProps = {
  transaction: TransactionDetails
}

const Transaction = ({ transaction }: TransactionProps) => {
  const { currentTransaction, retryTransaction, isTransactionDelayed } = useCurrentTransaction();
  const { transactionUrl } = useBlockExplorer();
  const isError = useMemo(() => [TRANSACTION_STATUS.failed, TRANSACTION_STATUS.rejected].includes(transaction.status), [transaction]);
  const isConfirmed = useMemo(() => transaction.status === TRANSACTION_STATUS.confirmed, [transaction]);
  const isConfirming = useMemo(() => transaction.status === TRANSACTION_STATUS.processing, [transaction]);
  const tokenName = useMemo(() => {
    switch (transaction.type) {
      case TransactionType.APPROVAL:
        return (transaction.info as ApprovalInfo).token;
      case TransactionType.DEPOSIT_AND_BORROW:
      case TransactionType.DEPOSIT:
      case TransactionType.WITHDRAW:
        return (transaction.info as DepositAndBorrowInfo).supplyToken;
      case TransactionType.BORROW:
        return (transaction.info as BorrowInfo).borrowToken;
      case TransactionType.CLAIM:
        return (transaction.info as ClaimInfo).claimToken;
      default:
        return null;
    }
  }, [transaction])
  const label = useMemo(() => {
    const typeLabelMap = {
      [TransactionType.APPROVAL]: {
        default: `Approve ${tokenName && getSymbolFromName(tokenName)}`,
        pending: 'Approval pending...',
        error: 'Approval rejected',
        confirmed: 'Approved'
      },
      [TransactionType.DEPOSIT]: {
        default: 'Deposit',
        pending: 'Deposit pending...',
        error: 'Deposit rejected',
        confirmed: 'Deposited'
      },
      [TransactionType.DEPOSIT_AND_BORROW]: {
        default: 'Deposit & Borrow',
        pending: 'Deposit & Borrow pending...',
        error: 'Deposit & Borrow rejected',
        confirmed: 'Deposited & Borrowed'
      },
      [TransactionType.BORROW]: {
        default: 'Borrow',
        pending: 'Borrow pending...',
        error: 'Borrowing rejected',
        confirmed: 'Borrowed'
      },
      [TransactionType.REPAY]: {
        default: 'Repay',
        pending: 'Repayment pending... ',
        error: 'Repayment rejected',
        confirmed: 'Repaid'
      },
      [TransactionType.WITHDRAW]: {
        default: 'Withdraw',
        pending: 'Withdrawal pending...',
        error: 'Withdrawal rejected',
        confirmed: 'Withdrawn'
      },
      [TransactionType.CLAIM]: {
        default: 'Claim',
        pending: 'Claiming pending...',
        error: 'Claiming rejected',
        confirmed: 'Claimed'
      }
    }
    let mapping = typeLabelMap[transaction.type];
    if (isConfirming) {
      return mapping.pending;
    } else if (isConfirmed) {
      return mapping.confirmed;
    } else if (transaction.status === TRANSACTION_STATUS.rejected) {
      return mapping.error;
    }
    return mapping.default;
  }, [transaction, tokenName, isConfirming, isConfirmed]);

  const icon = useMemo(() => {
    const active = transaction.id === currentTransaction?.id && !isConfirming && !isConfirmed && !isError;
    const typeIconMapping = {
      [TransactionType.DEPOSIT]: TransactionIn,
      [TransactionType.DEPOSIT_AND_BORROW]: TransactionInAndOut,
      [TransactionType.APPROVAL]: TransactionOut,
      [TransactionType.BORROW]: TransactionOut,
      [TransactionType.REPAY]: TransactionIn,
      [TransactionType.WITHDRAW]: TransactionOut,
      [TransactionType.CLAIM]: TransactionClaim
    }
    let IconComponent = typeIconMapping[transaction.type];
    if (isConfirmed) {
      IconComponent = TransactionOk;
    } else if (isError) {
      IconComponent = TransactionNotOk;
    } else if (transaction.type === TransactionType.APPROVAL && tokenName) {
      IconComponent = getTokenLogoComponent(tokenName);
    }
    return (
      <Styles.TransactionIconContainer>
        <Styles.TransactionIcon>
          <IconComponent inactive={!active} />
        </Styles.TransactionIcon>
        {active && <Styles.TransactionIconPulsingCircle />}
        {isConfirming && (
          <>
            <Styles.TransactionSpinningCircleLeft />
            <Styles.TransactionSpinningCircleRight />
          </>
        )}
      </Styles.TransactionIconContainer>
    );
  }, [transaction, currentTransaction, isConfirming, isConfirmed, isError, tokenName]);

  return (
    <Styles.TransactionContainer>
      <Styles.TransactionLabelContainer>
        {icon}
        <Styles.TransactionLabel>{label}</Styles.TransactionLabel>
      </Styles.TransactionLabelContainer>
      {isError && <Styles.Retry $variant="secondary" onClick={retryTransaction}>Retry</Styles.Retry>}
      {isTransactionDelayed && currentTransaction?.id === transaction.id ? (
        <Styles.StillProcessing target="_blank" rel="noreferrer noopener" href={transactionUrl(transaction.hash)}>
          Check status
        </Styles.StillProcessing>
      ) : ''}
    </Styles.TransactionContainer>
  )
}

export default Transaction;
