import { createAsyncThunk } from "@reduxjs/toolkit";
import {BigNumberish, constants} from "ethers";
import { TokenName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { sdk } from "../../../services/sdk-provider";
import * as store from "../../index";
import {selectUser} from "../user.slice";

type ApproveSpendingPayload = {
  spender: string;
  owner?: string;
  amount: BigNumberish;
  tokenName: TokenName;
};

export const approveSpending = createAsyncThunk(
  "user/approveSpending",
  async ({ amount, tokenName, spender, owner }: ApproveSpendingPayload, thunkApi) => {
    try {
      const { user } = store.default.getState();
      const { userAddress }: any = selectUser(user);
      const ownerAddress = owner || userAddress || constants.AddressZero
      const tokenContract = sdk.contracts.tokens[tokenName];
      const currentAllowance = await tokenContract.allowance(ownerAddress, spender);
      if (currentAllowance.gte(amount)) {
        return Promise.resolve();
      }
      const tx = await tokenContract.approve(
        spender,
        amount,
      );
      thunkApi.fulfillWithValue({
        id: `approve-${tokenName}`,
        txReceipt: tx
      });
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }
  }
);
