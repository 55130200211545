import styled from "styled-components";
import { ThemePrimaryText, ThemePrimary, ThemeSecondaryText } from "./theme.styles";
import { GridVars } from "./grid.styles";

export const weights = {
  light: 300,
  normal: 400,
  medium: 500,
  strong: 600,
  stronger: 800,
}
export const textSizes = {
  small: "0.7rem",
  normal: "0.8rem",
  medium: "1rem",
  large: "1.2rem",
  mediumLarge: "1.6rem",
  larger: "2rem",
  huge: "2.5rem",
}
export type TextProps = {
  $weight?: keyof typeof weights
  $size?: keyof typeof textSizes
}
export const Text = styled.div<TextProps>`
  display: inline;
  color: ${ThemeSecondaryText};
  fill: ${ThemeSecondaryText};
  font-size: ${props => textSizes[props.$size || 'normal']};
  font-weight: ${props => weights[props.$weight || 'normal']};
  line-height: 1.4em;
  vertical-align: middle;
`;
export const TextLink = styled.a`
  color: ${ThemePrimary};

  :hover {
    opacity: 0.8;
  }

  &:visited {
    color: ${ThemePrimaryText};
  }
`;

export const TextParagraph = styled(Text)`
  display: block;
  margin-bottom: ${GridVars.margin.m2};
  text-align: justify;
  hyphens: auto;
  word-wrap: break-word;

  &:last-child {
    margin-bottom: 0px;
  }
`;

// Headers
export const TextTitle = styled(TextParagraph)`
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: ${GridVars.margin.m2};
`;

export const TextHeader = styled(TextParagraph).attrs({ $size: 'large' })`
  color: ${ThemePrimaryText};
  font-weight: 600;
  line-height: 1em;
  margin-bottom: ${GridVars.margin.m2};
  font-size: ${props => textSizes[props.$size || 'large']};
`

// Text labels & values
const labelSizes = {
  small: '0.7rem',
  large: '1.4rem'
}

export type TextLabelProps = {
  size?: keyof typeof labelSizes
}

export const TextLabel = styled(Text).attrs({ $weight: 'medium' })<TextLabelProps>`
  text-overflow: ellipsis;
  color: ${ThemeSecondaryText};
  ${props => props.size && `font-size: ${labelSizes[props.size]}`}
`;

export const TextValue = styled(TextLabel)`
  padding-left: ${GridVars.padding.p1};
  color: ${ThemePrimaryText};
`;
