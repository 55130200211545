import * as Styles from "../style";
import numbro from "numbro";
import { IconAltitude } from "../../../../../assets/images/iconComponents";
import * as PopoverStyles from "../../../../../components/custom-popover/styles";
import React, { useMemo, useState } from "react";
import { InventivesNetApy, InlineMargin } from "./style";
import { useIncentives } from "../../../../../hooks/useIncentives";
import PercentageFormatter from "../../../../../components/percentage-formatter";
import { formatEther } from "ethers/lib/utils";
import { IncentivesData } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { InfoPopover } from "../../../../../components";
import { useUserPosition } from "../../../../../hooks/useUserPosition";
import {useUser, useUserVaultInfo, useVault} from "../../../../../hooks/useAppSelector";
import { Icon, iconToSvg } from "../../../../../utils/icons.mapping";
import { useApyData } from "../../../../../hooks/useApi";
import { SetValuation } from "./set-valuation";
import { ElementMarginMedium, ElementInlineIconLeft, parseValue } from "../../../../../utils";
import { constants } from "ethers";
import { useLido, useIncludeLidoApr } from "../../../../../hooks/useLido";
import Toggle from "../../../../../components/toggle/toggle";

// use default collateral & debt if the user is new to show supply APY
export const useDefaultPosition = () => {
  const { isNewUser } = useUserVaultInfo();
  const { data: user } = useUser();
  const { data: vault } = useVault();
  const showDefault = useMemo(() => !user?.walletConnected || isNewUser, [isNewUser, user]);

  if (showDefault) {
    return [constants.Zero, parseValue('1', vault?.supplyDecimals)]
  }
  return [undefined, undefined];
}

type IncentivesAmountProps = {
  incentives: IncentivesData;
  showTooltip?: boolean;
}
const IncentivesAmount = ({ incentives, showTooltip = false }: IncentivesAmountProps) => {
  return incentives.apy
    ? <><PercentageFormatter percentage={incentives.apy} fractionDigits={2} /><span>%</span></>
    : <>
      {numbro(formatEther(incentives.tokensPerThousandDollars)).format({ mantissa: 2, thousandSeparated: true })}
      {showTooltip && (
        <InfoPopover  marginTop="0px" marginLeft="-100px">
          <PopoverStyles.Paragraph>
            Per day per $1,000 deposited
          </PopoverStyles.Paragraph>
        </InfoPopover>
      )}
    </>
}

type RatesAndRewardsProps = {
  onSet: () => void
}
const SupplyInfo = ({ onSet }: RatesAndRewardsProps) => {
  const incentives = useIncentives();
  const { data: vault } = useVault();
  const [defaultDebt, defaultCollateral] = useDefaultPosition();
  const { data: position } = useUserPosition(defaultDebt, defaultCollateral);
  const { data: apyData } = useApyData();
  const { lidoApr } = useLido();
  const [includeLidoApr, setIncludeLidoApr] = useIncludeLidoApr();
  const lenderStrategy = useMemo(() => vault && vault?.lenderStrategyInfo, [vault]);
  const farmStrategy = useMemo(() => vault && vault?.farmStrategyInfo, [vault]);
  const earningsApy = useMemo(() => position?.supply.earningsApy || 0, [position]);
  const supplyApy = useMemo(() => apyData?.data.apys.supplyApy || 0, [apyData]);
  return (
    <Styles.PopoverContent>
      <PopoverStyles.Header>Supply APY</PopoverStyles.Header>
      <PopoverStyles.Paragraph>Overview of rates, rewards and their impact on your supply APY.</PopoverStyles.Paragraph>
      <ElementMarginMedium />
      <PopoverStyles.Row>
        <PopoverStyles.RowLabel>
          <ElementInlineIconLeft>
            {lenderStrategy && iconToSvg(lenderStrategy.icon as Icon, { width: "18px", height: "18px" })}
          </ElementInlineIconLeft>
          <span>Supply Interest</span>
        </PopoverStyles.RowLabel>
        <PopoverStyles.RowValue>
          <PercentageFormatter percentage={supplyApy} fractionDigits={2}/><span>%</span>
        </PopoverStyles.RowValue>
      </PopoverStyles.Row>
      <Styles.RowHr />
      {earningsApy > 0 && (
        <>
          <PopoverStyles.Row>
            <PopoverStyles.RowLabel>
              <ElementInlineIconLeft>
                {farmStrategy && iconToSvg(farmStrategy.icon as Icon, { width: "18px", height: "18px" })}
              </ElementInlineIconLeft>
              <span>Altitude Earnings</span>
            </PopoverStyles.RowLabel>
            <PopoverStyles.RowValue>
              <PercentageFormatter percentage={earningsApy} fractionDigits={2}/><span>%</span>
            </PopoverStyles.RowValue>
          </PopoverStyles.Row>
          <Styles.RowHr />
        </>
      )}
      <PopoverStyles.Row>
        <PopoverStyles.RowLabel>
          <ElementInlineIconLeft>
            {iconToSvg('lido', { width: "18px", height: "18px" })}
          </ElementInlineIconLeft>
          <InlineMargin><span>Lido Staking Yield</span></InlineMargin>
          <Toggle value={includeLidoApr} onChange={setIncludeLidoApr} />
        </PopoverStyles.RowLabel>
        <PopoverStyles.RowValue>
          <PercentageFormatter percentage={lidoApr} fractionDigits={2}/><span>%</span>
        </PopoverStyles.RowValue>
      </PopoverStyles.Row>
      <Styles.RowHr />
      <PopoverStyles.Row>
        <PopoverStyles.RowLabel>
          <ElementInlineIconLeft>
            <IconAltitude width="18" height="18" />
          </ElementInlineIconLeft>
          <span>ALTI</span>
          <Styles.InlineButton onClick={onSet}>
            set
          </Styles.InlineButton>
        </PopoverStyles.RowLabel>
        <PopoverStyles.RowValue>
          <IncentivesAmount incentives={incentives} showTooltip />
        </PopoverStyles.RowValue>
      </PopoverStyles.Row>
      <Styles.RowHr />
      <PopoverStyles.Row>
        <PopoverStyles.RowLabel>
          <InventivesNetApy>Supply APY</InventivesNetApy>
        </PopoverStyles.RowLabel>
        <PopoverStyles.RowValue>
          <InventivesNetApy><PercentageFormatter percentage={position?.supply.supplyApy || 0} fractionDigits={2} /><span>%</span></InventivesNetApy>
        </PopoverStyles.RowValue>
      </PopoverStyles.Row>
    </Styles.PopoverContent>
  )
}

export const SupplyInfoContent = () => {
  const [showValuationInput, setShowValuationInput] = useState(false);
  return (
    showValuationInput
      ? <SetValuation
        onBack={() => setShowValuationInput(false)}
        onSubmit={() => setShowValuationInput(false)}
      />
      : <SupplyInfo
        onSet={() => setShowValuationInput(true)}
      />
  )
};

export const SupplyInfoPopover = () => (
  <InfoPopover>
    <SupplyInfoContent />
  </InfoPopover>
);
