import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { NumberFormatValues, SourceInfo } from "react-number-format";
import * as FormStyles from "../../utils";
import { getTokenLogoComponent } from "../../utils/icons.mapping";
import { BigNumber, constants } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { parseValue } from "../../utils";
import numbro from "numbro";
import { useAnalytics } from "../../hooks/useAnalytics";
import {TokenName} from "@altitude-fi/altitude-v1-sdk/dist/types";

export type ValidatorFunction = (value: string | undefined) => string;

interface TokenInputProps {
  name: string;
  label: string;
  error?: string;
  warning?: string;
  decimals?: number;
  precision?: number;
  balanceLabel?: string;
  balanceValue?: BigNumber;
  maxLabel?: string;
  maxValue: BigNumber;
  tokenName: string;
  currencyExchangeRate?: number;
  defaultValue?: string;
  onValueChange: (value?: BigNumber) => void;
  validator?: ValidatorFunction;
  maxClicked?: () => void;
}

const defaultValidator = (value: string | undefined) => {
  throw new Error("Validator did not settled!");
};

const TokenInput = ({
  name,
  label,
  defaultValue,
  tokenName,
  decimals = 18,
  precision = 2,
  error = "",
  warning = "",
  onValueChange,
  maxValue,
  maxLabel = "max",
  balanceLabel = "balance",
  balanceValue = maxValue,
  maxClicked = () => {},
  currencyExchangeRate = 1,
  validator = defaultValidator,
}: TokenInputProps) => {
  const inputRef = useRef<HTMLElement>();
  const { track } = useAnalytics();

  const [value, setValue] = useState(defaultValue);
  const [maxTrack, setMaxTrack] = useState<boolean>(false);
  const [estimatedPrice, setEstimatedPrice] = useState<BigNumber>(constants.Zero);
  const [internalError, setInternalError] = useState<string>("");

  const balanceValueFormatted = useMemo(() => formatUnits(balanceValue, decimals), [balanceValue, decimals]);
  const maxValueFormatted = useMemo(() => formatUnits(maxValue, decimals), [maxValue, decimals]);
  const currencyExchangeBn = useMemo(() => parseValue(currencyExchangeRate), [currencyExchangeRate]);

  const handleChange = useCallback(({ value }: NumberFormatValues, { source }: SourceInfo) => {
    if (source === 'event') {
      setMaxTrack(false);
    }
    setInternalError("");
    setValue(value)
    const valueBn = parseValue(value, decimals);
    onValueChange(value ? valueBn : undefined);
    setEstimatedPrice(valueBn.mul(currencyExchangeBn).div(constants.WeiPerEther));
    setInternalError(validator(value));

  }, [currencyExchangeBn, decimals, onValueChange, validator]);

  const formContainerClick = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.focus();
  }, [inputRef]);

  const maxHandler = useCallback(() => {
    track('max-clicked', { input: name, balanceValue: balanceValueFormatted, maxValue: maxValueFormatted });
    setMaxTrack(true);
  },[track, name, balanceValueFormatted, maxValueFormatted]);

  useEffect(() => {
    if (maxTrack) {
      setValue(maxValueFormatted)
    }
  }, [maxTrack, maxValueFormatted]);

  const IconComponent = getTokenLogoComponent(tokenName as TokenName);
  return (
    <>
      <FormStyles.FormLabel data-testid="tokenInputLabel">
        <FormStyles.FormLabelLeft>{label}</FormStyles.FormLabelLeft>
        {maxLabel.length > 0 && (
          <FormStyles.FormLabelRight>
            <FormStyles.FormLabelBalance>
              {balanceLabel}: {numbro(balanceValueFormatted).format({ mantissa: precision, thousandSeparated: true })}
            </FormStyles.FormLabelBalance>
            <FormStyles.FormLabelMax onClick={maxHandler}>
              {maxLabel}
            </FormStyles.FormLabelMax>
          </FormStyles.FormLabelRight>
        )}
      </FormStyles.FormLabel>
      <FormStyles.FormInputContainer
        data-testid="token-input-container"
        error={error || internalError}
        warning={warning}
        onClick={formContainerClick}
      >
        <FormStyles.InputTokenLogo>
          <IconComponent />
          <span>{tokenName}</span>
        </FormStyles.InputTokenLogo>
        <FormStyles.FormInputWrapper>
          <FormStyles.FormInputField
            id={name}
            name={name}
            value={value}
            placeholder="0"
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={decimals} // default is 18
            onValueChange={handleChange}
            getInputRef={inputRef}
          />
          <FormStyles.FormFieldText
            prefix="~ $"
            displayType={"text"}
            thousandSeparator={true}
            value={formatUnits(estimatedPrice, decimals)}
            decimalScale={2}
          />
        </FormStyles.FormInputWrapper>
      </FormStyles.FormInputContainer>
      {internalError.length > 0 && (
        <FormStyles.FormHelperText $variant="error">{internalError}</FormStyles.FormHelperText>
      )}
      {warning.length > 0 && (
        <FormStyles.FormHelperText $variant="warning">{warning}</FormStyles.FormHelperText>
      )}
    </>
  );
};

export default TokenInput;
