import React from "react";
import { useCallback, useMemo, useState } from "react";

import { TokenInput } from "../../../../../components";
import { useAppDispatch } from "../../../../../hooks";
import { useAppSelector } from "../../../../../hooks";
import {
  UserVaultModel,
  VaultModel,
} from "../../../../../models";
import {
  BarChart,
  borrowInputValidator,
  StatisticsRow,
  ModalStyles,
} from "../../../../../modules/dashboard/modals";
import { depositAndBorrow } from "../../../../../store/userVault/actions";
import { selectUserVault } from "../../../../../store/userVault/userVault.slice";
import { selectVault } from "../../../../../store/vault/vault.slice";
import { useAnalytics } from "../../../../../hooks/useAnalytics";
import { UserPosition, useUserPosition } from "../../../../../hooks/useUserPosition";
import { useUser } from "../../../../../hooks/useAppSelector";
import ReadOnlyWarning from "../../components/read-only-warning";
import { formatUnits } from "ethers/lib/utils";
import { ValidatorFunction } from "../../../../../components/token-input/token-input";
import { parseValue } from "../../../../../utils";
import { BigNumber, constants } from "ethers";
import * as Styles from "../../styles";
import { useMaxBorrowAmount } from "../../calculation.logic";
import { RateLimit, useVaultRateLimits } from "../../../../../hooks/useVaultRateLimits";

export const BorrowTab = () => {
  const dispatch = useAppDispatch();
  const { track } = useAnalytics();
  const { data: { isReadOnly } = {} } = useUser();

  const vaultStore = useAppSelector((state) => state.vault);
  const userVaultStore = useAppSelector((state) => state.userVault);
  const { selectedVault } = useAppSelector((state) => state.global);

  const {
    borrowToUsdRatio,
    supplyThreshold,
    liquidationThreshold,
    borrowSymbol,
    supplySymbol,
    borrowDecimals,
    borrowPrecision
  }: VaultModel = selectVault(vaultStore, selectedVault) || ({} as VaultModel);
  const {
    debtTokenAmount: debtTokenAmountNumber,
    debtTokenAmountBn: debtTokenAmount,
    collateralTokenAmountBn: collateralTokenAmount,
    collateralTokenAmount: collateralTokenAmountNumber,
  }: UserVaultModel =
    selectUserVault(userVaultStore, selectedVault) || ({} as UserVaultModel);

  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const [borrowAmount, setBorrowAmount] = useState<BigNumber>(constants.Zero);
  const newDebtAmount = debtTokenAmount.add(borrowAmount);
  const { data: currentPosition = {} } = useUserPosition();
  const {
    loan: { loanApy },
    current: { borrowLimit, loanToValue, liquidationLimit },
  } = currentPosition as UserPosition;

  const { data: futurePosition } = useUserPosition(newDebtAmount, collateralTokenAmount);
  const {
    loan: { loanApy: futureLoanAPY },
    current: {
      loanToValue: futureLoanToValue,
    }
  } = futurePosition as UserPosition;

  const borrowHandler = useCallback(() => {
    track('click-borrow', { borrowAmount, selectedVault })
    dispatch(depositAndBorrow({ borrow: borrowAmount, selectedVault }));
  }, [borrowAmount, dispatch, selectedVault, track])

  const inputChangeHandler = useCallback((value?: BigNumber) => {
    setIsTouched(!!value);
    setBorrowAmount(value || constants.Zero);
  }, []);
  const borrowLimitBn = useMemo(() => parseValue(borrowLimit, borrowDecimals), [borrowLimit, borrowDecimals]);
  const maximumBorrowAmountBn = useMaxBorrowAmount(borrowLimitBn, debtTokenAmount);

  const isBorrowButtonDisabled = useMemo(() => isReadOnly || !isValid || borrowAmount.lte(0), [borrowAmount, isReadOnly, isValid])

  const { data: rateLimits } = useVaultRateLimits();
  const borrowRateLimit = useMemo(() => rateLimits && rateLimits[RateLimit.borrow], [rateLimits]);
  const borrowValidator = useCallback<ValidatorFunction>(
    (value) => {
      const valueBn = parseValue(value, borrowDecimals);
      const error = borrowInputValidator(valueBn, maximumBorrowAmountBn, borrowRateLimit);
      setIsValid(!error);
      return error;
    },
    [borrowDecimals, maximumBorrowAmountBn, borrowRateLimit]
  );

  return (
    <ModalStyles.TabWrapper data-testid="borrow-tab">
      <ModalStyles.AmountContainer
        data-testid={borrowSymbol + "AmountTokenInput"}
      >
        <TokenInput
          label={"Borrow Amount"}
          tokenName={borrowSymbol}
          decimals={borrowDecimals}
          precision={borrowPrecision}
          balanceLabel="borrow limit"
          name={borrowSymbol + "Amount"}
          currencyExchangeRate={borrowToUsdRatio}
          validator={borrowValidator}
          maxValue={maximumBorrowAmountBn}
          onValueChange={inputChangeHandler}
        />
      </ModalStyles.AmountContainer>
      <ModalStyles.StatisticsContainer>
        <BarChart
          to={futureLoanToValue}
          from={loanToValue}
          borrowLimit={borrowLimit}
          collateral={collateralTokenAmountNumber}
          balance={+formatUnits(newDebtAmount, borrowDecimals)}
          liquidation={liquidationLimit}
          liquidationThreshold={liquidationThreshold}
          supplyThreshold={supplyThreshold}
          borrowToUsdRatio={borrowToUsdRatio}
          borrowName={borrowSymbol}
          supplySymbol={supplySymbol}
        />
        <Styles.StatisticsRowDivider />
        <StatisticsRow
          touched={isTouched}
          label={"Borrowed Amount"}
          from={debtTokenAmountNumber}
          to={+formatUnits(newDebtAmount, borrowDecimals)}
          prefix={borrowSymbol + " "}
        />
        <StatisticsRow
          touched={isTouched}
          label={"Loan APY"}
          from={loanApy || 0}
          to={futureLoanAPY}
          postfix={"%"}
          prefix={""}
        />
        <ModalStyles.TakeLoanBtn
          onClick={() => borrowHandler()}
          disabled={isBorrowButtonDisabled}
          data-testid="borrow-action-btn"
        >
          Borrow
        </ModalStyles.TakeLoanBtn>
        <ReadOnlyWarning />
      </ModalStyles.StatisticsContainer>
    </ModalStyles.TabWrapper>
  );
};
