import * as Styles from "../../components/style";
import { useMemo } from "react";
import { getTokenLogoComponent } from "../../../../utils/icons.mapping";
import TokenBalance from "../../../token-balance";
import { useVault } from "../../../../hooks/useAppSelector";
import { TransactionMode, useTransactionMode } from "../../../../hooks/useTrasactionMode";

const Actions = () => {
  const { actions, transactionMode } = useTransactionMode();
  const { data: vault } = useVault();
  const isInactive = useMemo(
    () =>
      transactionMode === TransactionMode.confirmed ||
      transactionMode === TransactionMode.failed,
    [transactionMode]
  );
  return (
    <Styles.ActionsContainer>
      {actions.map((action) => {
        const IconComponent = getTokenLogoComponent(action.tokenName);
        return (
          <Styles.ActionContainer key={action.label}>
            <Styles.ActionDescription>
              <Styles.ActionLabel>{action.label}</Styles.ActionLabel>
              <Styles.ActionValue $size={isInactive ? "large" : "mediumLarge"}>
                <TokenBalance
                  backLabel
                  amount={action.value}
                  vaultType={vault?.id || ""}
                  displayType={action.displayType}
                />
              </Styles.ActionValue>
            </Styles.ActionDescription>
            <Styles.ActionIcon>
              {IconComponent && <IconComponent inactive={isInactive} />}
            </Styles.ActionIcon>
          </Styles.ActionContainer>
        );
      })}
    </Styles.ActionsContainer>
  );
};

export default Actions;
