import { IconProps } from "../../../../assets/images/iconComponents";
const TransactionInAndOut = ({ inactive, ...rest }: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="12" cy="12" r="12" fill={inactive ? '#606060' : 'white'} />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M16.1517 5.57661C16.6615 5.55941 17.0657 5.96282 17.0851 6.46951L17.085 13.4164C17.085 13.7103 16.9473 13.9875 16.7126 14.1663C16.3791 14.4202 15.9175 14.4265 15.5775 14.1816L15.5643 14.1721C15.3122 13.9905 15.1629 13.6998 15.1629 13.3902L15.1627 6.46951C15.1433 5.96282 15.6419 5.59381 16.1517 5.57661Z"
            fill={inactive ? '#A5A5A5' : 'black'}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12.539 10.0976C12.1356 9.69422 12.1486 9.02714 12.5681 8.60767L15.4163 5.75946C15.8358 5.33999 16.5029 5.32697 16.9063 5.73039L19.4629 8.28697C19.8663 8.69039 19.8533 9.35748 19.4338 9.77695C19.0143 10.1964 18.3473 10.2094 17.9438 9.80602L16.1177 7.97989L14.029 10.0686C13.6095 10.488 12.9425 10.5011 12.539 10.0976Z"
            fill={inactive ? '#A5A5A5' : 'black'}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M19.8535 16.358C19.8535 16.9887 19.3422 17.5 18.7115 17.5L13.4911 17.5C12.8604 17.5 12.3492 16.9887 12.3492 16.358C12.3492 15.7273 12.8604 15.2161 13.4911 15.2161L18.7115 15.2161C19.3422 15.2161 19.8535 15.7273 19.8535 16.358Z"
            fill={inactive ? '#A5A5A5' : '#4F8BFF'}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M7.84437 17.2593C7.33455 17.2765 6.93038 16.8731 6.91095 16.3664L6.91107 9.41957C6.91108 9.12566 7.04875 8.84845 7.28351 8.66966C7.61698 8.4157 8.07855 8.40948 8.41861 8.65436L8.43176 8.66384C8.68392 8.84543 8.8332 9.13618 8.83321 9.44577L8.83341 16.3664C8.85284 16.8731 8.35419 17.2421 7.84437 17.2593Z"
            fill={inactive ? '#A5A5A5' : 'black'}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.457 12.7344C11.8605 13.1378 11.8475 13.8049 11.428 14.2244L8.57977 17.0726C8.1603 17.492 7.49321 17.5051 7.08979 17.1016L4.53321 14.5451C4.12979 14.1416 4.14281 13.4746 4.56228 13.0551C4.98175 12.6356 5.64884 12.6226 6.05225 13.026L7.87839 14.8521L9.96707 12.7635C10.3865 12.344 11.0536 12.331 11.457 12.7344Z"
            fill={inactive ? '#A5A5A5' : 'black'}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4 6.14197C4 5.51128 4.51128 5 5.14197 5H10.3624C10.9931 5 11.5044 5.51128 11.5044 6.14197C11.5044 6.77266 10.9931 7.28393 10.3624 7.28393H5.14197C4.51128 7.28393 4 6.77266 4 6.14197Z"
            fill={inactive ? '#A5A5A5' : '#4F8BFF'}/>
  </svg>
);

export default TransactionInAndOut;
