import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { UserVaultModel } from "../../models";
import {
  fetchUserVaultData,
} from "./actions";

const userVaultAdapter = createEntityAdapter<UserVaultModel>({
  selectId: (userVault) => userVault.id,
});

const userVaultSlice = createSlice({
  name: "userVault",
  initialState: {
    ...userVaultAdapter.getInitialState(),
    userVaultLoading: false
  },
  reducers: {
    userVaultSet: userVaultAdapter.setOne,
    userVaultUpdate: userVaultAdapter.updateOne,
    userVaultClear: userVaultAdapter.removeAll,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserVaultData.pending, (state) => {
        state.userVaultLoading = true;
      })
      .addCase(
        fetchUserVaultData.fulfilled,
        (state, action: PayloadAction<UserVaultModel[]>) => {
          state.userVaultLoading = false;
          userVaultAdapter.setAll(state, action.payload);
        }
      )
      .addCase(
        fetchUserVaultData.rejected,
        (state, action: PayloadAction<any>) => {
          state.userVaultLoading = false;
        }
      )
  },
});

// Selectors
const { selectAll, selectById } = userVaultAdapter.getSelectors();
export const selectAllUserVaults = selectAll;
export const selectUserVault = selectById;

export default userVaultSlice;
