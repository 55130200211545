import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { getAccount, getBlock, getChainId } from '@wagmi/core'
import { NETWORKS } from "../../utils";
import { sdk } from "../sdk-provider";
import { Contract, EventFilter } from "ethers";
import { wagmiConfig } from "../../config";

const QUERY_BLOCK_LIMIT = 500;

export const getBlocksInfo = async (blockNumbers: Array<any>): Promise<Array<any>> => {
  const res: any = [];
  for (const blockNumber of blockNumbers) {
    res.push(await getBlock(wagmiConfig, {
      blockNumber
    }));
  }

  return res;
};
export const getNetworkName = (): string => {
  const network = getChainId(wagmiConfig);

  // TODO return enum name here
  switch (network) {
    case NETWORKS.mainnetfork:
      return "mainnetfork";
    case NETWORKS.localhost:
      return "localhost";
    case NETWORKS.mainnet:
    default:
      return "mainnet";
  }
};

export const getEventsFromContract = async (
  contractWithEvents: { contract: Contract, events: EventFilter[] }[],
  startBlock: string,
  endBlock: string
) => {
  const queriedEvents = [];
  for (const contractInfo of contractWithEvents) {
    const { contract, events } = contractInfo

    for (const event of events) {
      const newEvents = await contract.queryFilter(event, Math.max(Number(startBlock), Number(endBlock) - QUERY_BLOCK_LIMIT), endBlock);
      queriedEvents.push(...newEvents);
    }
  }

  return queriedEvents.sort((a: any, b: any) => (a.blockNumber > b.blockNumber ? 1 : -1));
};

export const isNativeToken = (symbol: string) => {
  const { chain } = getAccount(wagmiConfig);
  const defaultTokenSymbol = 'ETH';
  const nativeTokenSymbol = chain ? chain.nativeCurrency.symbol : defaultTokenSymbol;
  return symbol.toUpperCase() === nativeTokenSymbol;
};

export const isExistingVault = (vaultType: VaultName) => {
  if (!(vaultType in sdk.contracts.vaults)) {
    throw new Error("not implemented vault");
  }
};
