import { createRef, JSX, useState } from "react";

import { MenuDots } from "../../assets/images/iconComponents";
import { useOutside } from "../../hooks";

import * as Styles from "./style";
import { AnimationDurationMs } from "../../utils";

interface DropDownMenuProps {
  items: { label: string; disabled: boolean; callback: () => void }[];
  button?: (toggle: () => void, isOpen: boolean) => JSX.Element;
}

export const DropDownMenu = ({ items, button }: DropDownMenuProps) => {
  const ref = createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);

  const toggle = () => isOpen ? handleClose() : handleOpen();

  const handleClose = () => {
    setContentVisible(false);
    setTimeout(() => {
      setIsOpen(false);
    }, AnimationDurationMs.short); // Duration of fadeOut animation
  };

  const handleOpen = () => {
    setIsOpen(true);
    setContentVisible(true);
  };

  useOutside(ref, () => handleClose());

  return (
    <Styles.DropDownMenuContainer ref={ref}>
      {button ? button(toggle, isOpen) : (
        <Styles.DropDownMenuIcon onClick={toggle}>
          <MenuDots />
        </Styles.DropDownMenuIcon>
      )}
      <Styles.DropDownMenuWrapper $show={contentVisible}>
        {items.map((item, index) => (
          <Styles.DropDownMenuItem
            disabled={item.disabled}
            key={`dropDownMenu${index}`}
            data-testid={`drop-down-menu-${item.label.replace(' ', '-').toLowerCase()}`}
            onClick={() => item.callback()}
          >
            {item.label}
          </Styles.DropDownMenuItem>
        ))}
      </Styles.DropDownMenuWrapper>
    </Styles.DropDownMenuContainer>
  );
};
