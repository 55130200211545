import { useCallback, useMemo } from "react";
import React from "react";

import {
  LoaderInline,
  PercentageFormatter,
} from "../../../../components";

import * as Styles from "./style";
import { useUserPosition } from "../../../../hooks/useUserPosition";
import { useUserVaultInfo, useVault } from "../../../../hooks/useAppSelector";
import { IncentivesPopover, SupplyInfoPopover, LoanInfoPopover, RepayingButton } from "./components";
import { useDefaultPosition } from "./components/supply-info-popover";
import { useBackgroundLoading } from "../../../../hooks/useBackgroundLoading";

const UserStats = () => {
  const {
    data: vault,
    isLoading: vaultLoading
  } = useVault();
  const { data: userVault, isLoading: userVaultLoading } = useUserVaultInfo();
  const [defaultDebt, defaultCollateral] = useDefaultPosition();
  const { data: userPosition, isLoading: userPositionLoading } = useUserPosition(defaultDebt, defaultCollateral);
  const ltv = useMemo(() => userPosition?.current.loanToValue || 0, [userPosition]);
  const supplyApy = useMemo(() => userPosition?.supply.supplyApy || 0, [userPosition]);
  const loanApy = useMemo(() => userPosition?.loan.loanApy || 0, [userPosition]);

  const isDataLoading = useMemo(() => (
      vaultLoading ||
      userVaultLoading ||
      userPositionLoading
  ), [
    vaultLoading,
    userVaultLoading,
    userPositionLoading,
  ]);

  const isLoading = useBackgroundLoading(isDataLoading);

  const renderLoanApy = useCallback(() => {
    if (isLoading) {
      return <StatLoaderDouble />;
    }
    if (!userPosition || !vault) {
      return (
        <>
          <PercentageFormatter percentage={0} fractionDigits={2} />
          <span>%</span>
        </>
      );
    }

    return (
      <>
        <PercentageFormatter percentage={loanApy} fractionDigits={2} />
        <span>%</span>
      </>
    );
  }, [userPosition, vault, isLoading, loanApy]);
  const repayingButton = useCallback(() => {
    if (!userVault || !vault || !userPosition || isLoading) {
      return "";
    }

    return (
      <Styles.SubStatWrapper>
        <Styles.PillButton>
          <RepayingButton
            userPosition={userPosition}
            userLTV={ltv / (vault.supplyBasePrice || 1)}
            targetLTV={vault.targetThreshold}
            supplyThreshold={vault.supplyThreshold}
            liquidationThreshold={vault.liquidationThreshold}
          />
        </Styles.PillButton>
      </Styles.SubStatWrapper>
    );
  }, [userVault, vault, userPosition, ltv, isLoading]);

  return (
    <Styles.UserStatContainer>
      <Styles.UserStatBox>
        <Styles.UserStatBoxItem>
          <Styles.UserStatBoxHeader color="purpleBeta">
            <Styles.BoxHeaderSquare color="purpleBeta" />
            <span>Supply APY</span>
            <SupplyInfoPopover />
          </Styles.UserStatBoxHeader>
          {!isLoading
            ? (
              <Styles.UserStatBoxValueWrapper>
                <Styles.UserStatBoxValue>
                  <Styles.EllipsisWrapper>
                    <PercentageFormatter
                      percentage={supplyApy}
                      fractionDigits={2}
                    />
                  </Styles.EllipsisWrapper>
                  <Styles.EllipsisWrapper>%</Styles.EllipsisWrapper>
                </Styles.UserStatBoxValue>
                <Styles.SubStatWrapper>
                  <IncentivesPopover />
                </Styles.SubStatWrapper>
              </Styles.UserStatBoxValueWrapper>
            ) : (
              <StatLoaderDouble />
            )
          }
        </Styles.UserStatBoxItem>
        <Styles.UserStatBoxItem>
          <Styles.BoxHeader color="yellowBeta">
            <Styles.BoxHeaderSquare color="yellowBeta" />
            <span>Loan APY</span>
            <LoanInfoPopover />
          </Styles.BoxHeader>
          <Styles.UserStatBoxValueWrapper>
            <Styles.UserStatBoxValue>
              {renderLoanApy()}
            </Styles.UserStatBoxValue>
            {repayingButton()}
          </Styles.UserStatBoxValueWrapper>
        </Styles.UserStatBoxItem>
      </Styles.UserStatBox>
    </Styles.UserStatContainer>
  );
};

const StatLoaderDouble = () => {
  const rects = [
    {
      y: 0,
      x: 0,
      rx: 3,
      ry: 3,
      width: 80,
      height: 25,
    },
    {
      y: 36,
      x: 0,
      rx: 3,
      ry: 3,
      width: 100,
      height: 15,
    },
  ];
  return <LoaderInline height={55} loaderSettings={rects} />;
};

export default UserStats;
