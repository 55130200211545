import styled from "styled-components";
import {
  ThemeGray500,
  GridVars,
  ThemePrimaryText,
} from "../../utils";
import {Button} from "../../utils/styles/button";
import {AnimationFadeIn, AnimationFadeOut, AnimationDuration} from "../../utils/styles/animation.styles";

interface DropDownMenuWrapperProps {
  readonly $show: boolean;
}

export const DropDownMenuContainer = styled.div`
  position: relative;
  width: 100%;
`

export const DropDownMenuIcon = styled.div`
  color: ${ThemePrimaryText};
  display: block;
  font-size: 2.4rem;
  position: absolute;
  top: -${GridVars.padding.p1};
  right: -${GridVars.padding.p1};
  cursor: pointer;
`;

export const DropDownMenuWrapper = styled.div<DropDownMenuWrapperProps>`
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  padding: ${GridVars.padding.p1};
  position: absolute;
  top: 0px;
  transform: translateY(-100%);
  min-width: 165px;
  right: 0;
  border-radius: 4px;
  background: ${ThemeGray500};
  box-shadow: 0 2px 8px 0 #00000020;

  visibility: ${(props) => (props.$show ? "visible" : "hidden")};
  transition-property: visibility;
  transition-delay:  ${(props) => (props.$show ? "0s" : AnimationDuration.short)};
  transition-duration: 0s;
  ${props => props.$show ? AnimationFadeIn: AnimationFadeOut};
`;

export const DropDownMenuItem = styled(Button).attrs({
  $variant: 'menu',
  $size: 'compact'
})`

`;

