import { createAsyncThunk } from "@reduxjs/toolkit";
import * as store from "../../../store";
import { selectUser } from "../../../store/user/user.slice";
import { selectVault } from "../../../store/vault/vault.slice";
import { sdk } from "../../../services/sdk-provider";

import { fetchUserVaultData } from "./fetchUserVaults";
import {TokenName, VaultName} from "@altitude-fi/altitude-v1-sdk/dist/types";
import { isNativeToken } from "../../../services/contracts/base";
import { BigNumber} from "ethers";
import { TransactionFlowPayload, TransactionType } from "../../transaction/transaction.slice";
import { addTransactionFlow, startTransactionFlow } from "../../../store";

interface RepayPayload {
  repay: BigNumber;
  selectedVault: VaultName;
}

export const repay = createAsyncThunk<void, RepayPayload>(
  "userVault/repay",
  async ({ repay, selectedVault }: RepayPayload, thunkApi) => {
    const { vault, user } = store.default.getState();
    const { userAddress }: any = selectUser(user);
    const { borrowSymbol, borrowName }: any = selectVault(vault, selectedVault) || {};

    const txSendArgs: any = {
      from: userAddress,
    };

    const vaultContract = sdk.contracts.vaults[selectedVault].vault;
    const tokenContract = sdk.contracts.tokens[borrowName as TokenName];

    const transactionFlow: TransactionFlowPayload = [];
    try {
      if (isNativeToken(borrowSymbol)) {
        txSendArgs.value = repay;
      } else {
        const currentAllowance = await tokenContract.allowance(userAddress, vaultContract.address);
        if (currentAllowance.lt(repay)) {
          transactionFlow.push({
            id: `approve-${borrowName}`,
            index: 0,
            type: TransactionType.APPROVAL,
            info: {
              token: borrowName,
              amount: repay,
              spender: vaultContract.address,
              owner: userAddress
            }
          });
        }
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }

    try {
      transactionFlow.push({
        id: `repay-${selectedVault}-${borrowName}`,
        index: transactionFlow.length,
        type: TransactionType.REPAY,
        info: {
          vault: selectedVault,
          borrowToken: borrowName,
          repayAmount: repay,
          userAddress,
        },
      });

      thunkApi.dispatch(addTransactionFlow(transactionFlow));
      thunkApi.dispatch(startTransactionFlow());
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }

    thunkApi.dispatch(fetchUserVaultData({ selectedVault }));
  }
);
