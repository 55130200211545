import InfoButton from "./info-button";
import CustomPopover from "../../components/custom-popover";

interface InfoPopoverProps {
  children: React.ReactNode;
  marginLeft?: string;
  marginTop?: string;
}

export const InfoPopover = ({ children, marginTop = "0px", marginLeft = "-20px" }: InfoPopoverProps) => {
  return (
    <CustomPopover marginTop={marginTop} marginLeft={marginLeft} button={<InfoButton />}>
      {children}
    </CustomPopover>
  );
};
