import { createAsyncThunk } from "@reduxjs/toolkit";
import { VaultModel } from "../../../models";
import { toastAdd, toastRemove } from "../../../store";

import { sdk } from "../../../services/sdk-provider";
import { TokenName, VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { strategyInfos } from "../../../models/vault";
import { isDevelopment, parseValue, getSymbolFromName } from "../../../utils";
import { captureMessage } from "@sentry/react";

const vaultConfigs: { [key in VaultName]: VaultConfig } = {
  wstethUsdc: {
    vaultType: "wstethUsdc",
    supplyName: "wsteth",
    supplyDecimals: 18,
    supplyPrecision: 5,
    borrowName: "usdc",
    borrowDecimals: 6,
    borrowPrecision: 2,
    incentiveName: "alti",
    incentiveDecimals: 18,
    incentivePrecision: 5,
  }
}

export const fetchVaultsData = createAsyncThunk("vault/fetchVaultsData", async (data, thunkApi) => {
  try {
    const vaultModels: VaultModel[] = [];
    for (const vaultName of Object.keys(vaultConfigs) as VaultName[]) {
      const vaultConfig = vaultConfigs[vaultName];
      const vaultModel = await getVaultByConfig(vaultConfig);
      vaultModels.push(vaultModel);
    }

    thunkApi.dispatch(toastRemove('error-fetching-vaults-data'))
    return vaultModels;
  } catch (error: any) {
    console.log(error)
    if (!isDevelopment()) {
      captureMessage(error)
    } else {
      thunkApi.dispatch(
        toastAdd({
          id: "error-fetching-vaults-data",
          message: "Error fetching data",
          type: "error",
        })
      );
    }
    const message = error.message;
    return thunkApi.rejectWithValue(message);
  }
});

type VaultConfig = {
  vaultType: VaultName
  supplyName: TokenName
  supplyDecimals: number
  supplyPrecision: number
  borrowName: TokenName
  borrowDecimals: number
  borrowPrecision: number
  incentiveName: TokenName
  incentiveDecimals: number
  incentivePrecision: number
}

const getVaultByConfig = async (vaultConfig: VaultConfig): Promise<VaultModel> => {
  const vault = sdk.multi.vaults[vaultConfig.vaultType];
  const customMinThreshold = parseValue(process.env.REACT_APP_MIN_THRESHOLD);
  const [
    activeLenderStrategy,
    activeFarmStrategy,
    supplyThreshold,
    liquidationThreshold,
    maxTargetThreshold,
    minTargetThreshold,
    targetThreshold,
    [,reserveFactor],
    harvestsCount,
    userMinDepositLimit,
    userMaxDepositLimit,
    vaultDepositLimit,
    protocolPaused,
    [,,liquidationBonus],
    supplyTokenAddress,
    borrowTokenAddress
  ] = await sdk.getMultiData([
    vault.vault.activeLenderStrategy(),
    vault.vault.activeFarmStrategy(),
    vault.vault.supplyThreshold(),
    vault.vault.liquidationThreshold(),
    vault.rebalanceIncentivesController.maxThreshold(),
    customMinThreshold.gt(0) ? Promise.resolve(customMinThreshold) : vault.rebalanceIncentivesController.minThreshold(),
    vault.vault.targetThreshold(),
    vault.vault.getSnapshotableConfig(),
    vault.vault.getHarvestsCount(),
    vault.ingressControl.userMinDepositLimit(),
    vault.ingressControl.userMaxDepositLimit(),
    vault.ingressControl.vaultDepositLimit(),
    vault.ingressControl.pause(),
    vault.vault.getLiquidationConfig(),
    vault.supplyToken.underlying(),
    vault.borrowToken.underlying()
  ]);

  const activeLendingStrategyContract = vault.lendingStrategies.find(strategy => strategy.address === activeLenderStrategy)
  const activeFarmStrategyContract = vault.farmingStrategies.find(strategy => strategy.address === activeFarmStrategy)
  if (!activeLendingStrategyContract) {
    throw new Error(`Active lending strategy ${activeLenderStrategy} was not found`)
  }
  if (!activeFarmStrategyContract) {
    throw new Error(`Active farm strategy ${activeFarmStrategy} was not found`)
  }

  const [supplyBasePrice, activeLendingPool, borrowToUsdRatio, usdDecimals, activeFarmPool] =
    await sdk.getMultiData([
      activeLendingStrategyContract.getInBase(supplyTokenAddress, borrowTokenAddress),
      activeLendingStrategyContract.address,
      sdk.multi.custom.chainlinkAggregator.latestRoundData(),
      sdk.multi.custom.chainlinkAggregator.decimals(),
      activeFarmStrategyContract.address,
    ]);

  const res: VaultModel = {
    id: vaultConfig.vaultType,

    supplyName: vaultConfig.supplyName,
    borrowName: vaultConfig.borrowName,
    vaultDecimals: {
      supply: 10 ** (vaultConfig.supplyDecimals || 0),
      borrow: 10 ** (vaultConfig.borrowDecimals || 0),
    },
    supplyDecimals: vaultConfig.supplyDecimals,
    borrowDecimals: vaultConfig.borrowDecimals,
    supplyPrecision: vaultConfig.supplyPrecision,
    borrowPrecision: vaultConfig.borrowPrecision,
    activeLenderStrategy,
    activeFarmStrategy,
    supplyTokenAddress,
    borrowTokenAddress,
    supplyBasePrice: sdk.utils.convertFromWei(supplyBasePrice, vaultConfig.borrowDecimals),
    supplyBasePriceBn: supplyBasePrice,
    borrowToUsdRatio: sdk.utils.convertFromWei(borrowToUsdRatio[1], usdDecimals),
    supplyThreshold: sdk.utils.convertFromWei(supplyThreshold, vaultConfig.supplyDecimals),
    supplyThresholdBn: supplyThreshold,
    maxTargetThreshold: sdk.utils.convertFromWei(maxTargetThreshold, vaultConfig.supplyDecimals),
    minTargetThreshold: sdk.utils.convertFromWei(minTargetThreshold, vaultConfig.supplyDecimals),
    targetThreshold: sdk.utils.convertFromWei(targetThreshold, vaultConfig.supplyDecimals),
    liquidationThreshold: sdk.utils.convertFromWei(
      liquidationThreshold,
      vaultConfig.supplyDecimals
    ),
    liquidationThresholdBn: liquidationThreshold,
    liquidationBonus: sdk.utils.convertFromWei(liquidationBonus, vaultConfig.supplyDecimals),
    reserveFactor: sdk.utils.convertFromWei(reserveFactor, vaultConfig.supplyDecimals),
    harvestsCount: +harvestsCount,

    userMinDepositLimit: sdk.utils.convertFromWei(userMinDepositLimit, vaultConfig.supplyDecimals),
    userMaxDepositLimit: sdk.utils.convertFromWei(userMaxDepositLimit, vaultConfig.supplyDecimals),
    vaultDepositLimit: sdk.utils.convertFromWei(vaultDepositLimit, vaultConfig.supplyDecimals),
    protocolPaused: protocolPaused,

    lenderStrategyInfo: strategyInfos[activeLendingPool],
    farmStrategyInfo: strategyInfos[activeFarmPool],

    supplySymbol: getSymbolFromName(vaultConfig.supplyName),
    borrowSymbol: getSymbolFromName(vaultConfig.borrowName),
    incentiveSymbol: getSymbolFromName(vaultConfig.incentiveName),
  };

  return res;
};
