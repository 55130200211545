import { IconProps } from "../../../../assets/images/iconComponents";
const TransactionClaim = ({ inactive, ...rest }: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="12" cy="12" r="12" fill={inactive ? '#606060' : 'white'} />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M16.327 6.32875C16.8981 6.86255 16.8989 7.73764 16.3709 8.30775L10.2574 14.421C9.93891 14.7395 9.48935 14.8907 9.04124 14.8301C8.40469 14.7439 7.89779 14.2505 7.79466 13.6166L7.79067 13.5921C7.7142 13.1221 7.86751 12.6453 8.20296 12.3098L14.2878 6.22457C14.8158 5.65446 15.756 5.79494 16.327 6.32875Z"
          fill={inactive ? '#A5A5A5' : 'black'}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.50977 7.31351C7.50977 6.43922 8.24672 5.73047 9.1558 5.73047L15.3284 5.73047C16.2375 5.73047 16.9745 6.43922 16.9745 7.31351L16.9745 12.8541C16.9745 13.7284 16.2375 14.4372 15.3284 14.4372C14.4194 14.4372 13.6824 13.7284 13.6824 12.8541L13.6824 8.89655L9.1558 8.89655C8.24672 8.89655 7.50977 8.1878 7.50977 7.31351Z"
          fill={inactive ? '#A5A5A5' : 'black'}/>
    <path d="M8 18H16" stroke={inactive ? '#A5A5A5' : '#4F8BFF'} strokeWidth="3.5" strokeLinecap="round"/>
  </svg>
);

export default TransactionClaim;
