import { TokenName, VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import {BigNumber} from "ethers";

export enum TokenDisplayType {
  supply = "supply",
  borrow = "borrow",
  incentive = "incentive",
  base = "",
}

export enum StrategyType {
  lender = "lender",
  farm = "farm",
}

export interface BaseStrategyInterface {
  name: string;
  type: StrategyType;
  icon: string;
  risksUrls:  { text: string, url: string }[]
}

const convexPoolDetails = {
  type: StrategyType.farm,
  risksUrls: [{
    text: "Convex Risks",
    url: "https://docs.convexfinance.com/convexfinance/faq/risks"
  },
    {
      text: "Curve Risks",
      url: "https://classic.curve.fi/risks"
    }],
  icon: "convex",
}
const morphoDetails = {
  type: StrategyType.farm,
  risksUrls: [{
    text: "Morpho Risks",
    url: "https://docs.morpho.org/morpho-vaults/concepts/risk-documentation"
  }],
  icon: "morpho",
}

export const strategyInfos: {[key: string]: BaseStrategyInterface } = {
  "0x1476F031DAD2354A48a7C37a6128ad7B297a3997": {
    name: "Aave V3",
    type: StrategyType.lender,
    risksUrls: [{
      text: "Aave V3 Risks",
      url: "https://docs.aave.com/risk"
    }],
    icon: "aave",
  },
  "0x5715E68c7b92e5c3F9e9F6730297F64410caE155": {
    name: "Morpho V1",
    type: StrategyType.lender,
    risksUrls: [{
      text: "Morpho V1 Risks",
      url: "https://docs.morpho.org/morpho/concepts/risk-documentation"
    }],
    icon: "morpho",
  },
  "0x7d2768dE32b0b80b7a3454c06BdAc94A69DDc7A9": {
    name: "Aave V2",
    type: StrategyType.lender,
    risksUrls: [{
      text: "Aave V2 Risks",
      url: "https://docs.aave.com/risk"
    }],
    icon: "aave",
  },
  "0x3d9819210A31b4961b30EF54bE2aeD79B9c9Cd3B": {
    name: "Compound Finance",
    type: StrategyType.lender,
    risksUrls: [{
      text: "Compound Risks",
      url: "https://docs.compound.finance/#security"
    }],
    icon: "compound",
  },
  "0x505b5c9e5f625fA96D8091bc502cf3c3D0335027": {
    name: "Convex MIM Pool",
    ...convexPoolDetails
  },
  "0xA2327f3fB832225f5ff37CDa70F694d51154929D": {
    name: "Convex sUSD-crvUSD Pool",
    ...convexPoolDetails
  },
  "0xd168b6Ca6548F13f4EAF63191a2Df72633742Fd0": {
    name: "Convex USDV Pool",
    ...convexPoolDetails
  },
  "0x3B1f0Ac253Ce81A9Dd5623c2DB4fcD079503616c": {
    name: "Convex USDP-crvUSD Pool",
    ...convexPoolDetails
  },
  "0xc0631Eb4BcF841BBE65887C3Fa9abcf138c90447": {
    name: "Convex eUSD​-USDC Pool",
    ...convexPoolDetails
  },
  "0xAB769b4FBba0F83ae649Fbf741Dd724268637061": {
    name: "Convex crvUSD​-sUSDe Pool",
    ...convexPoolDetails
  },
  "0xf0Dd037Dc733dcc42AbC0e7e2541e699Da6dA29d": {
    name: "Convex ULTRA​-USDC Pool",
    ...convexPoolDetails
  },
  "0x9f1339626C42fbe44A42A4759eB0b0aE75228020": { name: "Morpho LlamaRisk crvUSD Vault", ...morphoDetails },
  "0x1DA520b405F0592638C2C0c9d0BC546d5200A82d": { name: "Morpho Gauntlet USDC Core", ...morphoDetails },
  "0x6AbE6BF0Ff587e6e4Fe208572694EF3A1472521a": { name: "Morpho LeadBlock USDC RWA", ...morphoDetails },
  "0x27825e64228F2dD5E4eF99720CC390951b4d1FA0": { name: "Morpho Usual Boosted USDC", ...morphoDetails },
  "0x0a61911367CC8C534E25eF2107a429f87A03ec70": { name: "Morpho Steakhouse USDT", ...morphoDetails },
}

export interface VaultModel {
  id: VaultName;

  supplyName: TokenName;
  borrowName: TokenName;
  supplySymbol: string;
  borrowSymbol: string;
  incentiveSymbol: string;
  vaultDecimals: any;
  supplyDecimals: number;
  borrowDecimals: number;
  supplyPrecision: number;
  borrowPrecision: number;
  supplyBasePrice: number;
  supplyBasePriceBn: BigNumber;
  borrowToUsdRatio: number;

  supplyTokenAddress: string;
  borrowTokenAddress: string;
  activeLenderStrategy: string | undefined;
  activeFarmStrategy: string | undefined;

  maxTargetThreshold: number;
  minTargetThreshold: number;
  targetThreshold: number;
  supplyThreshold: number;
  supplyThresholdBn: BigNumber;
  liquidationThreshold: number;
  liquidationThresholdBn: BigNumber;
  liquidationBonus: number;
  reserveFactor: number;

  userMinDepositLimit: number;
  userMaxDepositLimit: number;
  vaultDepositLimit: number;
  harvestsCount: number;
  protocolPaused: boolean;

  lenderStrategyInfo: BaseStrategyInterface;
  farmStrategyInfo: BaseStrategyInterface;
}

export interface VaultStats {
  id: VaultName;
  totalSupply: number;
  totalSupplyBn: BigNumber;
  totalSupplyInUsd: number;
  totalBorrow: number;
  totalBorrowBn: BigNumber;
  totalBorrowInUsd: number;
  totalUserBorrow: number;
  totalUserBorrowBn: BigNumber;
  totalUserBorrowInUsd: number;
  totalFarm: number;
  totalFarmInUsd: number;
  totalVaultBorrow: BigNumber;
  supplyApy: number;
  borrowApy: number;
  farmApy: number;
}

export interface VaultHarvestModel {
  id: VaultName;
  latestHarvest: any;
  previousHarvest: any;
}

