import { IconProps } from "../../../../assets/images/iconComponents";
const TransactionIn = ({ inactive, ...rest }: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="12" cy="12" r="12" fill={inactive ? '#606060' : 'white'} />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M11.9206 14.1907C11.1393 14.2171 10.5199 13.5989 10.4901 12.8224L10.4903 4.17676C10.4903 3.72636 10.7013 3.30156 11.0611 3.02758C11.5721 2.63839 12.2794 2.62885 12.8005 3.00413L12.8207 3.01865C13.2071 3.29692 13.4359 3.74249 13.4359 4.2169L13.4362 12.8224C13.466 13.5989 12.7018 14.1644 11.9206 14.1907Z"
          fill={inactive ? '#A5A5A5' : 'black'} />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M17.4587 7.25953C18.077 7.87774 18.057 8.90001 17.4142 9.54283L13.0495 13.9075C12.4067 14.5504 11.3844 14.5703 10.7662 13.9521L6.84836 10.0343C6.23014 9.41606 6.25008 8.39379 6.8929 7.75097C7.53572 7.10815 8.55799 7.08821 9.17621 7.70642L11.9747 10.5049L15.1754 7.30408C15.8183 6.66126 16.8405 6.64131 17.4587 7.25953Z"
          fill={inactive ? '#A5A5A5' : 'black'} />
    <path d="M8 18H16" stroke={inactive ? '#A5A5A5' : '#4F8BFF'} strokeWidth="3.5" strokeLinecap="round"/>
  </svg>

);

export default TransactionIn;
