import React from "react";
import { ArrowRight } from "../../../../../assets/images/iconComponents";
import { PercentageFormatter } from "../../../../../components";

import { useChartInfo } from "./bar-chart.logic";
import * as Styles from "./styles";
import HorizontalChart from "./horizontal-chart";

interface BarChartProps {
  to: number;
  from: number;
  balance: number;
  borrowLimit: number;
  liquidation: number;
  collateral: number;
  liquidationThreshold: number;
  supplyThreshold: number;
  borrowToUsdRatio: number;
  borrowName: string;
  supplySymbol: string;
  showValues?: boolean;
  toLabel?: string;
}

export const BarChart = ({
  to,
  from,
  balance,
  borrowLimit,
  collateral,
  liquidation,
  liquidationThreshold,
  supplyThreshold,
  borrowToUsdRatio,
  borrowName,
  supplySymbol,
  toLabel,
  showValues,
  ...rest
}: BarChartProps) => {
  const {
    isUnderChart,
    borrowColor,
    liquidationAmount,
    borrowingLimit,
    borrowLimitPercentage,
    liquidationColor,
    liquidationLimit,
    liquidationPercentage,
  } = useChartInfo({
    borrowLimit,
    liquidation,
    balance,
    supplyThreshold,
    liquidationThreshold,
    collateral,
    borrowToUsdRatio,
  })
  const isToEqualFrom = from === to;

  const isPercentageContainerToRendered = !isNaN(to);
  const isPercentageContainerFromRendered = !isToEqualFrom && !isNaN(from);

  return (
    <>
      <Styles.PercentageContainer
        $isUnderChart={isUnderChart}
        data-testid="bar-chart-container"
      >
        {isPercentageContainerFromRendered && (
          <>
            <Styles.PercentageContainerFrom data-testid="bar-chart-percentage-from">
              <PercentageFormatter percentage={from} /> <span>%</span>
            </Styles.PercentageContainerFrom>
            <ArrowRight />
          </>
        )}
        {isPercentageContainerToRendered && (
          <Styles.PercentageContainerTo
            data-testid="bar-chart-percentage-to"
            $color={borrowColor}
          >
            <PercentageFormatter percentage={to} /> <span>%</span>
            <Styles.PercentageLabel>{toLabel}</Styles.PercentageLabel>
          </Styles.PercentageContainerTo>
        )}
      </Styles.PercentageContainer>
      <HorizontalChart
        supplyThreshold={supplyThreshold}
        supplySymbol={supplySymbol}
        borrowLimit={borrowLimit}
        borrowName={borrowName}
        liquidationAmount={liquidationAmount}
        borrowColor={borrowColor}
        borrowingLimit={borrowingLimit}
        borrowLimitPercentage={borrowLimitPercentage}
        isUnderChart={isUnderChart}
        liquidationColor={liquidationColor}
        liquidationLimit={liquidationLimit}
        liquidationPercentage={liquidationPercentage}
        showValues={showValues}
      />
    </>
  );
};
