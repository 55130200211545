import { createAsyncThunk } from "@reduxjs/toolkit";
import { toastAdd, toastRemove } from "../../index";

import { sdk } from "../../../services/sdk-provider";
import { VaultModel, VaultStats } from "../../../models";
import { isDevelopment } from "../../../utils";
import { captureException } from "@sentry/react";

export const fetchVaultStats = createAsyncThunk("vault/fetchVaultStats", async ({ vault }: { vault: VaultModel }, thunkApi) => {
  try {
    const vaultContract = sdk.multi.vaults[vault.id];
    if (!vaultContract) {
      throw new Error(`Vault ${vault.id} was not found`);
    }
    const activeFarmStrategyContract = vaultContract.farmingStrategies.find(strategy => strategy.address === vault.activeFarmStrategy)
    if (!activeFarmStrategyContract) {
      throw new Error(`Farm strategy ${vault.activeFarmStrategy} was not found`);
    }
    const [
      totalSupplyBn,
      totalBorrowBn,
      totalFarmBn,
      vaultBorrowBn
    ] = await sdk.getMultiData([
      vaultContract.supplyToken.totalSupply(),
      vaultContract.borrowToken.totalSupply(),
      activeFarmStrategyContract.balance().catch(() => NaN),
      vaultContract.borrowToken.balanceOf(vaultContract.vault.address),
    ]);

    const totalSupply = sdk.utils.convertFromWei(totalSupplyBn, vault.supplyDecimals);
    const totalBorrow = sdk.utils.convertFromWei(totalBorrowBn, vault.borrowDecimals);
    const totalFarm = totalFarmBn && sdk.utils.convertFromWei(totalFarmBn, vault.borrowDecimals);
    const totalUserBorrowBn = totalBorrowBn.sub(vaultBorrowBn);
    const totalUserBorrow = sdk.utils.convertFromWei(totalUserBorrowBn, vault.borrowDecimals);

    thunkApi.dispatch(toastRemove('error-fetching-vaults-data'));
    const result: VaultStats = {
      id: vault.id,
      totalSupply,
      totalSupplyBn,
      totalSupplyInUsd: totalSupply * vault.supplyBasePrice * vault.borrowToUsdRatio,
      totalBorrow,
      totalBorrowBn,
      totalBorrowInUsd: totalBorrow * vault.borrowToUsdRatio,
      totalUserBorrow,
      totalUserBorrowBn,
      totalUserBorrowInUsd: totalFarm && (totalUserBorrow * vault.borrowToUsdRatio),
      totalFarm,
      totalFarmInUsd: totalFarm && (totalFarm * vault.borrowToUsdRatio),
      totalVaultBorrow: vaultBorrowBn,
      supplyApy: 0,
      borrowApy: 0,
      farmApy: 0
    };
    return result;
  } catch (error: any) {
    console.log(error)
    if (!isDevelopment()) {
      captureException(error)
    } else {
      thunkApi.dispatch(
        toastAdd({
          id: "error-fetching-vaults-data",
          message: "Error fetching data",
          type: "error",
        })
      );
    }
    const message = error.message;
    return thunkApi.rejectWithValue(message);
  }
});
