import theme from "styled-theming";

// Main colors
export const colors = {
  blueAlpha: "#4F8AFF",
  blueBeta: "#06A0D1",
  bluePrimary: "#71D5FA",
  blueSecondary: "#6197FF",
  purpleAlpha: "#C98FE9",
  purpleBeta: "#E8C2F8",
  pinkAlpha: "#F17DA1",
  pinkBeta: "#FFB8CC",
  peachAlpha: "#ED8877",
  peachBeta: "#FFC5B4",
  orangeAlpha: "#EC9C47",
  orangeBeta: "#FFCD80",
  yellowAlpha: "#EBB53B",
  yellowBeta: "#FBD873",
  greenAlpha: "#40B983",
  greenBeta: "#79DAA8",
  redAlpha: "#ff3232"
}

export const grays = {
  gray900: "#1E212A",
  gray800: "#292C35",
  gray700: "#2E3139",
  gray600: "#30333B",
  gray500: "#35373F",
  gray400: "#393C44",
  gray300: "#3E4048",
  gray200: "#42454C",
  gray100: "#474950",
  gray50: "#52555B",
  gray10: "#A4A5A5",
}

export const ThemePrimaryText = theme("theme", { light: "#ff0000", dark: "#FEFFFF" });
export const ThemeSecondaryText = theme("theme", { light: "#ffff00", dark: grays.gray10 });
export const ThemePrimary = theme("theme", { light: "#4F8AFF", dark: "#4F8AFF" });

// Background colour
export const ThemeAppBackground = theme("theme", {
  light: `
    linear-gradient(
      0.2deg, rgba(255, 255, 255, 0) 0.2%, 
      rgba(255, 255, 255, 0.09) 99.84%
    ),
    radial-gradient(
      107.78% 275.91% at 105.97% 9.61%,
      rgba(146, 111, 221, 0.1) 0%,
      rgba(67, 113, 182, 0) 100%
    ),
    radial-gradient(
      107.33% 258.58% at 0% 102.06%,
      rgba(30, 103, 113, 0.2) 0%,
      rgba(44, 112, 213, 0) 100%
    ),
    #ffffff;
  `,
  dark: `
    linear-gradient(
      0.2deg, rgba(255, 255, 255, 0) 0.2%, 
      rgba(255, 255, 255, 0.09) 99.84%
    ),
    radial-gradient(
      107.78% 275.91% at 105.97% 9.61%,
      rgba(146, 111, 221, 0.1) 0%,
      rgba(67, 113, 182, 0) 100%
    ),
    radial-gradient(
      107.33% 258.58% at 0% 102.06%,
      rgba(30, 103, 113, 0.2) 0%,
      rgba(44, 112, 213, 0) 100%
    ),
    #000000;
  `,
});
// Background colour
export const ThemeBannerBackground = theme("theme", {
  light: `linear-gradient(283.39deg, rgba(0, 163, 255, 0.25) -18.13%, rgba(0, 163, 255, 0.25) -18.12%, rgba(248, 203, 255, 0.25) 108.06%), rgb(30, 33, 42)`,
  dark: `linear-gradient(283.39deg, rgba(0, 163, 255, 0.25) -18.13%, rgba(0, 163, 255, 0.25) -18.12%, rgba(248, 203, 255, 0.25) 108.06%), rgb(30, 33, 42)`,
});

// Grayscale
// TODO needs to be simplified with "variants", no need to have a separate const for each theme gray
export const ThemeGray900 = theme("theme", { light: grays.gray900, dark: grays.gray900 });
export const ThemeGray800 = theme("theme", { light: grays.gray800, dark: grays.gray800 });
export const ThemeGray700 = theme("theme", { light: grays.gray700, dark: grays.gray700 });
export const ThemeGray600 = theme("theme", { light: grays.gray600, dark: grays.gray600 });
export const ThemeGray500 = theme("theme", { light: grays.gray500, dark: grays.gray500 });
export const ThemeGray400 = theme("theme", { light: grays.gray400, dark: grays.gray400 });
export const ThemeGray300 = theme("theme", { light: grays.gray300, dark: grays.gray300 });
export const ThemeGray200 = theme("theme", { light: grays.gray200, dark: grays.gray200 });
export const ThemeGray100 = theme("theme", { light: grays.gray100, dark: grays.gray100 });
export const ThemeGray50 = theme("theme", { light: grays.gray50, dark: grays.gray50 });

// Chart colours
// TODO: Investigate if we can use theme colors directly in charts
export const ThemeChartGray100 = {
  light: "#ff00ff",
  dark: "#474950",
};

export const ThemeChartGray50 = {
  light: "#0000ff",
  dark: "#606060",
};

export const ThemeChartGray500 = {
  light: "#4F8AFF",
  dark: "#35373F",
};

export const ThemeStatus100 = "#72CA5D";
export const ThemeStatus200 = "#A3C811";
export const ThemeStatus300 = "#EFB73F";
export const ThemeStatus400 = "#EB9B46";
export const ThemeStatus500 = "#D85830";
export const ThemeStatus600 = "#F97B5F";

// Warning colors
export const ThemeError = colors.redAlpha;
export const ThemeWarning = colors.orangeAlpha;
export const ThemeInfo = colors.blueAlpha;

// Theme palette
// TODO light colors need to be updated when we decide to enable light theme

type ThemeColors = {
  [key: string]: { light: string, dark: string }
}

export const ThemePalette = theme.variants(
  "theme",
  "color",
  (Object.keys(colors) as Array<keyof typeof colors>).reduce<ThemeColors>((acc, key) => ({
    ...acc, [key]: { light: colors[key], dark: colors[key] }
  }),{})
);
