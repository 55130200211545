import { WriteContractErrorType, WaitForTransactionReceiptErrorType } from "wagmi/actions";
import { transactionStatusCodes } from "../components/transaction-modal/transaction-status.codes";

export const NOT_ENOUGH_FUNDS = "NOT_ENOUGH_FUNDS";
export const USER_REJECTED = "USER_REJECTED";
export const RPC_ERROR = "RPC_ERROR";
export const NETWORK_CHANGED = "NETWORK_CHANGED";

type ErrorPattern = { msg?: string; code?: number };

const TX_ERROR_PATTERNS: { [key: string]: ErrorPattern[] } = {
  [NOT_ENOUGH_FUNDS]: [
    { msg: "insufficient funds for gas" },
    { msg: "not enough funds for gas" },
    { msg: "failed to execute call with revert code InsufficientGasFunds" },
  ],
  [USER_REJECTED]: [{ msg: "User rejected the request" }],
  [NETWORK_CHANGED]: [{ msg: "underlying network changed" }],
  [RPC_ERROR]: Object.keys(transactionStatusCodes).map((code) => ({ code: Number(code) })),
};

export type TxError = WriteContractErrorType | WaitForTransactionReceiptErrorType;

export function extractError(error: TxError): [
  reason: string | null,
  message: string | null,
  type: (keyof typeof TX_ERROR_PATTERNS) | null,
  data: any,
] {
  if (!error) {
    return [null, null, null, null];
  }
  let message = error.message;
  let reason = '';
  if ('shortMessage' in error) {
    reason = error.shortMessage;
    message = error.message;
  }

  if (!message) {
    return [null, null, null, null];
  }

  for (const [type, patterns] of Object.entries(TX_ERROR_PATTERNS)) {
    for (const pattern of patterns) {
      const matchMessage = pattern.msg && reason && reason.includes(pattern.msg);

      if (matchMessage) {
        return [reason as string, message as string, type as keyof typeof TX_ERROR_PATTERNS, error];
      }
    }
  }

  return [reason as string, message as string, null, error];
}
