import styled from "styled-components";
import {
  GridVars,
} from "../../../../utils";
import { ModalContent } from "../../../../utils/styles/modal.styles";
import { Button } from "../../../../utils/styles/button";
import { Text, TextHeader, TextParagraph } from "../../../../utils/styles/text";

export const RiskWarningModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  padding: ${GridVars.padding.p3};
  border-radius: 8px;

  & text:nth-child(3) {
    margin-bottom: 0;
  }
`;

export const RiskWarningHeader = styled(TextHeader).attrs({ $size: 'large' })``;

export const RiskWarningContent = styled(TextParagraph)``;

export const RiskWarningContentLast = styled(TextParagraph)`
  margin-bottom: 0;
`;

export const RiskWarningCheckboxWrapper = styled.div`
  display: flex;
`;

export const CookieConsentText = styled(Text)``
export const ConfirmButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`
export const RiskWarningCheckboxItem = styled(Text)`
  cursor: pointer;

  &:first-child {
    margin-right: ${GridVars.margin.m2};
  }
`;

export const RiskWarningConfirmButton = styled(Button).attrs({
  $variant: 'primary',
  $size: "compact"
})`
  align-self: end;
`;
