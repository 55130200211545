import { createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";

type GlobalState = {
  headerModal: number;
  selectedVault: VaultName;
  localStorage: {
    [key: string]: any
  }
}

const globalSlice = createSlice<
  GlobalState,
  {
    globalSetHeaderModal(state: any, action: PayloadAction<number>): void;
    globalSetSelectedVault(state: any, action: PayloadAction<VaultName>): void;
    readLocalStorage(state: any, action: PayloadAction<string>): void;
    saveLocalStorage(state: any, action: PayloadAction<{ key: string, value: any}>): void;
  },
  "global"
>({
  name: "global",
  initialState: {
    headerModal: 0,
    selectedVault: "wstethUsdc", // "ethUsdc" | "wstethUsdc"
    localStorage: {}
  },
  reducers: {
    globalSetHeaderModal(state, action: PayloadAction<number>) {
      state.headerModal = action.payload;
    },
    globalSetSelectedVault(state, action: PayloadAction<VaultName>) {
      state.selectedVault = action.payload;
    },
    readLocalStorage(state, action: PayloadAction<string>) {
      try {
        state.localStorage[action.payload] = JSON.parse(localStorage.getItem(action.payload) || '');
      } catch (e) {
        state.localStorage[action.payload] = undefined;
      }
    },
    saveLocalStorage(state, action: PayloadAction<{ key: string, value: any}>) {
      try {
        const serializedValue = JSON.stringify(action.payload.value);
        localStorage.setItem(action.payload.key, serializedValue);
        state.localStorage[action.payload.key] = action.payload.value
      } catch (e) {
        console.error(e);
        state.localStorage[action.payload.key] = undefined;
      }
    }
  },
});

// Selectors
export const selectGlobal = (
  state: EntityState<GlobalState>
): EntityState<GlobalState> => {
  return state;
};

export default globalSlice;
