import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserModel } from "../../../models";
import * as store from "../../../store";
import { selectUser } from "../../../store/user/user.slice";

import { fetchUserVaultData } from "./fetchUserVaults";
import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { BigNumber } from "ethers";
import { TransactionFlowPayload, TransactionType } from "../../transaction/transaction.slice";
import { selectVault } from "../../vault/vault.slice";
import { addTransactionFlow, startTransactionFlow } from "../../../store";

export const withdraw = createAsyncThunk<void, any>(
  "userVault/withdraw",
  async (
    { withdraw, selectedVault }: { withdraw: BigNumber; selectedVault: VaultName },
    thunkApi
  ) => {
    const { user, vault } = store.default.getState();
    const { userAddress }: UserModel = selectUser(user);
    const { supplyName }: any = selectVault(vault, selectedVault) || {};

    const transactionFlow: TransactionFlowPayload = [];

    try {
      transactionFlow.push({
        id: `withdraw-${selectedVault}`,
        index: 0,
        type: TransactionType.WITHDRAW,
        info: {
          vault: selectedVault,
          supplyToken: supplyName,
          withdrawAmount: withdraw,
          userAddress: userAddress,
        },
      });

      thunkApi.dispatch(addTransactionFlow(transactionFlow));
      thunkApi.dispatch(startTransactionFlow());
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }

    thunkApi.dispatch(fetchUserVaultData({ selectedVault }));
  }
);
