import * as Styled from "./style";
import numbro from "numbro";
import { IconAltitude } from "../../../../../assets/images/iconComponents";
import * as PopoverStyles from "../../../../../components/custom-popover/styles";
import CustomPopover from "../../../../../components/custom-popover";
import React from "react";
import { useIncentives } from "../../../../../hooks/useIncentives";
import PercentageFormatter from "../../../../../components/percentage-formatter";
import { formatEther } from "ethers/lib/utils";
import { IncentivesData } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { InfoPopover } from "../../../../../components";
import { SupplyInfoContent } from "./supply-info-popover";

type IncentivesAmountProps = {
  incentives: IncentivesData;
  showTooltip?: boolean;
}
const IncentivesAmount = ({ incentives, showTooltip = false }: IncentivesAmountProps) => {
  return incentives.apy
    ? <>
        <Styled.PillButtonText>
          <PercentageFormatter percentage={incentives.apy} fractionDigits={2} /><span>%</span>
        </Styled.PillButtonText>
      </>
    : <>
        <Styled.PillButtonText>
          +{numbro(formatEther(incentives.tokensPerThousandDollars)).format({ mantissa: 2, thousandSeparated: true })}
          {showTooltip && (
            <InfoPopover marginLeft="-100px">
              <PopoverStyles.Paragraph>
                Per day per $1,000 deposited
              </PopoverStyles.Paragraph>
            </InfoPopover>
          )}
        </Styled.PillButtonText>
      </>
}

export const IncentivesPopover = () => {
  const incentives = useIncentives();
  return (
    <CustomPopover
      marginLeft="-13px"
      marginTop="0px"
      button={
        <Styled.PillButton>
          <IconAltitude width="14" height="14" />
          <span>
            <IncentivesAmount incentives={incentives} />
          </span>
        </Styled.PillButton>
      }
    >
      <SupplyInfoContent />
    </CustomPopover>
  )
}
